import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { MAP_API_KEY } from "../../config";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { LOCAL_PATH } from "../../constants";

const MapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=" + MAP_API_KEY + "&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `700px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(({ locations, defaultLocation, setLocation=() => {}, setShowModal=() => {}, defaultDraggable }) => {
  const [infoWindow, setInfoWindow] = useState('');
  const [markerPosition, setMarkerPosition] = useState(defaultLocation);
  const history = useHistory();
  const onMapClick = ({ latLng }) => {
    setMarkerPosition(latLng)
    setLocation({ lat: latLng.lat(), lng: latLng.lng() });
    setShowModal(false)
  }
  const markers = locations ? locations.map(device => {
    let markerIcon = device.status ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png" : "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
    return (
      <Marker
        position={{ lat: device.lat, lng: device.lng }}
        options={{ icon: markerIcon }}
        onMouseOver={() => setInfoWindow(device.deviceName)}
        onMouseOut={() => setInfoWindow('')}
        onClick={() => history.push(LOCAL_PATH.DEVICE, { id: device.deviceName })}
      >
        {(infoWindow === device.deviceName) &&
          <InfoWindow>
            <p> <strong>
              {device.deviceName} <br />
              {device.deviceAlias} <br />
              {device.managerName} <br />
            </strong>
            </p>
          </InfoWindow>}
      </Marker>
    )
  }) : null
  return (
    markers ?
      <GoogleMap defaultZoom={3} defaultCenter={defaultLocation}>
        {markers}
      </GoogleMap> :
      <GoogleMap defaultZoom={12} defaultCenter={defaultLocation} onClick={!defaultDraggable ? onMapClick : undefined} >
        <Marker position={markerPosition} defaultDraggable={!defaultDraggable} onDragEnd={onMapClick} />
      </GoogleMap>
  )
});

export default MapComponent;





































// import React from 'react';
// import { compose, withProps } from 'recompose';
// import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
// import { MAP_API_KEY } from '../../config';
// import { useState } from 'react';
// import { useHistory } from 'react-router-dom';
// import { LOCAL_PATH } from '../../constants';

// const MapComponent = compose(
//   withProps({
//     googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=' + MAP_API_KEY + '&v=3.exp&libraries=geometry,drawing,places',
//     loadingElement: <div style={{ height: `100%` }} />,
//     containerElement: <div style={{ height: `700px` }} />,
//     mapElement: <div style={{ height: `100%` }} />,
//   }),
//   withScriptjs,
//   withGoogleMap
// )(({ locations, defaultLocation, setLocation, setShowModal, defaultDraggable }) => {
//   const [infoWindow, setInfoWindow] = useState('');
//   const [markerPosition, setMarkerPosition] = useState(defaultLocation);
//   const history = useHistory();
//   const onMapClick = ({ latLng }) => {
//     setMarkerPosition(latLng);
//     setLocation({ lat: latLng.lat(), lng: latLng.lng() });
//     setShowModal(false);
//   };
//   const markers = locations
//     ? locations.map((device) => {
//         let markerIcon = device.status
//           ? 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
//           : 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
//         return (
//           <Marker
//             position={{ lat: device.lat, lng: device.lng }}
//             options={{ icon: markerIcon }}
//             onMouseOver={() => setInfoWindow(device.deviceName)}
//             onMouseOut={() => setInfoWindow('')}
//             onClick={() => history.push(LOCAL_PATH.DEVICE, { id: device.deviceName })}
//           >
//             {infoWindow === device.deviceName && (
//               <InfoWindow>
//                 <p>
//                   {' '}
//                   <strong>
//                     {device.deviceName} <br />
//                     {device.deviceAlias} <br />
//                     {device.managerName} <br />
//                   </strong>
//                 </p>
//               </InfoWindow>
//             )}
//           </Marker>
//         );
//       })
//     : null;
//   return markers ? (
//     <GoogleMap defaultZoom={3} defaultCenter={defaultLocation}>
//       {markers}
//     </GoogleMap>
//   ) : (
//     <GoogleMap defaultZoom={12} defaultCenter={defaultLocation} onClick={defaultDraggable ? onMapClick : undefined}>
//       <Marker position={markerPosition} defaultDraggable={defaultDraggable} onDragEnd={onMapClick} />
//     </GoogleMap>
//   );
// });

// export default MapComponent;
