import React from "react";
import { TextField } from "@material-ui/core";
import { objectResolve } from "../../utils/ObjectResolver";

export const Input = ({ id, field, ...rest }) => {
    const error =
        objectResolve(field.name, rest.form.touched) &&
        objectResolve(field.name, rest.form.errors);
    // const error = rest.form.touched[field.name] && rest.form.errors[field.name];
    return (
        <TextField
            {...field}
            {...rest}
            error={Boolean(error)}
            helperText={error}
        />
    );
};
