import React, { useEffect, useContext, useState } from "react";
import { LoaderContext } from "../../context/LoaderContext";
import {
    START_LOADER,
    STOP_LOADER,
    STORED_CREDENTIALS,
    LOCAL_PATH,
} from "../../constants";
import { makeStyles, Grid, Button, InputAdornment } from "@material-ui/core";
import { Lock as LockIcon, SignalCellularNull } from "@material-ui/icons";
import { Formik, Form, Field } from "formik";
import { Input } from "../../components/Input/Input";
import { LoginValidation, OTPValidation, UserSignup } from "../../validators/userValidator";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { UserService } from "../../services/UserService";
import { useHistory } from "react-router-dom";
import { AuthService } from "../../services/AuthService";
import { DropDown } from "../../components/DropDown/DropDown";
import { toast } from "react-toastify";

const useStyles = makeStyles({
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    container: {
        width: "30vw",
        margin: "0 auto",
    },
    fields: {
        marginBottom: "1rem",
    },
});
export const LoginPage = () => {
    const [errorMsg, setErrorMsg] = useState('');
    const user = AuthService.getCurrentUser();
    const history = useHistory();
    if (user) {
        history.push(LOCAL_PATH.HOME);
    }

    const { dispatch } = useContext(LoaderContext);
    const startLoader = () =>
        dispatch({ type: START_LOADER, payload: "login" });
    const stopLoader = () => dispatch({ type: STOP_LOADER, payload: "login" });
    const classes = useStyles();
    const initialValues = { email: "", password: "" };
    const [signup, setSignUp] = useState(false);
    const [verify, setVerify] = useState(false);
    const [locations, setLocations] = useState([]);
    useEffect(() => {
        if (signup) {
            UserService.GetAllLocation(
                startLoader,
                ({ data }) => {
                    let processedLocations = data.data.locations.map((item) => ({
                        name: item?.locationName,
                        id: item?.id
                    }))
                    setLocations(processedLocations)

                },
                handleError,
                stopLoader
            );
        }
    }, [signup])
    const handleSubmit = (values) => {
        if (signup && values.repeatPassword !== values?.password) {
            setErrorMsg('Password and Repeat Password should match!');
            return
        }
        if (signup && !verify) {
            setErrorMsg('')
            UserService.SignupGenerateOtp(
                {
                    "email": values?.email,
                    "mobile": values?.contact
                },
                startLoader,
                () => {
                    setVerify(true)

                },
                handleError,
                stopLoader
            );
        } if (signup && verify) {
            setErrorMsg('');
            UserService.Signup(
                values,
                startLoader,
                () => {
                    setVerify(false)
                    setSignUp(false)
                    toast.success('Signup Success!')
                },
                handleError,
                stopLoader
            );
        } else if (!signup && !verify) {
            setErrorMsg('');
            UserService.Login(
                {
                    "email": values?.email,
                    "password": values?.password
                },
                startLoader,
                handleLoginSuccess,
                handleLoginError,
                stopLoader
            );
        }

    };
    const handleLoginSuccess = (res) => {
        const data = res.data;
        if (!data.error) {
            const credential = JSON.stringify(data.data);
            localStorage.setItem(STORED_CREDENTIALS, credential);
            history.push(LOCAL_PATH.HOME);
            return
        }
        setErrorMsg(data.message)
    };
    const handleLoginError = (err) => {
        console.log(err);
        localStorage.clear();
    };
    const handleError = (err) => {
        console.log(err);
        toast.error(err?.response?.data?.message)
    };
    const disabled = verify && signup ? true : false
    return (
        <div className="login-page">
            <div className={classes.container + " login-page-card"}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>
                            <LockIcon />
                            {!signup && !verify ? "LOGIN" : !verify && signup ? "VERIFY" : "SIGNUP"}
                        </h4>
                        <p style={{ textAlign: "center" }}>to view the dashboard</p>
                    </CardHeader>
                    <CardBody>
                        <Formik
                            onSubmit={handleSubmit}
                            validationSchema={!signup && !verify ? LoginValidation : signup && !verify ? OTPValidation : UserSignup}
                            initialValues={initialValues}
                        >
                            {({ handleSubmit, isSubmitting, values }) => (
                                <Form>
                                    <Grid container spacing={4}>

                                        {!signup && (
                                            <>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Field
                                                        component={Input}
                                                        name="email"
                                                        type="email"
                                                        fullWidth
                                                        label="Email Address"
                                                        disabled={disabled}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Field
                                                        component={Input}
                                                        name="password"
                                                        type="password"
                                                        fullWidth
                                                        label="Password"
                                                        disabled={disabled}
                                                    />
                                                </Grid></>
                                        )}


                                        {
                                            signup && (
                                                <>
                                                    <Grid item container spacing={1} xs={12} sm={12} md={12}>
                                                        <Grid item xs={6} sm={6} md={6}>
                                                            <Field
                                                                component={Input}
                                                                name="name"
                                                                type="text"
                                                                fullWidth
                                                                label="User Name"
                                                                disabled={disabled}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} sm={6} md={6}>
                                                            <Field
                                                                component={Input}
                                                                name="email"
                                                                type="email"
                                                                fullWidth
                                                                label="Email Address"
                                                                disabled={disabled}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                </>

                                            )
                                        }
                                        {signup && (
                                            <>

                                                <Grid item container spacing={1} xs={12} sm={12} md={12}>
                                                    <Grid item xs={6} sm={6} md={6}>
                                                        <Field
                                                            component={Input}
                                                            name="password"
                                                            type="password"
                                                            fullWidth
                                                            label="Password"
                                                            disabled={disabled}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6}>
                                                        <Field
                                                            component={Input}
                                                            name="repeatPassword"
                                                            type="text"
                                                            fullWidth
                                                            label="Repeat Password"
                                                            disabled={disabled}
                                                        />
                                                    </Grid>


                                                </Grid>
                                                <Grid item container spacing={1} xs={12} sm={12} md={12}>
                                                    <Grid item xs={6} sm={6} md={6}>
                                                        <Field
                                                            component={Input}
                                                            name="companyName"
                                                            type="text"
                                                            fullWidth
                                                            label="Company Name"
                                                            disabled={disabled}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6}>
                                                        <Field
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">+</InputAdornment>
                                                                ),
                                                            }}

                                                            component={Input}
                                                            name="contact"
                                                            type="text"
                                                            fullWidth
                                                            label="Contact"
                                                            disabled={disabled}
                                                        />
                                                    </Grid>



                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Field
                                                        component={Input}
                                                        name="companyAddress"
                                                        type="text"
                                                        fullWidth
                                                        label="Company Address"
                                                        disabled={disabled}
                                                    />
                                                </Grid>
                                                <Grid item container spacing={1} xs={12} sm={12} md={12}>
                                                    <Grid item xs={6} sm={6} md={6}>
                                                        <Field
                                                            component={DropDown}
                                                            name="locationId"
                                                            type="select"
                                                            values={locations}
                                                            fullWidth
                                                            label="Location"
                                                            disabled={disabled}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6}>
                                                        <Field
                                                            component={Input}
                                                            name="country"
                                                            type="text"
                                                            fullWidth
                                                            label="Country"
                                                            disabled={disabled}
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </>
                                        )}

                                        {
                                            verify && (<Grid item xs={12} sm={12} md={12}>
                                                <Field
                                                    component={Input}
                                                    name="mobileOtp"
                                                    type="number"
                                                    fullWidth
                                                    label="Mobile OTP"
                                                />
                                            </Grid>
                                                /* <Grid item xs={6} sm={6} md={6}>
                                                    <Field
                                                        component={Input}
                                                        name="emailOtp"
                                                        type="number"
                                                        fullWidth
                                                        label="Email OTP"
                                                    />
                                                </Grid> */


                                                // </Grid>
                                            )
                                        }
                                        {errorMsg ?
                                            <Grid item xs={12} sm={12} md={12} className="p-b-0">
                                                <div className="error-msg">{errorMsg}</div>
                                            </Grid> : null}

                                        <Grid item container spacing={1} xs={12} sm={12} md={12}>
                                            <Grid item xs={6} sm={6} md={6}><div className="signup" onClick={() => {
                                                setSignUp(!signup)
                                                setVerify(false)
                                            }}>{!signup ? "Signup" : "Sign In"}?</div></Grid>
                                            <Grid item xs={6} sm={6} md={6}><div className="forget-password" onClick={() => history.push(LOCAL_PATH.RESET_PASSWORD)}>Forgot Password?</div></Grid>


                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                                            <Button
                                                type="submit"
                                                variant="outlined"
                                                color="primary"
                                                mb={2}
                                            >
                                                {!signup && !verify ? "LogIn" : !verify && signup ? "Verify" : "Signup"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};
