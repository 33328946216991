import React from "react";
import { Card, CardContent, Grid, Divider } from "@material-ui/core";
import CardHeader from "./CardHeader";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { LOCAL_PATH } from "../../constants";

const useStyles = makeStyles({
  cardTitleWhite: {
    paddingTop: "0.5rem",
    paddingBottom: "0",
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  CardHeader: {
    marginTop: "5px",
  },
  cardRightSide: {
    padding: "0.5rem",
    textAlign: "right",
  },
  cardLeftSide: {
    padding: "0.5rem",
    textAlign: "left",
  },
});

export default function DashCard({ item, companyHashMap }) {
  const history = useHistory();
  const classes = useStyles();
  return item !== undefined ? (
    <Card>
      <CardHeader
        onClick={() => history.push(LOCAL_PATH.DEVICE, { id: item.deviceName })}
        color="primary"
        style={
          item.status === 0
            ? { background: "green", margin: " 13px 15px 0", padding: "10px" }
            : { background: "red", margin: " 13px 15px 0", padding: "10px" }
        }
      >
        <h4 className={classes.cardTitleWhite}>
          <span className="bold uppercase">{item.deviceName}</span> -{" "}
          {item.deviceAlias}
        </h4>
      </CardHeader>
      <CardContent>
        <Grid container justify="space-between">
          <Grid xs={3} className={classes.cardLeftSide + " bold"}>
            Company
          </Grid>
          <Grid xs={6} style={{ padding: "0.5rem", textAlign: "right" }}>
            {companyHashMap[item.companyId]?.companyName}
          </Grid>
        </Grid>
        <Divider />
        <Grid container justify="space-between">
          <Grid xs={3} className={classes.cardLeftSide + " bold"}>
            Location
          </Grid>
          <Grid xs={6} className={classes.cardRightSide}>
            {item.locationName === "null,null" ? "--" : item.locationName}
          </Grid>
        </Grid>
        <Divider />
        <Grid container justify="space-between">
          <Grid xs={3} className={classes.cardLeftSide + " bold"}>
            Sensors
          </Grid>
          <Grid xs={6} className={classes.cardRightSide}>
            {item.probes}
          </Grid>
        </Grid>
        <Divider />
        <Grid container justify="space-between">
          <Grid xs={4} className={classes.cardLeftSide + " bold"}>
            Last Updated
          </Grid>
          <Grid xs={6} className={classes.cardRightSide}>
            {item.lastUpdated !== "Not Reported"
              ? new Date(item.lastUpdated).toLocaleString()
              : "Not Reported"}
          </Grid>
        </Grid>
        <Divider />
        <Grid container justify="space-between">
          <Grid xs={3} className={classes.cardLeftSide + " bold"}>
            Created At
          </Grid>
          <Grid xs={6} className={classes.cardRightSide}>
            {new Date(item.createdAt).toLocaleString()}
          </Grid>
        </Grid>
        <Divider />
      </CardContent>
    </Card>
  ) : (
    ""
  );
}
