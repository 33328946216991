import { HttpClient } from "../utils/httpClient";

const PATH = {
    assign: "/assignedDevice",
    getAllDevice: "/getAllDevice",
    read: "/assignedDevice",
    readAssistantsByDeviceId: "/assistantByDeviceId",
    delete: "/assignedDevice",
};

const Assign = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.assign, payload)
        .then(callback)
        .catch(error)
        .finally(next);
};

const GetAllDevices = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getAllDevice)
        .then(callback)
        .catch(error)
        .finally(next);
};
const Read = (limit, offset, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read, { params: { limit, offset } })
        .then(callback)
        .catch(error)
        .finally(next);
};
const ReadAssistantsByDeviceId = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.readAssistantsByDeviceId}/${id}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

export const AssignDeviceService = {
    Assign,
    GetAllDevices,
    Read,
    ReadAssistantsByDeviceId,
    Delete,
};
