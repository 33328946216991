import React, { useState, useEffect, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import { Input } from '../../../components/Input/Input';
import Button from '../../../components/CustomButtons/Button';
import { Grid, makeStyles } from '@material-ui/core';
import { DeviceDashboardService } from '../../../services/DeviceDashboardService';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
  buttonContainer: {
    textAlign: 'center',
    marginTop: '20px',
  },
  command: {
    marginTop: '10px',
  },
});

export const AddCommand = ({ deviceName, setShowModal, commands, setCommands, startLoader, stopLoader }) => {
  const classes = useStyles();

  const handleSubmit = (values, resetForm) => {
    DeviceDashboardService.addCommand(
      values,
      () => startLoader(),
      (res) => handleCreateSuccess(res, resetForm),
      (e) => console.log(e),
      () => stopLoader()
    );
  };

  const handleCreateSuccess = (res, resetForm) => {
    let processedCommands = [...commands];
    processedCommands.push(res.data.data);
    setCommands(processedCommands);
    toast.success('Command created successfully');
    resetForm();
    setShowModal(false);
  };

  return (
    <>
      <Formik enableReinitialize initialValues={{ command: '', deviceName: deviceName }}>
        {({ isSubmitting, values, resetForm }) => (
          <Form>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Field component={Input} name='deviceName' type='text' fullWidth label='Device Name' disabled />
            </Grid>
            <Grid className='CommandField' item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Field component={Input} name='command' type='text' fullWidth label='Command' />
            </Grid>
            <Grid className='AddCommandButton' item xs={12} sm={12} md={12} lg={12} xl={12} lgclassName={classes.buttonContainer}>
              <Button type='submit' onClick={() => handleSubmit(values, resetForm)} variant='contained' fullWidth color='primary'>
                Add Command
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
