import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Tab, Tabs, Card, Button } from '@material-ui/core';
import { tabProps, TabPanel } from '../../../components/TabPanel/TabPanel';
import Table from '../../../components/Table/Table';
import { DeviceDashboardService } from '../../../services/DeviceDashboardService';

import { CustomToolTip } from '../../../components/CustomToolTip/CustomToolTip';
import { Details as DetailsIcon } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { getFields } from '../../alert/AddAlert';
import { FormDialog } from '../../../components/FormDialog/FormDialog';
import { Grid, makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import CardBody from '../../../components/Card/CardBody';
import MapComponent from '../../../components/Map/MapComponent';
import { AddCommand } from './AddCommand';
import DeleteIcon from '@material-ui/icons/Delete';
import { FloorMapService } from '../../../services/FloorMapService';
import { START_LOADER, STOP_LOADER } from '../../../constants';
import { LoaderContext } from '../../../context/LoaderContext';
import { toast } from 'react-toastify';
import { CanvasComponent } from '../../../components/CanvasComponent/CanvasComponent';
import { BuildingService } from '../../../services/BuildingService';
import classesModule from "../dashboard.module.css"
const useStyles = makeStyles({
  pagination: {
    marginLeft: 'auto',
  },
});

const LIMIT = 10;

export const DeviceDashboardTables = ({
  setSensorLogPage,
  sensorLogTotalPages,
  sensorLog,
  deviceId,
  deviceLocation,
  deviceCommands,
  template,
  initialData,
}) => {
  const locationMode = initialData?.deviceInfo?.locationMode;
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [tableHead, setTableHead] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showImageModel, setshowImageModel] = useState(false);
  const [totalPages, setTotalPages] = useState(sensorLogTotalPages);
  const [activeEvents, setActiveEvents] = useState({});
  const [alertData, setAlertData] = useState({});
  const [alertLog, setAlertLog] = useState({});
  const [ImageUrl, setImageUrl] = useState('');
  const [criteria, setCriteria] = useState({ show: false, content: null });
  const [commands, setCommands] = useState(deviceCommands);
  const [canvasImage, setCanvasImage] = useState('');
  const [markers, setMarkers] = useState([]);
  const [ImageLog, setImageLog] = useState([]);
  const [BuildingData, setBuildingData] = useState({ lat: 57, lng: 16 });
  const { dispatch } = useContext(LoaderContext);
  const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });

  useEffect(() => {
    getAlertData();
    getAlertLog();
    getEvents();
    setPagination(1);
    getImageLog()
  }, [deviceId]);
  useEffect(() => {
    if (locationMode === 'Map') {
      setMarkers([{ top: initialData.deviceInfo.top, left: initialData.deviceInfo.left }]);
      FloorMapService.ReadById(
        initialData.deviceInfo.floorMapId,
        () => startLoader(),
        hanldeFloorMapDatasuccess,
        (e) => console.log(e),
        () => stopLoader()
      );
      BuildingService.ReadById(
        initialData.deviceInfo.buildingId,
        () => startLoader(),
        hanldeBuldingMapDatasuccess,
        (e) => console.log(e),
        () => stopLoader()
      );
    }
  }, [locationMode]);
  const hanldeBuldingMapDatasuccess = (res) => {
    const building = res.data.data.buildingData;
    setBuildingData({ lat: building.lat, lng: building.lng });
  };
  const hanldeFloorMapDatasuccess = (res) => {
    const canVasImage = res.data.data.floor.imageUrl;
    setCanvasImage(canVasImage);
  };
  useEffect(() => {
    if (value === 1) getImageLog()
    if (value === 2) getAlertData();
    else if (value === 3) getAlertLog();
    else if (value === 4) getEvents();
    if (!value) {
      setSensorLogPage(pagination);
    }
  }, [pagination, value]);

  const handleDeleteCommand = (id) => {
    DeviceDashboardService.deleteCommand(
      id,
      () => startLoader(),
      (res) => handleDeleteSuccess(res, id),
      (e) => console.log(e),
      () => stopLoader()
    );
  };

  const handleDeleteSuccess = (res, id) => {
    let processedCommands = commands.filter(function (obj) {
      return obj.id !== id;
    });
    setCommands(processedCommands);
    toast.success(res.data.message);
  };
  const handleImageShow = (item) => {
    console.log(item)
    setshowImageModel(true)
    setImageUrl(item.url)
  }
  const ImageContent = () => (
    <>
      <div className={classesModule.ModalImage}>
        <img src={ImageUrl} alt="ImageUrl" />
      </div>
    </>
  )
  useEffect(() => {
    switch (value) {
      case 0:
        setTableHead(['S.No', 'Current', 'Date']);
        if (sensorLog['dataArray'] && sensorLog['dataArray'].length) {
          let data;
          if (sensorLog.dataArray[0].current || sensorLog.dataArray[0].current === 0) {
            setTableHead(['S.No', 'Current', 'Date']);
            data = sensorLog.dataArray.map((item, index) => [
              (pagination - 1) * 10 + index + 1,
              item.current,
              new Date(item.datetime).toLocaleString(),
            ]);
          } else {
            setTableHead(['S.No', 'Minimum', 'Average', 'Maximum', 'Date']);
            data = sensorLog.dataArray.map((item, index) => [
              (pagination - 1) * 10 + index + 1,
              item.minimum,
              item.average,
              item.maximum,
              new Date(item.datetime).toLocaleString(),
            ]);
          }
          setTableData(data);
          break;
        }
        setTableData([]);
        break;
      case template === 'Ultrasonic' ? 1 : 10:
        {
          setTableHead(['S.No', "File Name", "Image", "Date", "Action"]);
          let processedAlertData = template === 'Ultrasonic' && ImageLog.map((item, index) => [
            (pagination - 1) * 5 + index + 1,
            `${item.fileName.split('_')[1]}_${item.fileName.split('_')[2]}`,
            (<img style={{ width: "2vw", height: "2vw" }} src={item.url} alt={`${index}url`} />),
            new Date(item.createdAt).toLocaleString(),
            (<ImageSearchIcon style={{ cursor: "pointer" }} onClick={() => { handleImageShow(item) }} />)

          ]);
          template === 'Ultrasonic' && setTableData(processedAlertData);
        }
        break;
      case template === 'Ultrasonic' ? 2 : 1:
        setTableHead(['S.No', 'Device Name', 'Sensor Name', 'Contact', 'Email', 'Value Type', 'Alert Type', 'Created At', 'Criteria']);
        let processedAlertData = alertData.map((item, index) => [
          (pagination - 1) * 10 + index + 1,
          item.deviceName,
          item.sensorName,
          item.contact,
          item.email,
          item.valueType,
          item.alertType,
          new Date(item.createdAt).toLocaleString(),
          getOptions(item),
        ]);
        setTableData(processedAlertData);
        break;
      case template === 'Ultrasonic' ? 3 : 2:
        setTableHead(['S.No', 'Device Name', 'Sensor Name', 'Triggered At', 'Contact', 'Email', 'Value Type', 'Alert Type', 'Created At', 'Criteria']);
        let processedAlertLogData = alertLog.map((item, index) => [
          (pagination - 1) * 10 + index + 1,
          item.deviceName,
          item.sensorName,
          item.triggeredAt,
          item.contact,
          item.email,
          item.valueType,
          item.alertType,
          new Date(item.createdAt).toLocaleString(),
          getOptions(item),
        ]);
        setTableData(processedAlertLogData);
        break;
      case template === 'Ultrasonic' ? 4 : 3:
        setTableHead(['S.No', 'Device Name', 'Set Point', 'Value Type', 'Event Type', 'Created At']);
        console.log(activeEvents);
        const data = activeEvents.map((item, index) => [
          (pagination - 1) * 10 + index + 1,
          item.deviceName,
          item.setPoint,
          item.valueType,
          item.eventType,
          item.createdAt,
        ]);
        data.map((data) => (data[data.length - 1] = new Date(data[data.length - 1]).toLocaleString()));
        setTableData(data);
        break;
      case template === 'Ultrasonic' ? 5 : 4:
        setTableHead([]);
        setTableData([]);
        break;
      case template === 'Ultrasonic' ? 6 : 5:
        setTableHead(['S.No', 'Command', 'Created At', '']);
        const commandData = commands
          ? commands.map((item, index) => [
            index + 1,
            item.command,
            new Date(item.createdAt).toLocaleString(),
            <CustomToolTip title={'Delete User'} onClick={() => handleDeleteCommand(item.id)} Icon={DeleteIcon} />,
          ])
          : [];
        setTableData(commandData);
        break;
      case template === 'Ultrasonic' ? 7 : 6:
        setTableHead([]);
        setTableData([]);
        break;
      default:
        break;
    }
  }, [sensorLog, value, alertData, alertLog, activeEvents, commands]);

  const handleChange = (event, value) => {
    console.log('handl change');
    setPagination(1);
    if (template !== 'Ultrasonic' && locationMode === 'Map' && value === 5) {
      setValue(value + 1);
    } else {
      setValue(value);
    }
  };

  const getEvents = async () => {
    console.log('get events');
    await DeviceDashboardService.ReadActiveEvents(
      pagination,
      deviceId,
      ({ data }) => {
        setActiveEvents(data.data.eventData);
        setTotalPages(Math.ceil(data.data.pagination.totalCount / LIMIT));
      },
      fetchError
    );
  };

  const getAlertData = () => {
    console.log('get alet daata');
    DeviceDashboardService.ReadAlertData(
      pagination,
      deviceId,
      ({ data }) => {
        setAlertData(data.data.alertData);
        setTotalPages(Math.ceil(data.data.pagination.totalCount / LIMIT));
      },
      fetchError
    );
  };

  const getAlertLog = () => {
    console.log('get alet lg');
    DeviceDashboardService.ReadAlertLog(
      pagination,
      deviceId,
      ({ data }) => {
        setAlertLog(data.data.alertLogData);
        setTotalPages(Math.ceil(data.data.pagination.totalCount / LIMIT));
      },
      fetchError
    );
  };

  const getImageLog = () => {
    DeviceDashboardService.ReadImageLog(
      pagination,
      deviceId,
      ({ data }) => {
        setImageLog(data.data.imageLogData);
        console.log(data.data)
        setTotalPages(Math.ceil(data.data.pagination.totalCount / 5));
      },
      (err) => { console.log(err) },
      () => { }
    )
  }

  const showCriteria = (item) => {
    const { aLabel, bLabel, bDisabled } = getFields(item);
    const content = (
      <>
        <Typography>
          {aLabel} : {item.criteria.values[0]}
        </Typography>
        {bDisabled || (
          <Typography>
            {bLabel} : {item.criteria.values[1] || item.criteria.timePeriod}
          </Typography>
        )}
      </>
    );
    return setCriteria({ show: true, content });
  };

  const getOptions = (item) => {
    return (
      <>
        <CustomToolTip title='View Criteria' onClick={() => showCriteria(item)} Icon={DetailsIcon} />
      </>
    );
  };
  const fetchError = (err) => console.log(err);
  console.log(BuildingData, deviceLocation);
  const propsData = template === 'Ultrasonic' ? { ...tabProps(2) } : { ...tabProps(1) }
  return (
    <Fragment>
      <FormDialog
        show={showModal}
        onClose={() => setShowModal(false)}
        title={`Add Command`}
        content={
          <AddCommand
            commands={commands}
            setCommands={setCommands}
            setShowModal={setShowModal}
            deviceName={deviceId}
            onClose={() => setShowModal(false)}
            startLoader={startLoader}
            stopLoader={stopLoader}
          />
        }
      />

      <FormDialog
        show={showImageModel}
        onClose={() => setshowImageModel(false)}
        title={``}
        content={
          ImageContent()
        }
      />
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <Tabs value={value} onChange={handleChange} className='primary-tab'>
                <Tab label='Sensor Log' {...tabProps(0)} className='tab-fonts' />
                {template === 'Ultrasonic' ? <Tab label='Image Log' {...tabProps(1)} className='tab-fonts' /> : null}
                <Tab label='Active Alerts' {...propsData} className='tab-fonts' />
                <Tab label='Alert Log' {...tabProps(3)} className='tab-fonts' />
                <Tab label='Active Events' {...tabProps(4)} className='tab-fonts' />

                <Tab label='Map' {...tabProps(5)} className='tab-fonts' />
                {template === 'Ultrasonic' ? <Tab label='Commands' {...tabProps(6)} className='tab-fonts' /> : null}
                {locationMode === 'Map' ? <Tab label='Room Map' {...tabProps(7)} className='tab-fonts' /> : null}
              </Tabs>
              <FormDialog
                show={criteria.show}
                onClose={() => setCriteria({ show: false, content: null })}
                title={`Criteria`}
                content={criteria.content}
              />
              <TabPanel
                className='device-dashboard-table'
                value={value < template === 'Ultrasonic' ? 7 : 6 && value != template === 'Ultrasonic' ? 5 : 4 ? value : 0}
                index={value < template === 'Ultrasonic' ? 7 : 6 && value != template === 'Ultrasonic' ? 5 : 4 ? value : 0}
              >
                {template === 'Ultrasonic' && value === (template === 'Ultrasonic' ? 6 : 5) ? (
                  <div className='AddCommand'>
                    <Button onClick={() => setShowModal(true)} className={classes.button} variant='outlined' color='primary' mb={2}>
                      Add Command
                    </Button>
                  </div>
                ) : null}
                <Table tableHeaderColor='primary' tableHead={tableHead} tableData={tableData} />
              </TabPanel>
              <TabPanel value={value} index={template === 'Ultrasonic' ? 5 : 4}>
                <MapComponent defaultLocation={deviceLocation} defaultDraggable={false} />
              </TabPanel>
              <TabPanel value={value} index={locationMode === 'Map' && template === 'Ultrasonic' ? 7 : locationMode === 'Map' ? 6 : 5}>
                {locationMode === 'Map' ? <CanvasComponent markers={markers} setMarkers={setMarkers} image={canvasImage} /> : null}
              </TabPanel>
              {value && totalPages > 1 ? (
                <Grid item xs={12} className={classes.pagination + ' device-dashboard-paginate'}>
                  <Pagination
                    count={totalPages}
                    page={pagination}
                    variant='outlined'
                    color='primary'
                    onChange={(e, page) => setPagination(page)}
                  />
                </Grid>
              ) : null}
              {!value && sensorLogTotalPages > 1 ? (
                <Grid item xs={12} className={classes.pagination + ' device-dashboard-paginate'}>
                  <Pagination
                    count={sensorLogTotalPages}
                    page={pagination}
                    variant='outlined'
                    color='primary'
                    onChange={(e, page) => setPagination(page)}
                  />
                </Grid>
              ) : null}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Fragment>
  );
};
