import { HttpClient } from "../utils/httpClient";

const PATH = {
    create: "/event",
    read: "/event",
    delete: "/event",
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.create}`, payload)
        .then(callback)
        .catch(error)
        .finally(next);
};

const Read = (limit, offset, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read, { params: { limit, offset } })
        .then(callback)
        .catch(error)
        .finally(next);
};

const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

export const EventService = {
    Create,
    Read,
    Delete,
};
