import React, { useContext, useState, useEffect, Fragment } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Table from "../../components/Table/Table";
import { LoaderContext } from "../../context/LoaderContext";
import { BRIEFS, START_LOADER, STOP_LOADER } from "../../constants";
import { Pagination } from "@material-ui/lab";
import { mqttLogService } from "../../services/mqttLogService";
import Button from "../../components/CustomButtons/Button";
import moment from "moment";
import { FormDialog } from "../../components/FormDialog/FormDialog";
import { DeleteDialog } from "../../components/FormDialog/DeleteDialog";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { toast } from "react-toastify";
import AddUdpRoute from "./AddudpRoute";
import { udpRoutingService } from "../../services/UdpRoutingService";

const useStyles = makeStyles({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  blockFrom: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    padding: "0 8px",
  },
  blockParent: {
    display: "flex",
    alignItems: "baseline",
  },
  blockBtn: {
    marginRight: "5px",
  },
  button: {
    float: "right",
  },
  pagination: {
    marginLeft: "auto",
  },
  searchBtn: {
    margin: 0,
    padding: "11px 12px 11px 15px",
  },
  searchWrapper: {
    paddingRight: "0",
    marginRight: "1.3px",
  },
  searchInput: {
    padding: "11px 14px",
  },
  buttonContainer: {
    textAlign: "center",
  },
});

const LIMIT = 10;

export const UdpRouting = () => {
  const { dispatch } = useContext(LoaderContext);
  const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });
  const classes = useStyles();
  const [refresh, setRefresh] = useState(0);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const [showModal, setShowModal] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);

  const handleDelete = (id) => {
    udpRoutingService.Delete(
      id,
      () => startLoader("deleteAll"),
      handleDeleteSuccess,
      handleError,
      () => stopLoader("deleteAll")
    );
  };

  const rules = useLocations(
    refresh,
    pagination.curPage,
    setPagination,
    handleDelete
  );

  const handleDeleteSuccess = () => {
    setRefresh((prev) => prev + 1);
    toast.success("Logs deleted!");
  };

  const handleError = (err) => {
    toast.error(err?.response?.data?.message);
  };

  return (
    <Fragment>
      <FormDialog
        show={showBlockModal}
        onClose={setShowBlockModal}
        title="Add Rule"
        content={
          <AddUdpRoute
            classes={classes}
            stopLoader={stopLoader}
            startLoader={startLoader}
            setOpen={setShowBlockModal}
            setRefresh={setRefresh}
          />
        }
      />
      <DeleteDialog
        show={showModal}
        onClose={setShowModal}
        deletefunction={handleDelete}
      />
      <Grid container direction="column" spacing={2}>
        <div className="info-container">
          <div>
            <div className="info-heading">{BRIEFS?.udpRoutes?.heading}</div>
            <ul className="info-list">
              {Object.values(BRIEFS?.udpRoutes?.description).map((desc) => (
                <li>{desc}</li>
              ))}
            </ul>
          </div>
          <div>
            <Grid item className="info-container">
              <Button
                onClick={() => setShowBlockModal(true)}
                className={classes.button}
                variant="contained"
                color="primary"
                mb={2}
              >
                Add Rule
              </Button>
            </Grid>
          </div>
        </div>
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>All Rules</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Device ID", "Host", "Port", "Action"]}
                tableData={rules}
              />
            </CardBody>
          </Card>
        </Grid>
        {pagination.totalPages > 1 ? (
          <Grid item xs={12} className={classes.pagination}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.curPage}
              variant="outlined"
              color="primary"
              onChange={(e, value) =>
                setPagination((prev) => ({
                  ...prev,
                  curPage: value,
                }))
              }
            />
          </Grid>
        ) : null}
      </Grid>
    </Fragment>
  );
};

const useLocations = (refresh, curPage, setPagination, handleDelete) => {
  const [rules, setRules] = useState([]);
  useEffect(() => {
    getData();
  }, [refresh, curPage]);

  const getData = () => {
    const params = {
      limit: LIMIT,
      offset: (curPage - 1) * LIMIT,
    };

    udpRoutingService.Read(
      params,
      () => {},
      handleLogsReadSuccess,
      handleLogsReadError,
      () => {}
    );
  };

  const handleLogsReadSuccess = (res) => {
    const data = res.data.data;
    const pagination = data.pagination;
    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(pagination.totalCount / LIMIT),
    }));
    const finalData = data.udpRoutes.map((item) => [
      item.deviceId,
      item.host,
      item.port,
      <DeleteIcon
        style={{ cursor: "pointer" }}
        onClick={() => handleDelete(item.id)}
      />,
    ]);
    setRules(finalData);
  };

  const handleLogsReadError = (err) => {
    console.log(err);
  };

  return rules;
};
