import { HttpClient } from "../utils/httpClient";

const PATH = {
    create: "/floor",
    read: "/floor",
    delete: "/floor",
    upload: "/floor/upload",
    getFloorByBuilding:"/floorByBuilding"
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload)
        .then(callback)
        .catch(error)
        .finally(next);
};

const Read = (limit, offset, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.read}`, { params: { limit, offset } })
        .then(callback)
        .catch(error)
        .finally(next);
};

const ReadById = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.read}/${id}`)
        .then(callback)
        .catch(error)
        .finally(next);
};
const getFloorByBuilding = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getFloorByBuilding}/${id}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

const Upload = ( payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.upload}`, payload)
        .then(callback)
        .catch(error)
        .finally(next);
};

const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

export const FloorMapService = {
    Create,
    Read,
    ReadById,
    Upload,
    Delete,
    getFloorByBuilding
};
