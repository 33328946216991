import { Button, Grid, makeStyles } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React from "react";
import {
  DropDown,
  MultiSelectDropDown,
} from "../../components/DropDown/DropDown";
import { DeviceService } from "../../services/DeviceService";
import { BulkDeviceEditValidation } from "../../validators/BulkDeviceEditvalidation";
import { useAllDevices } from "../alert/AddAlert";

const useStyles = makeStyles({
  buttonContainer: {
    textAlign: "center",
  },
});

const BulkEdit = ({
  managers,
  startLoader,
  stopLoader,
  onSuccess,
  onClose,
  edit = null,
  userRole,
  user,
}) => {
  const classes = useStyles();
  const hasAccess = userRole === "admin";
  const availableManagers = (managers || []).map((x) => ({
    id: x.id,
    name: x.name,
  }));
  const initialValues = {
    deviceId: [],
    managerId: "",
  };
  const devices = useAllDevices(startLoader, stopLoader);
  const availableDevices = (
    devices && devices.deviceTable ? devices.deviceTable : []
  ).map((x) => ({
    id: x,
    name: x,
  }));

  const handleFetchError = (err) => {
    console.log(err);
  };

  const handleSubmit = (values) => {
    DeviceService.BulkUpdate(
      values,
      () => startLoader("bulkupdate"),
      ({ data }) => onSuccess(),
      handleFetchError,
      () => stopLoader("bulkupdate")
    );
  };

  return (
    <div>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={BulkDeviceEditValidation}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ handleSubmit, isSubmitting, values, ...props }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Field
                  component={MultiSelectDropDown}
                  name="deviceId"
                  type="select"
                  values={availableDevices}
                  fullWidth
                  label="Device"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Field
                  component={DropDown}
                  name="managerId"
                  type="select"
                  values={availableManagers}
                  fullWidth
                  label="Manager"
                />
              </Grid>
              <Grid item xs={12} className={classes.buttonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mb={2}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BulkEdit;
