import * as yup from "yup";

export const UdpRuleValidation = yup.object({
  deviceId: yup.string().required(),
  host: yup.string().required(),
  port: yup.number().required(),
  topic: yup.string().required(),
  prePostEnable: yup.boolean().required(),
  connectMessage: yup.string().when("prePostEnable", {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  disconnectMessage: yup.string().when("prePostEnable", {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  connectTopic: yup.string().when("prePostEnable", {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  disconnectTopic: yup.string().when("prePostEnable", {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  connectRetain: yup.boolean().when("prePostEnable", {
    is: true,
    then: yup.boolean().required(),
    otherwise: yup.boolean(),
  }),
  disconnectRetain: yup.boolean().when("prePostEnable", {
    is: true,
    then: yup.boolean().required(),
    otherwise: yup.boolean(),
  }),
  tlsEnabled: yup.boolean().required(),
  certificate: yup.array().when("tlsEnabled", {
    is: true,
    then: yup
      .array()
      .of(yup.string().required("certificate is a required field"))
      .min(1),
    otherwise: yup.array().of(yup.string()),
  }),
});
