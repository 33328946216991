import React, { useState, Fragment, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Table from "../../components/Table/Table";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { Field, Form, Formik } from "formik";
import { Input } from "../../components/Input/Input";
import { BlockTopicValidation } from "../../validators/BlockTopicValidator";
import Button from "../../components/CustomButtons/Button";
import { toast } from "react-toastify";
import { mqttLogService } from "../../services/mqttLogService";
import { Pagination } from "@material-ui/lab";
import { CustomToolTip } from "../../components/CustomToolTip/CustomToolTip";
import { Delete as DeleteIcon } from "@material-ui/icons";

const LIMIT = 4;

const BlockTopic = ({ classes, startLoader, stopLoader }) => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });

  useEffect(() => {
    getData();
  }, [pagination.curPage]);

  const getData = () => {
    mqttLogService.ReadBlockTopic(
      LIMIT,
      (pagination.curPage - 1) * LIMIT,
      () => startLoader("getBlockTopic"),
      handleSuccess,
      handleError,
      () => stopLoader("getBlockTopic")
    );
  };
  const handleSuccess = ({ data }) => {
    if (data.data) {
      const blockTopic = data.data.blockedTopics;
      const pagination = data.data.pagination;

      setPagination((prev) => ({
        ...prev,
        totalPages: Math.ceil(pagination.totalCount / LIMIT),
      }));
      const finalData = blockTopic.map((item) => [
        item.id,
        item.topic,
        <DeleteIcon
          style={{ cursor: "pointer" }}
          onClick={() => handleDelete(item.id)}
        />,
      ]);
      if (finalData.length) setData(finalData);
    }
  };

  const handleSubmit = (values, resetForm) => {
    mqttLogService.CreateBlockTopic(
      values,
      () => startLoader("createBlockTopic"),
      ({ data }) => {
        getData();
        resetForm();
        toast.success("Topic Blocked!");
      },
      handleError,
      () => stopLoader("createBlockTopic")
    );
  };

  const handleDelete = (id) => {
    if (id) {
      mqttLogService.DeleteBlockTopic(
        id,
        () => startLoader("deleteBlockTopic"),
        ({ data }) => {
            getData();
            toast.success("Deleted!");
          },
        handleError,
        () => stopLoader("deleteBlockTopic")
      );
    }
  };

  const handleError = (err) => {
    toast.error(err?.response?.data?.message);
  };

  return (
    <Fragment>
      <Grid container direction="column" spacing={2}>
        <div className="info-container">
          <Formik
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm);
            }}
            validationSchema={BlockTopicValidation}
            initialValues={{ topicName: "" }}
          >
            <Form className={classes.blockFrom}>
              <Grid className={classes.blockParent}>
                <Grid container direction="column">
                  <Field
                    component={Input}
                    name="topicName"
                    type="text"
                    label="Topic Name"
                    className={classes.blockBtn}
                  />
                </Grid>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Grid>
            </Form>
          </Formik>
        </div>
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Logs</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Id", "Topic", "Action"]}
                tableData={data}
              />
            </CardBody>
          </Card>
        </Grid>
        {pagination.totalPages > 1 ? (
          <Grid item xs={12} className={classes.pagination}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.curPage}
              variant="outlined"
              color="primary"
              onChange={(e, value) =>
                setPagination((prev) => ({
                  ...prev,
                  curPage: value,
                }))
              }
            />
          </Grid>
        ) : null}
      </Grid>
    </Fragment>
  );
};

export default BlockTopic;
