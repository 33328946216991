import React, { Fragment, useState, useContext, useEffect } from "react";
import { FormDialog } from "../../components/FormDialog/FormDialog";
import { Grid, makeStyles } from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Table from "../../components/Table/Table";
import { Pagination } from "@material-ui/lab";
import { Backup, Delete as DeleteIcon, HighlightOff } from "@material-ui/icons";
import { AddFloorMap } from "./AddFloorMap";
import { LoaderContext } from "../../context/LoaderContext";
import { BRIEFS, START_LOADER, STOP_LOADER } from "../../constants";
import { FloorMapService } from "../../services/FloorMapService";
import { CustomToolTip } from "../../components/CustomToolTip/CustomToolTip";
import { toast } from "react-toastify";
import { AuthService } from "../../services/AuthService";

const useStyles = makeStyles({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  button: {
    float: "right",
  },
  pagination: {
    margin: "auto",
  },
});

const LIMIT = 10;
export const FloorMapPage = () => {
  const userRole = AuthService.getUserRole();
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [uploadFileModal, setUploadFileModal] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const { dispatch } = useContext(LoaderContext);
  const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });

  const onSensorAddSuccess = () => {
    setRefresh((prev) => prev + 1);
    setShowModal(false);
  };

  const deleteUploadFile = (id) => {
    FloorMapService.deleteFile(
      id,
      () => startLoader("deletefileUpload"),
      fileDeleteSuccess,
      (err) => console.log(err),
      () => stopLoader("deletefileUpload")
    );
  };

  const fileDeleteSuccess = (res) => {
    console.log(res);
    res.data.error
      ? toast.error(res.data.message)
      : toast.success(res.data.message);
  };

  const sensors = useSensors(
    startLoader,
    stopLoader,
    refresh,
    setRefresh,
    pagination.curPage,
    setPagination,
    setUploadFileModal,
    deleteUploadFile,
    userRole
  );

  return (
    <Fragment>
      <FormDialog
        onClose={() => setShowModal(false)}
        show={showModal}
        title={`Add Room`}
        content={
          <AddFloorMap
            onClose={() => setShowModal(false)}
            startLoader={startLoader}
            stopLoader={stopLoader}
            onSuccess={onSensorAddSuccess}
          />
        }
      />

      <Grid container direction="column" spacing={2}>
        <div className="info-container">
          <div>
            <div className="info-heading">
              {BRIEFS?.manageFloorMap?.heading}
            </div>
            <ul className="info-list">
              {Object.values(BRIEFS?.manageFloorMap?.description).map(
                (desc) => (
                  <li>{desc}</li>
                )
              )}
            </ul>
          </div>
          <div>
            <Grid item>
              <Button
                onClick={() => setShowModal(true)}
                className={classes.button}
                variant="contained"
                color="primary"
                mb={2}
              >
                Add Room
              </Button>
            </Grid>
          </div>
        </div>
        <Grid item xs={12} sm={12} md={12} className="w-65 margin-auto">
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Rooms</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={
                  userRole === "admin"
                    ? [
                        "SNo",
                        "Room Name",
                        "Dwelling Name",
                        "Created At",
                        "Options",
                      ]
                    : [
                        "SNo",
                        "Room Name",
                        "Dwelling Name",
                        "Created At",
                        "Options",
                      ]
                }
                tableData={sensors}
              />
            </CardBody>
          </Card>
        </Grid>
        {pagination.totalPages > 1 ? (
          <Grid item xs={12} className={classes.pagination}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.curPage}
              variant="outlined"
              color="primary"
              onChange={(e, value) =>
                setPagination((prev) => ({
                  ...prev,
                  curPage: value,
                }))
              }
            />
          </Grid>
        ) : null}
      </Grid>
    </Fragment>
  );
};

const useSensors = (
  startLoader,
  stopLoader,
  refresh,
  setRefresh,
  curPage,
  setPagination,
  setUploadFileModal,
  deleteUploadFile,
  userRole
) => {
  const [floorMapdata, setFloorMapdata] = useState([]);
  useEffect(() => {
    FloorMapService.Read(
      LIMIT,
      (curPage - 1) * LIMIT,
      () => startLoader("mapFloorFetch"),
      handleMapFloorFetchSuccess,
      handleMapFloorFetchError,
      () => stopLoader("mapFloorFetch")
    );
  }, [curPage, refresh]);
  const handleMapFloorFetchSuccess = (res) => {
    const data = res.data.data;
    const pagination = data.pagination;
    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(pagination.totalCount / LIMIT),
    }));
    const processedData =
      userRole === "admin"
        ? data.floor.map((item) => [
            item.floorId,
            item.floorName,
            item.buildingName,
            new Date(item.createdAt).toLocaleString(),
            getOptions(item.floorId),
          ])
        : data.floor.map((item) => [
            item.floorId,
            item.floorName,
            item.buildingName,
            new Date(item.createdAt).toLocaleString(),
            getOptions(item.floorId),
          ]);
    setFloorMapdata(processedData);
  };
  const handleMapFloorFetchError = (err) => {
    console.log(err);
  };
  const handleSensorDelete = (id) => {
    FloorMapService.Delete(
      id,
      () => startLoader("deleteFloor"),
      () => setRefresh((prev) => prev + 1),
      () => setRefresh((prev) => prev + 1),
      () => stopLoader("deleteFloor")
    );
  };
  const getOptions = (id) => {
    return (
      <>
        <CustomToolTip
          title={"Delete Room"}
          onClick={() => handleSensorDelete(id)}
          Icon={DeleteIcon}
        />
      </>
    );
  };
  return floorMapdata.map((x, i) => [
    (curPage - 1) * LIMIT + i + 1,
    ...x.slice(1),
  ]);
};
