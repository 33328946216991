// context
export const SET_USER = "SET_USER";
export const REMOVE_USER = "REMOVE_USER";
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";

// stored credentials
export const STORED_CREDENTIALS = "DASHBOARD_USER";

// local path
export const LOCAL_PATH = {
  LOGIN: "/auth/login",
  HOME: "/dashboard",
  DEVICE: "/dashboard/info",
  PROFILE: "/dashboard/profile",
  ALERT: "/dashboard/alert",
  RESET_PASSWORD: "/auth/resetPassword",
};

//users
export const USER_ROLES = ["admin", "manager", "assistant"];

// alerts

export const ALERTS = {
  VALUE_TYPES: ["Current", "Minimum", "Average", "Maximum"],
  ALERT_TYPE: ["Restrict Range", "In Between", "Periodic", "Above", "Below"],
  TIME_PERIOD: [
    [1, "1 hour"],
    [3, "3 hours"],
    [6, "6 hours"],
    [12, "12 hours"],
    [24, "24 hours (1 day)"],
    [48, "48 hours (2 days)"],
    [72, "72 hours (3 days)"],
    [168, "168 hours (1 week)"],
    [336, "336 hours (2 weeks)"],
    [672, "672 hours (4 weeks)"],
  ],
  MQTT_ENABLED: ["enabled", "disabled"],
};

// events

export const EVENTS = {
  VALUE_TYPES: ["Current", "Minimum", "Average", "Maximum"],
  EVENT_TYPES: ["Above", "Below"],
};

export const DAYS = [
  { id: 1, name: "Monday" },
  { id: 2, name: "Tuesday" },
  { id: 3, name: "Wednesday" },
  { id: 4, name: "Thursday" },
  { id: 5, name: "Friday" },
  { id: 6, name: "Saturday" },
  { id: 7, name: "Sunday" },
];
export const ALERT_TYPES = [
  {
    name: "Restrict Range",
    description:
      "Alert will be triggered if a sensor reading is outside of the upper and lower thresholds.",
  },
  {
    name: "In Between",
    description:
      "Alert will be triggered if a sensor reading is between the upper and lower thresholds. ",
  },
  {
    name: "Periodic",
    description:
      "Alert will be triggered if a sensor hasn’t reached the set point within the time period specified",
  },
  {
    name: "Above",
    description:
      "Alert will be triggered if a sensor goes above the set point.",
  },
  {
    name: "Below",
    description:
      "Alert will be triggered if a sensor goes below the set point.",
  },
];
export const BRIEFS = {
  manageDevice: {
    heading: "Manage all your allocated devices",
    description: {
      1: `Add new devices, remove old ones or amend any device characteristic.`,
      2: `Click Add Device to fill in all the new devices credentials or select Edit or Delete under the Options column.`,
    },
  },
  sensorTypes: {
    heading: "Manage Sensor Types",
    description: {
      1: `Add new types of sensor to your repository, remove old ones or amend existing types. `,
      2: `Click the Add Sensor button, specify a Sensor Type Name and select who the sensor type is allocated to. `,
      3: `If required, use the Options column to upload an excel spreadsheet with sensor configuration look up table to convert from binary or hex format into human
readable text.`,
    },
  },
  assignDevices: {
    heading: "Assign Devices to specific users",
    description: {
      1: `Select the specific sensor from the Device List then select which user the device should be allocated to.`,
    },
  },
  manageCompany: {
    heading: "Manage the company for customers",
    description: {
      1: `Add, remove or amend company for customers.`,
    },
  },
  manageLocations: {
    heading: "Manage the location of customers",
    description: {
      1: `Add, remove or amend location of customers.`,
    },
  },
  manageBuildings: {
    heading: "Create or remove Dwelling that devices can be added to",
    description: {
      1: `Add new dwellings or remove old ones. Click the Add Dwelling button to add new, or click on the delete icon under the Action column.`,
    },
  },
  manageFloorMap: {
    heading: "Manage rooms within dwellings",
    description: {
      1: `Once you have added a dwelling you can add rooms to them.`,
      2: `Click Add room, specify the name of the room & select the dwelling its in, then complete by clicking Submit. `,
    },
  },
  manageAlerts: {
    heading: "Manage Alerts for all your devices",
    description: {
      1: `Alerts are extremely useful to let you know when a device needs attention. `,
      2: `Select the best alert type for you, set the reporting conditions then click Submit to enable the alert. `,
    },
  },
  manageEvents: {
    heading: "Add, remove or amend different event types to be displayed.",
    description: {
      1: `Events is something non urgent but nonetheless important that you want to keep your eye on. For example how many times a temperature sensor has gone above a set point or how often a door
was opened or closed. Events will be displayed the Device view next to the graph.`,
    },
  },
  autoReport: {
    heading: "Automatic reporting for all allocated devices",
    description: {
      1: `Set the status of Alerts and/or Events to Active to receive emailed report. Specify the desired frequency using the Configuration options. `,
    },
  },
  manageUsers: {
    heading: "Add, remove or amend any users allocated to you",
  },
  mqttLogs: {
    heading: "Global MQTT Logs",
    description: {
      1: `Devices posting data to MQTT broker are populated unmoderated`,
    },
  },
  udpRoutes: {
    heading: "UDP Data Routes",
    description: {
      1: "The data posted using the Device IDs will be routed to respective MQTT brokers",
    },
  },
  udpLogs: {
    heading: "UDP Logs",
    description: {
      1: "Devices posting data to UDP and routed to MQTT are populated unmoderated",
    },
  },
};
