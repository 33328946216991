import { HttpClient } from "../utils/httpClient";

const PATH = {
    create: "/building",
    read: "/building",
    delete: "/building",
    update: "/building",
    All:"/getAllBuildings"
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload)
        .then(callback)
        .catch(error)
        .finally(next);
};

const Read = (limit, offset, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.read}`, { params: { limit, offset } })
        .then(callback)
        .catch(error)
        .finally(next);
};
const GetAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.All}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

const ReadById = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.read}/${id}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload)
        .then(callback)
        .catch(error)
        .finally(next);
};

const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

export const BuildingService = {
    Create,
    Read,
    ReadById,
    Update,
    Delete,
    GetAll
};
