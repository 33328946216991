import { HttpClient } from "../utils/httpClient";

const PATH = {
  autoReport: "/autoReport",
  getExport: "/autoReport/getExportLog",
};

const Read = (start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.autoReport}`)
    .then(callback)
    .catch(error)
    .finally(next);
};

const Update = (payload, start, callback, error, next) => {
  start();
  return HttpClient.post(`${PATH.autoReport}`, payload)
    .then(callback)
    .catch(error)
    .finally(next);
};

const getExports = async (deviceId, fromDate, toDate) => {
  const res = await HttpClient.get(`${PATH.getExport}/${deviceId}`, {
    params: { fromDate, toDate },
  });
  return res.data
};

export const AutoReportService = {
  Read,
  Update,
  getExports,
};
