import React, { Fragment, useState, useContext, useEffect } from "react";
import { FormDialog } from "../../components/FormDialog/FormDialog";
import { Grid, makeStyles } from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Table from "../../components/Table/Table";
import { Pagination } from "@material-ui/lab";
import { Backup, Delete as DeleteIcon, HighlightOff } from "@material-ui/icons";
import { AddSensor } from "./AddSensor";
import { LoaderContext } from "../../context/LoaderContext";
import { BRIEFS, START_LOADER, STOP_LOADER } from "../../constants";
import { SensorService } from "../../services/SensorService";
import { CustomToolTip } from "../../components/CustomToolTip/CustomToolTip";
import { DropzoneDialog } from "material-ui-dropzone";
import { toast } from "react-toastify";
import { AuthService } from "../../services/AuthService";

const useStyles = makeStyles({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  button: {
    float: "right",
  },
  pagination: {
    margin: "auto",
  },
});

const LIMIT = 10;
export const SensorPage = () => {
  const userRole = AuthService.getUserRole();
  const user = AuthService.getCurrentUser();
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [uploadFileModal, setUploadFileModal] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const { dispatch } = useContext(LoaderContext);
  const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });

  const onSensorAddSuccess = () => {
    setRefresh((prev) => prev + 1);
    setShowModal(false);
  };

  const handleFileUpload = (file) => {
    let formData = new FormData();
    formData.append("file", file[0]);
    SensorService.uploadFile(
      uploadFileModal,
      formData,
      () => startLoader("fileUpload"),
      fileUploadSuccess,
      (err) => console.log(err),
      () => stopLoader("fileUpload")
    );
    setUploadFileModal(false);
  };

  const deleteUploadFile = (id) => {
    SensorService.deleteFile(
      id,
      () => startLoader("deletefileUpload"),
      fileDeleteSuccess,
      (err) => console.log(err),
      () => stopLoader("deletefileUpload")
    );
  };

  const fileUploadSuccess = (res) => {
    res.data.error
      ? toast.error(res.data.message)
      : toast.success(res.data.message);
  };

  const fileDeleteSuccess = (res) => {
    console.log(res);
    res.data.error
      ? toast.error(res.data.message)
      : toast.success(res.data.message);
  };

  const sensors = useSensors(
    startLoader,
    stopLoader,
    refresh,
    setRefresh,
    pagination.curPage,
    setPagination,
    setUploadFileModal,
    deleteUploadFile,
    userRole,
    user
  );

  return (
    <Fragment>
      <FormDialog
        onClose={() => setShowModal(false)}
        show={showModal}
        title={`Add Sensor`}
        content={
          <AddSensor
            onClose={() => setShowModal(false)}
            startLoader={startLoader}
            stopLoader={stopLoader}
            onSuccess={onSensorAddSuccess}
          />
        }
      />
      <DropzoneDialog
        open={uploadFileModal}
        filesLimit={1}
        showFileNames={true}
        previewText="Files:"
        acceptedFiles={[".xls", ".xlsx", ".csv"]}
        onClose={() => setUploadFileModal(false)}
        onSave={(file) => handleFileUpload(file)}
      />
      <Grid container direction="column" spacing={2}>
        <div className="info-container">
          <div>
            <div className="info-heading">{BRIEFS?.sensorTypes?.heading}</div>
            <ul className="info-list">
              {Object.values(BRIEFS?.sensorTypes?.description).map((desc) => (
                <li>{desc}</li>
              ))}
            </ul>
          </div>
          <div>
            <Grid item>
              <Button
                onClick={() => setShowModal(true)}
                className={classes.button}
                variant="contained"
                color="primary"
                mb={2}
              >
                Add Sensor
              </Button>
            </Grid>
          </div>
        </div>
        <Grid item xs={12} sm={12} md={12} className="w-65 margin-auto">
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Sensors</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={
                  userRole === "admin" ||
                  (userRole === "manager" && user.managerAdmin)
                    ? [
                        "SNo",
                        "Sensor Name",
                        "Manager Name",
                        "Sub Manager Name",
                        "Options",
                      ]
                    : ["SNo", "Sensor Name", "Options"]
                }
                tableData={sensors}
              />
            </CardBody>
          </Card>
        </Grid>
        {pagination.totalPages > 1 ? (
          <Grid item xs={12} className={classes.pagination}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.curPage}
              variant="outlined"
              color="primary"
              onChange={(e, value) =>
                setPagination((prev) => ({
                  ...prev,
                  curPage: value,
                }))
              }
            />
          </Grid>
        ) : null}
      </Grid>
    </Fragment>
  );
};

const useSensors = (
  startLoader,
  stopLoader,
  refresh,
  setRefresh,
  curPage,
  setPagination,
  setUploadFileModal,
  deleteUploadFile,
  userRole,
  user
) => {
  const [sensors, setSensors] = useState([]);
  useEffect(() => {
    SensorService.Read(
      LIMIT,
      (curPage - 1) * LIMIT,
      () => startLoader("sensorFetch"),
      handleSensorFetchSuccess,
      handleSensorFetchError,
      () => stopLoader("sensorFetch")
    );
  }, [curPage, refresh]);
  const handleSensorFetchSuccess = (res) => {
    const data = res.data.data;
    const pagination = data.pagination;
    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(pagination.totalCount / LIMIT),
    }));
    const processedData =
      userRole === "admin" || (userRole === "manager" && user.managerAdmin)
        ? data.sensors.map((item) => [
            item.id,
            item.sensorName,
            item.managerName,
            item.subManagerName || '--',
            getOptions(item.id),
          ])
        : data.sensors.map((item) => [
            item.id,
            item.sensorName,
            getOptions(item.id),
          ]);
    setSensors(processedData);
  };
  const handleSensorFetchError = (err) => {
    console.log(err);
  };
  const handleSensorDelete = (id) => {
    SensorService.Delete(
      id,
      () => startLoader("deleteSensor"),
      () => setRefresh((prev) => prev + 1),
      () => setRefresh((prev) => prev + 1),
      () => stopLoader("deleteSensor")
    );
  };
  const getOptions = (id) => {
    return (
      <>
        <CustomToolTip
          title={"Upload Excel"}
          onClick={() => setUploadFileModal(id)}
          Icon={Backup}
        />
        <CustomToolTip
          title={"Remove Mapping"}
          onClick={() => deleteUploadFile(id)}
          Icon={HighlightOff}
        />
        <CustomToolTip
          title={"Delete Sensor"}
          onClick={() => handleSensorDelete(id)}
          Icon={DeleteIcon}
        />
      </>
    );
  };
  return sensors.map((x, i) => [(curPage - 1) * LIMIT + i + 1, ...x.slice(1)]);
};
