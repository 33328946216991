const { STORED_CREDENTIALS } = require("../constants")


const getCurrentUser = () =>{
    let creds=JSON.parse(localStorage.getItem(STORED_CREDENTIALS))
    if(creds&&creds.user) return {...creds.user,token:creds.token}
    return null
}

const getUserRole = () =>{
    let creds=JSON.parse(localStorage.getItem(STORED_CREDENTIALS))
    if(creds&&creds.user) return creds.user.userRole 
    return null
}

const getUserId = () =>{
    let creds=JSON.parse(localStorage.getItem(STORED_CREDENTIALS))
    if(creds&&creds.user) return creds.user.id 
    return null
}

export const AuthService = {
    getCurrentUser,
    getUserRole,
    getUserId
}