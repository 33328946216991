import React from "react";
import { Formik, Form, Field } from "formik";
import { Input } from "../../components/Input/Input";
import { ComapnyAddValidation } from "../../validators/CompanyValidator";
import Button from "../../components/CustomButtons/Button";
import { Grid, makeStyles } from "@material-ui/core";
import { CompanyService } from "../../services/CompanyService";

const useStyles = makeStyles({
    buttonContainer: {
        textAlign: "center",
    },
});
export const AddCompany = ({
    startLoader,
    stopLoader,
    onSuccess,
    onClose,
}) => {
    const classes = useStyles();
    const handleSubmit = (values) => {
        CompanyService.Create(
            values,
            () => startLoader("locationCreate"),
            locationCreateSuccess,
            locationCreateError,
            () => stopLoader("locationCreate")
        );
    };
    const initialValues = {
        companyName: "",
    };
    const locationCreateSuccess = (res) => {
        const data = res.data.data;
        onSuccess(data);
        onClose();
    };
    const locationCreateError = (err) => {
        console.log(err);
        onClose();
    };
    return (
        <Formik
            onSubmit={handleSubmit}
            validationSchema={ComapnyAddValidation}
            initialValues={initialValues}
        >
            <Form>
                <Grid container direction="column" spacing={3}>
                    <Grid item xs={12}>
                        <Field
                            component={Input}
                            name="companyName"
                            type="text"
                            fullWidth
                            label="Company"
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.buttonContainer}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            mb={2}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    );
};
