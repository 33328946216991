import React, { Fragment, useState, useContext, useEffect } from "react";
import { FormDialog } from "../../components/FormDialog/FormDialog";
import { Grid, makeStyles } from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Table from "../../components/Table/Table";
import { Pagination } from "@material-ui/lab";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import { LoaderContext } from "../../context/LoaderContext";
import { START_LOADER, STOP_LOADER, LOCAL_PATH, BRIEFS } from "../../constants";
import { CustomToolTip } from "../../components/CustomToolTip/CustomToolTip";
import { DeviceService } from "../../services/DeviceService";
import { AddDevice } from "./AddDevice";
import { UserService } from "../../services/UserService";
import { SensorService } from "../../services/SensorService";
import { AuthService } from "../../services/AuthService";
import { useHistory } from "react-router-dom";
import BulkEdit from "./BulkEdit";

const useStyles = makeStyles({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  button: {
    float: "right",
  },
  pagination: {
    marginLeft: "auto",
  },
});
const LIMIT = 10;
export const DevicePage = () => {
  const user = AuthService.getCurrentUser();
  const userRole = AuthService.getUserRole();

  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [managers, setManagers] = useState([]);
  const [edit, setEdit] = useState(null);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const { dispatch } = useContext(LoaderContext);
  const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });
  const editDevice = (id) => {
    setEdit(id);
    setShowModal(true);
  };
  const devices = useDevices(
    startLoader,
    stopLoader,
    refresh,
    setRefresh,
    editDevice,
    pagination.curPage,
    setPagination,
    userRole
  );
  const tableHead = ["admin"].includes(userRole)
    ? ["SNo", "Name", "Company", "Alias", "Manager", "Created At", "Options"]
    : ["manager"].includes(userRole)
    ? ["SNo", "Name", "Alias", "Created At", "Options"]
    : ["SNo", "Name", "Alias", "Created At"];

  const onDeviceAddSuccess = () => {
    setRefresh((prev) => prev + 1);
    setShowModal(false);
  };
  const onBulkEditSuccess = () => {
    setRefresh((prev) => prev + 1);
    setShowBulkModal(false);
  };
  useEffect(() => {
    UserService.GetManagers(
      () => startLoader("getManagers"),
      managerFetchSuccess,
      managerFetchError,
      () => stopLoader("getManagers")
    );
  }, []);
  const managerFetchSuccess = (res) => {
    const data = res.data.data;
    setManagers(data.managers);
  };
  const managerFetchError = (err) => {
    console.log(err);
  };
  const onClose = () => {
    setEdit(null);
    setShowModal(false);
  };
  const onCloseBulkModal = () => {
    setShowBulkModal(false);
  };
  return (
    <Fragment>
      <FormDialog
        onClose={onClose}
        show={showModal}
        title={`Add/Edit Device`}
        maxWidth={"lg"}
        content={
          <AddDevice
            edit={edit}
            managers={managers}
            startLoader={startLoader}
            stopLoader={stopLoader}
            onSuccess={onDeviceAddSuccess}
            onClose={onClose}
            userRole={userRole}
            user={user}
          />
        }
      />
      <FormDialog
        onClose={onCloseBulkModal}
        show={showBulkModal}
        title={`Bulk Update`}
        content={
          <BulkEdit
            managers={managers}
            startLoader={startLoader}
            stopLoader={stopLoader}
            onSuccess={onBulkEditSuccess}
            onClose={onCloseBulkModal}
            userRole={userRole}
            user={user}
          />
        }
      />
      <Grid container direction="column" spacing={2}>
        <div className="info-container">
          <div>
            <div className="info-heading">{BRIEFS?.manageDevice?.heading}</div>
            <ul className="info-list">
              {Object.values(BRIEFS?.manageDevice?.description).map((desc) => (
                <li>{desc}</li>
              ))}
            </ul>
          </div>
          <div>
            <Grid item>
              {["admin", "manager"].includes(userRole) && (
                <Button
                  onClick={() => setShowModal(true)}
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  mb={2}
                >
                  Add Device
                </Button>
              )}
              {["admin"].includes(userRole) && (
                <Button
                  onClick={() => setShowBulkModal(true)}
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  mb={2}
                >
                  Bulk Edit
                </Button>
              )}
            </Grid>
          </div>
        </div>
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Devices</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={tableHead}
                tableData={devices}
              />
            </CardBody>
          </Card>
        </Grid>
        {pagination.totalPages > 1 ? (
          <Grid item xs={12} className={classes.pagination}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.curPage}
              variant="outlined"
              color="primary"
              onChange={(e, value) =>
                setPagination((prev) => ({
                  ...prev,
                  curPage: value,
                }))
              }
            />
          </Grid>
        ) : null}
      </Grid>
    </Fragment>
  );
};

const useDevices = (
  startLoader,
  stopLoader,
  refresh,
  setRefresh,
  editDevice,
  curPage,
  setPagination,
  userRole
) => {
  const [devices, setDevices] = useState([]);
  const history = useHistory();
  useEffect(() => {
    DeviceService.Read(
      LIMIT,
      (curPage - 1) * LIMIT,
      () => startLoader("deviceFetch"),
      handleDeviceFetchSuccess,
      handleDeviceFetchError,
      () => stopLoader("deviceFetch")
    );
  }, [curPage, refresh]);
  const handleDeviceFetchSuccess = (res) => {
    const data = res.data.data;
    const pagination = data.pagination;
    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(pagination.totalCount / LIMIT),
    }));
    const processedData = ["admin"].includes(userRole)
      ? data.devices.map((item) => [
          <p
            className="device-link"
            onClick={() =>
              history.push(LOCAL_PATH.DEVICE, { id: item.deviceName })
            }
          >
            {item.deviceName}
          </p>,
          item.companyName,
          item.deviceAlias,
          item.managerName,
          new Date(item.createdAt).toLocaleString(),
          getOptions(item.deviceName),
        ])
      : ["manager"].includes(userRole)
      ? data.devices.map((item) => [
          <p
            className="device-link"
            onClick={() =>
              history.push(LOCAL_PATH.DEVICE, { id: item.deviceName })
            }
          >
            {item.deviceName}
          </p>,
          item.deviceAlias,
          new Date(item.createdAt).toLocaleString(),
          getOptions(item.deviceName),
        ])
      : data.devices.map((item) => [
          <p
            className="device-link"
            onClick={() =>
              history.push(LOCAL_PATH.DEVICE, { id: item.deviceName })
            }
          >
            {item.deviceName}
          </p>,
          item.deviceAlias,
          new Date(item.createdAt).toLocaleString(),
        ]);
    setDevices(processedData);
  };
  const handleDeviceFetchError = (err) => {
    console.log(err);
  };
  const handleDeviceDelete = (id) => {
    DeviceService.Delete(
      id,
      () => startLoader("deleteDevice"),
      () => setRefresh((prev) => prev + 1),
      () => setRefresh((prev) => prev + 1),
      () => stopLoader("deleteDevice")
    );
  };
  const getOptions = (id) => {
    return (
      <>
        <CustomToolTip
          title={"Edit Device"}
          onClick={() => editDevice(id)}
          Icon={EditIcon}
        />
        <CustomToolTip
          title={"Delete Device"}
          onClick={() => handleDeviceDelete(id)}
          Icon={DeleteIcon}
        />
      </>
    );
  };
  return devices.map((x, i) => [(curPage - 1) * LIMIT + i + 1, ...x]);
};
