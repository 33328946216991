import * as yup from "yup";

const sensorIdMetaSchema = yup.object().shape({
  from: yup.string().required("Required"),
  to: yup.string().required("Required"),
  value: yup.string().required("Required"),
});

const metaSchema = yup.object().when(["enabled"], (enabled, schema) => {
  if (enabled) {
    return schema
      .shape({
        "1a": sensorIdMetaSchema.required("Required"),
        "2a": sensorIdMetaSchema.required("Required"),
        "3a": sensorIdMetaSchema.required("Required"),
        "4a": sensorIdMetaSchema.required("Required"),
      })
      .required("Required");
  }
  return schema.shape({});
});

export const DeviceAddValidation = yup.object({
  deviceName: yup.string().required().min(3).max(15),
  deviceAlias: yup.string().required().min(3).max(25),
  managerId: yup.string().required(),
  sensorId1: yup.string().required(),
  sensorId2: yup.string().nullable(),
  sensorId3: yup.string().nullable(),
  sensorId4: yup.string().nullable(),
  smsLimit: yup.number("Must be a number").required().nullable(),
  emailLimit: yup.number("Must be a number").required().nullable(),
  limitRefresh: yup.string().required().nullable(),
  weather: yup.boolean().required(),
  sensorAdjustment: yup
    .object()
    .when(
      ["sensorId2", "sensorId3", "sensorId4"],
      (sensorId2, sensorId3, sensorId4, schema) => {
        const obj = {
          enabled: yup.boolean().required(),
          sensorId1Meta: metaSchema,
        };
        if (sensorId2) obj.sensorId2Meta = metaSchema;
        if (sensorId3) obj.sensorId3Meta = metaSchema;
        if (sensorId4) obj.sensorId4Meta = metaSchema;
        return yup.object().shape(obj);
      }
    ),
  sensorMapping: yup.object().shape({
    enabled: yup.boolean().required(),
    TC1: yup.string().when("enabled", {
      is: true,
      then: yup.string().required("Required"),
    }),
    TC2: yup.string().when("enabled", {
      is: true,
      then: yup.string().required("Required"),
    }),
    TC3: yup.string().when("enabled", {
      is: true,
      then: yup.string().required("Required"),
    }),
    TC4: yup.string().when("enabled", {
      is: true,
      then: yup.string().required("Required"),
    }),
  }),
});

export const DeviceAddValidationWithoutManager = yup.object({
  deviceName: yup.string().required().min(3).max(15),
  deviceAlias: yup.string().required().min(3).max(25),
  sensorId1: yup.string().required(),
  sensorId2: yup.string().nullable(),
  sensorId3: yup.string().nullable(),
  sensorId4: yup.string().nullable(),
  smsLimit: yup.number("Must be a number").required().nullable(),
  emailLimit: yup.number("Must be a number").required().nullable(),
  limitRefresh: yup.string().required().nullable(),
  weather: yup.boolean().required(),
  sensorAdjustment: yup
    .object()
    .when(
      ["sensorId2", "sensorId3", "sensorId4"],
      (sensorId2, sensorId3, sensorId4, schema) => {
        const obj = {
          enabled: yup.boolean().required(),
          sensorId1Meta: metaSchema,
        };
        if (sensorId2) obj.sensorId2Meta = metaSchema;
        if (sensorId3) obj.sensorId3Meta = metaSchema;
        if (sensorId4) obj.sensorId4Meta = metaSchema;
        return yup.object().shape(obj);
      }
    ),
  sensorMapping: yup.object().shape({
    enabled: yup.boolean().required(),
    TC1: yup.string().when("enabled", {
      is: true,
      then: yup.string().required("Required"),
    }),
    TC2: yup.string().when("enabled", {
      is: true,
      then: yup.string().required("Required"),
    }),
    TC3: yup.string().when("enabled", {
      is: true,
      then: yup.string().required("Required"),
    }),
    TC4: yup.string().when("enabled", {
      is: true,
      then: yup.string().required("Required"),
    }),
  }),
});
