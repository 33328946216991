import React from "react";
import { Tooltip, IconButton, makeStyles } from "@material-ui/core";
import tooltipStyles from "../../assets/jss/material-dashboard-react/views/dashboardStyle";

const useTooltipStyles = makeStyles(tooltipStyles);

export const CustomToolTip = ({ onClick, Icon, title }) => {
    const classes = useTooltipStyles();

    return (
        <Tooltip
            id={`location-tooltip-${title}`}
            title={title}
            placement="top"
            classes={{ tooltip: classes.tooltip }}
        >
            <IconButton
                onClick={onClick}
                aria-label={title}
                className={classes.tableActionButton}
            >
                <Icon
                    className={
                        classes.tableActionButtonIcon + " " + classes.edit
                    }
                />
            </IconButton>
        </Tooltip>
    );
};
