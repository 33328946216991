import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "../components/Navbars/Navbar";
// import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar";
import routes from "../routes.js";
import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle";
import bgImage from "../assets/img/sidebar-2.jpeg";
import { PrivateRoute } from "../PrivateRoute";
import { AuthService } from "../services/AuthService";
import BrandLogo from "../assets/iris_iot_logo.png";

let ps;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/dashboard" && prop.roles) {
        return (
          <PrivateRoute
            roles={prop.roles}
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/dashboard" to="/dashboard/home" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  const userRole = AuthService.getUserRole();
  const user = AuthService.getCurrentUser();

  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const image = bgImage;
  const color = "purple";
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/dashboard/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes.filter((route) => {
          if (route.roles && route.roles.includes(userRole)) {
            if (route.path === "/mqttLogs") return user.mqttAdmin === "Active";
            if (route.path === "/location") {
              console.log({ userRole, ma: user.managerAdmin })
              if (userRole === "manager" && !user.managerAdmin) return false;
            }
            return true;
          }

          // route.path === "/mqttLogs"
          //   ? user.mqttAdmin === "Active" //if path is mqttLogs then check user for mqttAdmin key
          //   : true || route.path === "/location"
          //   ? userRole === "admin"
          //     ? (() => {
          //         console.log({ userRole, ma: user.managerAdmin });
          //         return true;
          //       })()
          //     : userRole === "manager" && user.managerAdmin
          //     ? (() => {
          //         console.log({ userRole, ma: user.managerAdmin });
          //         return true;
          //       })()
          //     : false
          //   : true;
        })}
        logoText={"LPRS CS IoT Platform"}
        image={image}
        userRole={userRole}
        mqttAdmin={user.mqttAdmin === "Active"}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        logoImage={BrandLogo}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel} id="scroll-target">
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
      </div>
    </div>
  );
}
