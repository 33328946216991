import React from "react";
import {
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    minHeight: "10rem",
  },
});

export const FormDialog = ({
  show = false,
  text = "",
  title = "",
  content = "",
  onClose = () => {},
  maxWidth = false,
}) => {
  const classes = useStyles();
  const handleCancel = () => onClose(false);

  return (
    <Card mb={6}>
      <div>
        <Dialog
          fullWidth
          open={show}
          onClose={handleCancel}
          aria-labelledby="form-dialog-title"
          scroll="body"
          {...(maxWidth ? { maxWidth } : {})}
        >
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent className={classes.container}>
            {Boolean(text) && <DialogContentText>{text}</DialogContentText>}
            {content}
          </DialogContent>
        </Dialog>
      </div>
    </Card>
  );
};
