import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export const SwitchComponent = ({ label, field, ...rest }) => {
  // const [enabled, setEnabled] = useState(checked);
  return (
    <FormControlLabel
      control={
        <Switch
          // checked={enabled}
          // onChange={(e) => {
          //     onChange(e);
          //     setEnabled((state)=>!state)
          // }}
          // name={name}
          {...field}
          {...rest}
        />
      }
      label={label}
    />
  );
};
