import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import Button from "../../components/CustomButtons/Button";
import { Grid, makeStyles } from "@material-ui/core";
import { DropDown } from "../../components/DropDown/DropDown";
import { AssignDeviceService } from "../../services/AssignDeviceService";
import { AssignDeviceValidation } from "../../validators/AssignDeviceValidator";
import { useAllDevices } from "../alert/AddAlert";

const useStyles = makeStyles({
    buttonContainer: {
        textAlign: "center",
    },
});
export const AssignDevice = ({
    startLoader,
    stopLoader,
    onSuccess,
    onClose,
}) => {
    const classes = useStyles();
    const [device, setDevice] = useState(null);
    const [assistants, setAssistants] = useState([]);
    const initialValues = useInitialValues();
    const devices = useAllDevices(startLoader, stopLoader);


    useEffect(() => {
        if (device) {
            AssignDeviceService.ReadAssistantsByDeviceId(
                device,
                () => startLoader("fetchAssistant"),
                ({data})=>setAssistants(data.data),
                (err)=>console.log(err),
                () => stopLoader("fetchAssistant")
            );
        }
    }, [device]);

    const handleSubmit = (values) => {
        const payload = values;

        console.log(payload);
        AssignDeviceService.Assign(
            payload,
            () => startLoader("assignDevice"),
            assignSuccess,
            assignError,
            () => stopLoader("assignDevice")
        );
    };

    const assignSuccess = (res) => {
        const data = res.data.data;
        console.log(data);
        onSuccess(data);
        onClose();
    };
    const assignError = (err) => {
        console.log(err);
        onClose();
    };

    console.log(assistants)

    return (
        <Formik
            onSubmit={handleSubmit}
            validationSchema={AssignDeviceValidation}
            initialValues={initialValues}
            enableReinitialize
        >
            {({ handleSubmit, isSubmitting, values }) => (
                <Form>
                    {setDevice(values.deviceName)}
                    <Grid container spacing={3}>
                       <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Field
                                component={DropDown}
                                name="deviceName"
                                type="select"
                                values={devices && devices.deviceTable && devices.deviceTable.map((x) => ({
                                    id: x,
                                    name: x,
                                }))}
                                fullWidth
                                label="Device"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Field
                                component={DropDown}
                                name="assistantId"
                                type="select"
                                values={assistants.assistants && assistants.assistants.map((x) => ({
                                    id: x.assistantId,
                                    name: x.name,
                                }))}
                                fullWidth
                                label="Assistant"
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.buttonContainer}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                mb={2}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

const useInitialValues = () => {
    const initialValues = {
        deviceName: "",
        assistantId: "",
    };

    return initialValues;
};

const useAssistants = (startLoader, stopLoader, device) => {
    const [assistants, setAssistants] = useState([]);
    

    const handleFetchSuccess = (res) => {
        const data = res.data.data;
        console.log(data)
        setAssistants(data);
    };
    const handleFetchError = (err) => {
        console.log(err);
    };
    return assistants;
};
