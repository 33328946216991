import { HttpClient } from "../utils/httpClient";

const PATH = {
    create: "/sensorType",
    read: "/sensorType",
    readAll: "/getAllSensorType",
    delete: "/sensorType",
    uploadFile:"/sensorType/upload"
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload)
        .then(callback)
        .catch(error)
        .finally(next);
};

const Read = (limit, offset, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.read}`, { params: { limit, offset } })
        .then(callback)
        .catch(error)
        .finally(next);
};

const ReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAll)
        .then(callback)
        .catch(error)
        .finally(next);
};

const ReadAllByManager = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.readAll}/${id}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

const uploadFile = (id,payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.uploadFile}/${id}`,payload)
        .then(callback)
        .catch(error)
        .finally(next);
};

const deleteFile = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.uploadFile}/${id}`)
        .then(callback)
        .catch(error)
        .finally(next);
};



export const SensorService = {
    Create,
    Read,
    ReadAll,
    ReadAllByManager,
    Delete,
    uploadFile,
    deleteFile
};
