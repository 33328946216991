import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

export const ApexChart = ({ chartData, ...rest }) => {
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  useEffect(() => {
    setOptions({
      annotations: { position: 'front', ...chartData.annotations },
      chart: {
        id: 'basic-line',
      },
      xaxis: {
        categories: chartData?.timeStamp?.map((timeStamp) => {
          let date = new Date(timeStamp).toLocaleString();
          return date;
        }),
        labels: {
          rotate: -62,
          hideOverlappingLabels: true,
        },
      },
      ...rest
    });
    let processedChartData;
    chartData?.current
      ? (processedChartData = { current: chartData?.current })
      : (processedChartData = {
        minimum: chartData?.minimum,
        maximum: chartData?.maximum,
        average: chartData?.average,
      });
    let series = Object.entries(processedChartData).map((entry) => {
      return {
        name: entry[0],
        data: entry[1],
      };
    });
    setSeries(series);
  }, [chartData]);
  return (
    <div className='mixed-chart pd-t-8'>
      <Chart options={options} series={series} type='line' height='400' width='100%' />
    </div>
  );
};

export const ApexSynchronizedChart = ({ chartData = {
  timeStamp: [],
  minimum: [],
  maximum: [],
  average: [],
  rainData: [],
}, ...rest }) => {
  console.log(chartData)
  const timestampArray = chartData?.timeStamp.map((timeStamp) => {
    let date = new Date(timeStamp).toLocaleString();
    return date;
  })
  const options = {
    // annotations: { position: 'front', ...chartData.annotations },
    chart: {
      // id: 'fb',
      // group: 'social',
      type: 'line',
      height: 160,
    },
    xaxis: {
      categories: timestampArray ? timestampArray : [],
      // type: "datetime",
      labels: {
        rotate: -62,
        hideOverlappingLabels: true
      },
    },
    colors: ['#FF0000','#008FFB'],
    yaxis: {
      labels: {
        minWidth: 40,
      },
    },
    ...rest
  };
  console.log(timestampArray)
  const [optionsLine2, setOptionsLine2] = useState({
    // annotations: { position: 'front', ...chartData.annotations },
    chart: {
      // id: 'tw',
      // group: 'social',
      type: 'line',
      height: 160,
    },
    xaxis: {
      categories: timestampArray ? timestampArray : [],
      // type: "datetime",
      labels: {
        rotate: -62,
      },
    },
    colors: ['#008FFB'],
    yaxis: {
      labels: {
        minWidth: 40,
      },
    },
  });
  const [series, setSeries] = useState([]);
  const [rainseries, setRainSeries] = useState([]);
  useEffect(() => {
    let processedChartData;
    
       processedChartData = { current: chartData?.current,rainData: chartData?.rainData }
     

    let series = Object.entries(processedChartData).map((entry) => {
      //  let reProcess = []
      // entry[1].map((item, index) => {
      //   reProcess.push([ new Date(chartData.timeStamp[index]).toLocaleString(),item])
      // })
      return {
        name: entry[0],
        data: entry[1],
      };
    });
    setSeries(series);
    let processedRainChartData;
   
       processedRainChartData = { rainData: chartData?.rainData ? chartData?.rainData : [] }
      
    let rainSeries = Object.entries(processedRainChartData).map((entry) => {
      return {
        name: entry[0],
        data: entry[1],
      };
    });
    setRainSeries(rainSeries);
  }, [chartData]);
  return (
    <div id='wrapper  pd-t-8'>
      <div id='chart-line'>
        <Chart options={options} series={series} type='line' height='400' width='100%' />
      </div>
      {/* <div className='graph-heading-blue'>Rain Data</div> */}
      {/* <div id='chart-line2'>
        <Chart options={optionsLine2} series={rainseries} type='line' type='line' height='400' width='100%' />
      </div> */}
    </div>
  );
};
