import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthService } from './services/AuthService';


export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
   return (
    <Route {...rest} render={props => {
        const currentUser = AuthService.getCurrentUser();
         if (!currentUser) {
             return <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
         }
         if (roles && roles.indexOf(currentUser.userRole) === -1) {
             return <Redirect to={{ pathname: '/dashboard'}} />
        }
        return <Component {...props} />
    }} />
)
}
