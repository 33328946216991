import { HttpClient } from "../utils/httpClient";

const PATH = {
  getData: "/mqttLog",
  deleteAll: "mqttLog",
  blockTopicCreate: "mqttLog/blockTopic",
  blockTopicRead: "mqttLog/blockTopic",
  blockTopicDelete: "mqttLog/blockTopic",
};

const Read = (params, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.getData}`, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

const DeleteAll = (start, callback, error, next) => {
  start();
  return HttpClient.delete(`${PATH.deleteAll}`)
    .then(callback)
    .catch(error)
    .finally(next);
};

const ReadBlockTopic = (limit, offset, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.blockTopicRead}`, { params: { limit, offset } })
    .then(callback)
    .catch(error)
    .finally(next);
};

const CreateBlockTopic = (data, start, callback, error, next) => {
  start();
  return HttpClient.post(`${PATH.blockTopicCreate}`, data)
    .then(callback)
    .catch(error)
    .finally(next);
};

const DeleteBlockTopic = (id, start, callback, error, next) => {
  start();
  return HttpClient.delete(`${PATH.blockTopicDelete}/${id}`)
    .then(callback)
    .catch(error)
    .finally(next);
};

export const mqttLogService = {
  Read,
  DeleteAll,
  ReadBlockTopic,
  CreateBlockTopic,
  DeleteBlockTopic
};
