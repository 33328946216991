import React, { Fragment, useState, useContext, useEffect } from 'react';
import { FormDialog } from '../../components/FormDialog/FormDialog';
import { LoaderContext } from '../../context/LoaderContext';
import { BRIEFS, START_LOADER, STOP_LOADER } from '../../constants';
import { Grid, makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardIcon from '../../components/Card/CardIcon';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import Table from '../../components/Table/Table';
import { CustomToolTip } from '../../components/CustomToolTip/CustomToolTip';
import { Delete as DeleteIcon, Devices as DevicesIcon, Assignment as AssignmentIcon } from '@material-ui/icons';
import { AssignDevice } from './AssignDevice';

import { AssignDeviceService } from '../../services/AssignDeviceService';

const useStyles = makeStyles({
  // ...styles,
  cardTitle: {
    color: 'grey',
    textAlign: 'left',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  button: {
    float: 'right',
  },
  pagination: {
    marginLeft: 'auto',
  },
});

const LIMIT = 10;

export const AssignDevicePage = () => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const { dispatch } = useContext(LoaderContext);
  const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });
  const [device, setDevice] = useState([]);
  const devices = useDevices(startLoader, stopLoader, refresh, pagination.curPage, setPagination, setRefresh);

  const afterInsertSuccess = () => {
    setShowModal(false);
    setRefresh((prev) => prev + 1);
  };
  const onClose = () => {
    setShowModal(false);
  };
  return (
    <Fragment>
      <FormDialog
        show={showModal}
        onClose={onClose}
        title={`Assign Device`}
        content={<AssignDevice startLoader={startLoader} stopLoader={stopLoader} onSuccess={afterInsertSuccess} onClose={onClose} />}
      />
      <Grid container spacing={1} direction='column'>
        <div className='info-container'>
          <div>
            <div className='info-heading'>{BRIEFS?.assignDevices?.heading}</div>
            <ul className='info-list'>
              {Object.values(BRIEFS?.assignDevices?.description).map((desc) => (
                <li>{desc}</li>
              ))}
            </ul>
          </div>
          <div>
            <Grid item>
              <Button onClick={() => setShowModal(true)} className={classes.button} variant='contained' color='primary' mb={2}>
                Assign Device
              </Button>
            </Grid>
          </div>
        </div>
        <Grid item></Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6}>
            <Card>
              <CardHeader color='primary' icon stats>
                <CardIcon color='primary'>
                  <DevicesIcon />
                </CardIcon>
                <h3 className={classes.cardTitle}>Devices</h3>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor='primary'
                  tableHead={['SNo', 'Device', 'Manager']}
                  tableData={devices.map((x) => x.slice(0, 3))}
                  selected={device.length && device[0]}
                  onClick={(v) => setDevice(v)}
                />
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Card>
              <CardHeader color='primary' icon stats>
                <CardIcon color='primary'>
                  <AssignmentIcon />
                </CardIcon>
                <h3 className={classes.cardTitle}>Assistants</h3>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor='primary'
                  tableHead={['Name', 'Options']}
                  tableData={devices.length && device.length ? devices.find((x) => x[0] === device[0])[3] : []}
                />
              </CardBody>
            </Card>
          </Grid>
          {pagination.totalPages > 1 ? (
            <Grid item xs={12} className={classes.pagination}>
              <Pagination
                count={pagination.totalPages}
                page={pagination.curPage}
                variant='outlined'
                color='primary'
                onChange={(e, value) =>
                  setPagination((prev) => ({
                    ...prev,
                    curPage: value,
                  }))
                }
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Fragment>
  );
};

const useDevices = (startLoader, stopLoader, refresh, curPage, setPagination, setRefresh) => {
  const [devices, setDevices] = useState([]);
  useEffect(() => {
    AssignDeviceService.Read(
      LIMIT,
      (curPage - 1) * LIMIT,
      () => startLoader('fetchAssignDevice'),
      handleEventFetchSuccess,
      handleEventFetchError,
      () => stopLoader('fetchAssignDevice')
    );
  }, [curPage, refresh]);
  const handleEventFetchSuccess = (res) => {
    const data = res.data.data;
    const pagination = data.pagination;
    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(pagination.totalCount / LIMIT),
    }));
    const processedData = data.assignedManagers.map((item) => [item.deviceName, item.managerName, getAssistants(item.assistants)]);

    setDevices(processedData);
  };
  const handleEventFetchError = (err) => {
    console.log(err);
  };
  const getAssistants = (assistants) => {
    return assistants.map((item) => [item.assistantName, getOptions(item.id)]);
  };
  const handleRemoveAssistant = (id) => {
    AssignDeviceService.Delete(
      id,
      () => startLoader('deleteAssist'),
      () => setRefresh((prev) => prev + 1),
      () => setRefresh((prev) => prev + 1),
      () => stopLoader('deleteAssist')
    );
  };
  const getOptions = (id) => {
    return (
      <>
        <CustomToolTip title={'Remove Assistant'} onClick={() => handleRemoveAssistant(id)} Icon={DeleteIcon} />
      </>
    );
  };

  return devices.map((x, i) => [(curPage - 1) * LIMIT + i + 1, ...x]);
};
