import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Input } from "../../components/Input/Input";
import Button from "../../components/CustomButtons/Button";
import { Grid, makeStyles } from "@material-ui/core";
import { MapFloorValidation } from "../../validators/MapFloorValidation";
import { FloorMapService } from "../../services/FloorMapService";
import { BuildingService } from "../../services/BuildingService";
import { useState } from "react";
import { Backup } from "@material-ui/icons";
import { DropDown } from "../../components/DropDown/DropDown";
import { AuthService } from "../../services/AuthService";
import { DropzoneDialog } from "material-ui-dropzone";
import { CustomToolTip } from "../../components/CustomToolTip/CustomToolTip";
import { toast } from "react-toastify";
const useStyles = makeStyles({
  buttonContainer: {
    textAlign: "center",
  },
});
export const AddFloorMap = ({
  startLoader,
  stopLoader,
  onSuccess,
  onClose,
}) => {
  const userRole = AuthService.getUserRole();

  const [uploadFileModal, setUploadFileModal] = useState({ status: false });
  const userId = AuthService.getUserId();
  const [initialValues, setIntialValues] = useState({
    buildingId: "",
    floorName: "",
    mapUrl: "",
  });
  const hasAccess = userRole === "admin";
  const classes = useStyles();
  const [buildings, setBuildings] = useState([]);
  const handleSubmit = (values) => {
    if (!values.mapurl)
      values.mapUrl =
        "https://lprs-platform.s3.us-east-2.amazonaws.com/1f40815d-ab9a-4600-bb06-a5b822f1143e%2Bundefined";
    FloorMapService.Create(
      values,
      () => startLoader("floorMapCreate"),
      sensorCreateSuccess,
      sensorCreateError,
      () => stopLoader("floorMapCreate")
    );
  };

  const sensorCreateSuccess = (res) => {
    const data = res.data.data;
    onSuccess(data);
    onClose();
  };
  const sensorCreateError = (err) => {
    console.log(err);
    onClose();
  };

  useEffect(() => {
    BuildingService.Read(
      100,
      0,
      () => startLoader("getbuildings"),
      getBuildingsSuccess,
      (err) => console.log(err),
      () => stopLoader("getbuildings")
    );
  }, []);

  const getBuildingsSuccess = (res) => {
    const data = res.data.data.building;
    setBuildings(data);
  };
  const handleFileUpload = (file, setFieldValue) => {
    let formData = new FormData();
    formData.append("floormap", file[0]);
    FloorMapService.Upload(
      formData,
      () => startLoader("fileUpload"),
      // passing setFieldValue further to the file upload function
      (data) => fileUploadSuccess(data, setFieldValue),
      (err) => console.log(err),
      () => stopLoader("fileUpload")
    );
    setUploadFileModal({ status: false });
  };
  const fileUploadSuccess = (res, setFieldValue) => {
    // setIntialValues((prev) => ({
    //   ...prev,
    //   mapUrl: res.data.data.mapUrl,
    // }));
    setFieldValue("mapUrl", res.data.data.mapUrl);
    //setFieldValue used to set received image url to mapUrl field
    toast.success("Image uploaded!");
  };

  return (
    <>
      <DropzoneDialog
        open={uploadFileModal.status}
        filesLimit={1}
        showFileNames={true}
        previewText="Files:"
        acceptedFiles={[".png", ".jpeg", ".jpg", ".svg"]}
        onClose={() => setUploadFileModal({ status: false })}
        onSave={(file) => handleFileUpload(file, uploadFileModal.setFieldValue)}
      />
      <Formik
        onSubmit={handleSubmit}
        validationSchema={MapFloorValidation}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ setFieldValue, values, ...props }) => (
          <Form>
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12}>
                {/* passing setFieldValue to setUploadFileModal so that the new url can be set to formik values  */}
                {values.mapUrl === "" ? (
                  <>
                    <CustomToolTip
                      title={"Upload Image"}
                      onClick={() =>
                        setUploadFileModal({ status: true, setFieldValue })
                      }
                      Icon={Backup}
                    />
                    Upload Image
                  </>
                ) : (
                  <img
                    style={{ width: "30vw" }}
                    src={values.mapUrl}
                    alt="uploaded room"
                  />
                )}
                <div style={{ fontSize: "13px" }}>
                  <span style={{ color: "red" }}>*</span>A default image is
                  uploaded, if not selected.
                </div>
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={Input}
                  name="floorName"
                  type="text"
                  fullWidth
                  label="Room Name"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={DropDown}
                  name="buildingId"
                  type="select"
                  values={
                    buildings &&
                    buildings.map((x) => ({
                      id: x.buildingId,
                      name: x.buildingName,
                    }))
                  }
                  fullWidth
                  label="Building"
                />
              </Grid>
              <Grid item xs={12} className="hidden-input">
                <Field
                  component={Input}
                  name="mapUrl"
                  type="hidden"
                  fullWidth
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} className={classes.buttonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mb={2}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
