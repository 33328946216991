import React, { useContext, useState, useEffect, Fragment } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import Table from '../../components/Table/Table';
import { LoaderContext } from '../../context/LoaderContext';
import { BRIEFS, START_LOADER, STOP_LOADER } from '../../constants';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { LocationService } from '../../services/LocationService';
import { FormDialog } from '../../components/FormDialog/FormDialog';
import { AddLocation } from './AddLocation';
import { CustomToolTip } from '../../components/CustomToolTip/CustomToolTip';
import { Pagination } from '@material-ui/lab';

const useStyles = makeStyles({
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  button: {
    float: 'right',
  },
  pagination: {
    marginLeft: 'auto',
  },
});

const LIMIT = 10;

export const LocationPage = () => {
  const classes = useStyles();
  const { dispatch } = useContext(LoaderContext);
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });
  const locations = useLocations(startLoader, stopLoader, refresh, pagination.curPage, setPagination);

  const afterLocationCreate = () => {
    setShowModal(false);
    setRefresh((prev) => prev + 1);
  };
  return (
    <Fragment>
      <FormDialog
        show={showModal}
        onClose={() => setShowModal(false)}
        title={`Location`}
        content={<AddLocation startLoader={startLoader} stopLoader={stopLoader} onClose={afterLocationCreate} />}
      />
      <Grid container direction='column' spacing={2}>
        <div className='info-container'>
          <div>
            <div className='info-heading'>{BRIEFS?.manageLocations?.heading}</div>
            <ul className='info-list'>
              {Object.values(BRIEFS?.manageLocations?.description).map((desc) => (
                <li>{desc}</li>
              ))}
            </ul>
          </div>
          <div>
            <Grid item>
              <Button onClick={() => setShowModal(true)} className={classes.button} variant='contained' color='primary' mb={2}>
                Add Location
              </Button>
            </Grid>
          </div>
        </div>
        <Grid item xs={12} sm={12} md={12} className='w-65 margin-auto'>
          <Card>
            <CardHeader color='primary'>
              <h4 className={classes.cardTitleWhite}>Locations</h4>
            </CardHeader>
            <CardBody>
              <Table tableHeaderColor='primary' tableHead={['SNo', 'Name', 'Options']} tableData={locations} />
            </CardBody>
          </Card>
        </Grid>
        {pagination.totalPages > 1 ? (
          <Grid item xs={12} className={classes.pagination}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.curPage}
              variant='outlined'
              color='primary'
              onChange={(e, value) =>
                setPagination((prev) => ({
                  ...prev,
                  curPage: value,
                }))
              }
            />
          </Grid>
        ) : null}
      </Grid>
    </Fragment>
  );
};

const useLocations = (startLoader, stopLoader, refresh, curPage, setPagination) => {
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    LocationService.Read(
      LIMIT,
      (curPage - 1) * LIMIT,
      () => startLoader('locationFetch'),
      handleLocationReadSuccess,
      handleLocationReadError,
      () => stopLoader('locationFetch')
    );
  }, [refresh, curPage]);

  const handleLocationReadSuccess = (res) => {
    const data = res.data.data;
    const pagination = data.pagination;
    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(pagination.totalCount / LIMIT),
    }));
    const finalData = data.locations.map((item, index) => [item.id, index + 1, item.locationName, getOptions(item.id)]);
    setLocations(finalData);
  };

  const handleLocationReadError = (err) => {
    console.log(err);
  };

  const deleteLocation = (id) => {
    LocationService.Delete(
      id,
      () => startLoader('deleteLocation'),
      () => deleteLocationSuccess(id),
      deleteLocationError,
      () => stopLoader('deleteLocation')
    );
  };
  const deleteLocationSuccess = (id) => {
    setLocations((prev) => prev.filter((x) => x[0] !== id));
  };
  const deleteLocationError = (err) => {
    console.log(err);
  };

  const getOptions = (id) => {
    return (
      <>
        <CustomToolTip title={'Delete Location'} onClick={() => deleteLocation(id)} Icon={DeleteIcon} />
      </>
    );
  };
  return locations.map((x, i) => [(curPage - 1) * LIMIT + i + 1, ...x.slice(2)]);
};
