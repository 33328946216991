import { HttpClient } from '../utils/httpClient';

const PATH = {
  read: '/deviceDashboard',
  filter: '/deviceDashboard/getGraphData',
  sensorLog: '/deviceDashboard/getSensorLog',
  activeEvents: '/deviceDashboard/getEventData',
  alertData: '/deviceDashboard/getAlertData',
  alertLog: '/deviceDashboard/getAlertLogData',
  getExportLog: '/deviceDashboard/getExportLog',
  getDeviceInfo: '/deviceDashboard/getDeviceInfo',
  addCommand: '/deviceDashboard/addCommand',
  deleteCommand: '/deviceDashboard/deleteCommand',
  imageLogs: '/deviceDashboard/getImageLogData',
  getSensorDetails: '/deviceDashboard/sensorOptions',
};

const limit = 10;

const ReadById = (id,filterParams, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.read}/${id}`,{params:filterParams}).then(callback).catch(error).finally(next);
};
const getSensorDetailsById = (id, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.getSensorDetails}/${id}`).then(callback).catch(error).finally(next);
};
const FilterGraphData = (id, filterParams, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.filter}/${id}`, { params: filterParams }).then(callback).catch(error).finally(next);
};

const FilterSensorLog = (page, id, filterParams, start, callback, error, next) => {
  let offset = (page - 1) * 10;
  start();
  return HttpClient.get(`${PATH.sensorLog}/${id}`, { params: { ...filterParams, offset, limit } })
    .then(callback)
    .catch(error)
    .finally(next);
};

const ReadActiveEvents = (page, id, callback, error, next) => {
  let offset = (page - 1) * 10;
  return HttpClient.get(`${PATH.activeEvents}/${id}`, { params: { offset, limit } }).then(callback).catch(error).finally(next);
};

const ReadAlertData = (page, id, callback, error, next) => {
  let offset = (page - 1) * 10;
  return HttpClient.get(`${PATH.alertData}/${id}`, { params: { offset, limit } }).then(callback).catch(error).finally(next);
};

const ReadAlertLog = (page, id, callback, error, next) => {
  let offset = (page - 1) * 10;

  return HttpClient.get(`${PATH.alertLog}/${id}`, { params: { offset, limit } }).then(callback).catch(error).finally(next);
};

const getSensorExportLog = (id, filterParams, callback, error, next) => {
  return HttpClient.get(`${PATH.getExportLog}/${id}`, { params: filterParams }).then(callback).catch(error).finally(next);
};

const getDeviceInfo = (id, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.getDeviceInfo}/${id}`).then(callback).catch(error).finally(next);
};

const addCommand = (payload, start, callback, error, next) => {
  start();
  return HttpClient.post(`${PATH.addCommand}`, payload).then(callback).catch(error).finally(next);
};

const deleteCommand = (id, start, callback, error, next) => {
  start();
  return HttpClient.delete(`${PATH.deleteCommand}/${id}`).then(callback).catch(error).finally(next);
};
const ReadImageLog = (page, id, callback, error, next) => {
  let offset = (page - 1) * 10;

  return HttpClient.get(`${PATH.imageLogs}/${id}`, { params: { offset, limit } }).then(callback).catch(error).finally(next);
};
export const DeviceDashboardService = {
  ReadById,
  FilterGraphData,
  FilterSensorLog,
  ReadActiveEvents,
  ReadAlertData,
  ReadAlertLog,
  getSensorExportLog,
  getDeviceInfo,
  addCommand,
  deleteCommand,
  ReadImageLog,
  getSensorDetailsById
};
