import {
  LocationOn as LocationIcon,
  Person as PersonIcon,
  Memory as MemoryIcon,
  Devices as DevicesIcon,
  InfoOutlined as InfoOutlinedIcon,
  Event as EventIcon,
  Assignment as AssignmentIcon,
  Dashboard as DashboardIcon,
  ReportOutlined as ReportOutlined,
} from "@material-ui/icons";
import DataRoutingIcon from "@material-ui/icons/RouterTwoTone";
import HubIcon from "@material-ui/icons/DeviceHub";
import BuildingIcon from "@material-ui/icons/HomeWork";
import LayersIcon from "@material-ui/icons/Layers";
import { LocationPage } from "./view/location/LocationPage";
import { UserPage } from "./view/user/UserPage";
import { LoginPage } from "./view/Login/LoginPage";
import { SensorPage } from "./view/sensor/SensorPage";
import { DevicePage } from "./view/device/DevicePage";
import { AlertHome } from "./view/alert/AlertHome";
import { EventPage } from "./view/event/EventPage";
import { AssignDevicePage } from "./view/AssignDevice/AssignDevicePage";
import { DashboardPage } from "./view/dashboard/DashboardPage";
import DeviceDashboard from "./view/dashboard/DeviceDashboard/DeviceDashboard";
import UserProfile from "./view/user/UserProfile";
import { USER_ROLES } from "./constants";
import AutoReport from "./view/AutoReport/AutoReport";
import { ResetPassword } from "./view/ForgetPassword/ResetPassword";
import { BuildingPage } from "./view/Building/BuildingPage";
import { FloorMapPage } from "./view/FloorMap/FloorMapPage";
import { MqttLog } from "./view/mqttLog/mqttLogPage";
import DataRouting from "./view/DataRouting/DataRouting";
import { UdpRouting } from "./view/udpRouting/UdpRouting";
import UdpLog from "./view/udpLog/udpLog";
import { CompanyPage } from "./view/Company/CompanyPage";
const dashboardRoutes = [
  {
    path: "/home",
    name: "Dashboard",
    roles: [...USER_ROLES],
    icon: DashboardIcon,
    component: DashboardPage,
    layout: "/dashboard",
  },
  {
    path: "/parentDevice",
    name: "Devices",
    roles: [...USER_ROLES],
    icon: DevicesIcon,
    component: DevicePage,
    layout: "/dashboard",
  },
  {
    path: "/device",
    name: "Manage Devices",
    roles: [...USER_ROLES],
    icon: DevicesIcon,
    component: DevicePage,
    layout: "/dashboard",
  },
  {
    path: "/sensor",
    name: "Sensors Types",
    roles: ["admin", "manager"],
    icon: MemoryIcon,
    component: SensorPage,
    layout: "/dashboard",
  },
  {
    path: "/mqttLogs",
    name: "MQTT Logs",
    roles: ["admin", "manager", "assistant"],
    icon: MemoryIcon,
    component: MqttLog,
    layout: "/dashboard",
  },
  // {
  //   path: "/udpLogs",
  //   name: "UDP Logs",
  //   roles: ["admin", "manager", "assistant"],
  //   icon: MemoryIcon,
  //   component: UdpLog,
  //   layout: "/dashboard",
  // },
  {
    path: "/assignDevice",
    name: "Assign Devices",
    roles: ["admin", "manager"],
    icon: AssignmentIcon,
    component: AssignDevicePage,
    layout: "/dashboard",
  },
  {
    path: "/parentLoc",
    name: "Locations",
    roles: ["admin", "manager"],
    icon: LocationIcon,
    component: LocationPage,
    layout: "/dashboard",
  },
  {
    path: "/company",
    name: "Manage Company",
    roles: ["admin", "manager"],
    icon: LocationIcon,
    component: CompanyPage,
    layout: "/dashboard",
  },
  {
    path: "/location",
    name: "Manage Locations",
    roles: ["admin", "manager"],
    icon: LocationIcon,
    component: LocationPage,
    layout: "/dashboard",
  },
  {
    path: "/dwelling",
    name: "Manage Dwelling",
    roles: ["admin", "manager"],
    icon: BuildingIcon,
    component: BuildingPage,
    layout: "/dashboard",
  },
  {
    path: "/Room",
    name: "Manage Room",
    roles: ["admin", "manager"],
    icon: LayersIcon,
    component: FloorMapPage,
    layout: "/dashboard",
  },

  {
    path: "/parentAlert",
    name: "Alerts",
    roles: [...USER_ROLES],
    icon: InfoOutlinedIcon,
    component: AlertHome,
    layout: "/dashboard",
  },

  {
    path: "/alert",
    name: "Manage Alerts",
    roles: [...USER_ROLES],
    icon: InfoOutlinedIcon,
    component: AlertHome,
    layout: "/dashboard",
  },
  {
    path: "/event",
    name: "Manage Events",
    roles: [...USER_ROLES],
    icon: EventIcon,
    component: EventPage,
    layout: "/dashboard",
  },

  {
    path: "/autoReport",
    name: "Report",
    roles: [...USER_ROLES],
    component: AutoReport,
    icon: ReportOutlined,
    layout: "/dashboard",
  },
  {
    path: "/parentUser",
    name: "Users",
    roles: ["admin", "manager"],
    icon: PersonIcon,
    component: UserPage,
    layout: "/dashboard",
  },
  {
    path: "/user",
    name: "Manage Users",
    roles: ["admin", "manager"],
    icon: PersonIcon,
    component: UserPage,
    layout: "/dashboard",
  },
  {
    path: "/profile",
    roles: [...USER_ROLES],
    component: UserProfile,
    layout: "/dashboard",
  },
  {
    path: "/info/:deviceName",
    roles: [...USER_ROLES],
    component: DeviceDashboard,
    layout: "/dashboard",
  },
  {
    path: "/info",
    roles: [...USER_ROLES],
    component: DeviceDashboard,
    layout: "/dashboard",
  },
  {
    path: "/login",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/resetPassword",
    component: ResetPassword,
    layout: "/auth",
  },
  // {
  //   path: "/data-routing",
  //   name: "Data Routing",
  //   roles: ["admin"],
  //   icon: DataRoutingIcon,
  //   component: DataRouting,
  //   layout: "/dashboard",
  // },
  // {
  //   path: "/udp-routing",
  //   name: "UDP Routing",
  //   roles: ["admin"],
  //   icon: HubIcon,
  //   component: UdpRouting,
  //   layout: "/dashboard",
  // },
];

export default dashboardRoutes;
