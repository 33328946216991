import * as yup from "yup";
import { phoneRegex } from "./userValidator";
import { ALERTS } from "../constants";

export const AlertAddValidation = yup.object({
    deviceName: yup.string().required().min(3).max(25),
    sensorId: yup.string().required(),
    valueType: yup.string().required().oneOf(ALERTS.VALUE_TYPES),
    alertType: yup.string().required().oneOf(ALERTS.ALERT_TYPE),
    timePeriod: yup.number().when("valueType", {
        is: ALERTS.ALERT_TYPE[2],
        then: yup
            .number()
            .required()
            .oneOf(ALERTS.TIME_PERIOD.map((x) => x[0])),
        otherwise: yup.number(),
    }),
    a: yup
        .number()
        .typeError("This field should be valid number")
        .required("This is required field")
        .min(0),
    b: yup
        .number()
        .typeError("This field should be valid number")
        .when("alertType", {
            is: (value) => {
                return ALERTS.ALERT_TYPE.slice(0, 2).find((x) => x === value);
            },
            then: yup.number().required("This field is required").min(0),
            otherwise: yup.number().nullable(),
        }),
    contact: yup
        .string()
        .required()
        .matches(phoneRegex, "invalid contact number"),
    email: yup.string().email().required().max(50),
    mqttEnabled: yup.string().required().oneOf(ALERTS.MQTT_ENABLED),
    host: yup.string().when("mqttEnabled", {
        is: 'enabled',
        then: yup.string().required("This field is required"),
        otherwise: yup.string().nullable(),
    }),
    port: yup.number().when("mqttEnabled", {
        is: 'enabled',
        then: yup.number().required("This field is required"),
        otherwise: yup.number().nullable(),
    }),
    topic: yup.string().when("mqttEnabled", {
        is: 'enabled',
        then: yup.string().required("This field is required"),
        otherwise: yup.string().nullable(),
    }),
    message: yup.string().required("This field is required"),
});
