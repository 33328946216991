import * as yup from "yup";
import { EVENTS } from "../constants";

export const EventAddValidation = yup.object({
    deviceName: yup.string().required(),
    sensorId: yup.string().required(),
    valueType: yup.string().required().oneOf(EVENTS.VALUE_TYPES),
    eventType: yup.string().required().oneOf(EVENTS.EVENT_TYPES),
    setPoint: yup
        .number()
        .typeError("This should be a valid number")
        .required()
        .min(0),
});
