import React, { Fragment, useState, useContext, useEffect } from "react";
import { FormDialog } from "../../components/FormDialog/FormDialog";
import { Grid, makeStyles } from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Table from "../../components/Table/Table";
import { Pagination } from "@material-ui/lab";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import { LoaderContext } from "../../context/LoaderContext";
import { START_LOADER, STOP_LOADER, LOCAL_PATH, BRIEFS } from "../../constants";
import { CustomToolTip } from "../../components/CustomToolTip/CustomToolTip";
import { BuildingService } from "../../services/BuildingService";
import { AddBuilding } from "./AddBuilding";
import { UserService } from "../../services/UserService";

import { AuthService } from "../../services/AuthService";
import { useHistory } from "react-router-dom";
import { LocationService } from "../../services/LocationService";

const useStyles = makeStyles({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  button: {
    float: "right",
  },
  pagination: {
    marginLeft: "auto",
  },
});
const LIMIT = 10;
export const BuildingPage = () => {
  const user = AuthService.getCurrentUser();
  const userRole = AuthService.getUserRole();

  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [managers, setManagers] = useState([]);
  const [edit, setEdit] = useState(null);
  const [locations, setLocations] = useState([]);
  const [locationsHashMap, setLocationsHashMap] = useState({});
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const { dispatch } = useContext(LoaderContext);
  const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });
  const editBuilding = (id) => {
    setEdit(id);
    setShowModal(true);
  };
  const devices = useBuildings(
    startLoader,
    stopLoader,
    refresh,
    setRefresh,
    editBuilding,
    pagination.curPage,
    setPagination,
    userRole,
    locationsHashMap
  );
  const tableHead = ["admin"].includes(userRole)
    ? [
        "SNo",
        "Dwelling Name",
        "Manager Name",
        "Location",
        "Created At",
        "Action",
      ]
    : ["manager"].includes(userRole)
    ? ["SNo", "Dwelling Name", "Location", "Created At", "Action"]
    : ["SNo", "Dwelling Name", "Location", "Created At"];

  const onBuildingAddSuccess = () => {
    setRefresh((prev) => prev + 1);
    setShowModal(false);
  };
  useEffect(() => {
    UserService.GetManagers(
      () => startLoader("getManagers"),
      managerFetchSuccess,
      managerFetchError,
      () => stopLoader("getManagers")
    );
    LocationService.ReadAll(
      () => startLoader("getLocations"),
      locationFetchSuccess,
      locationFetchError,
      () => stopLoader("getLocations")
    );
  }, []);

  const locationFetchSuccess = (res) => {
    const data = res.data.data;
    setLocations(data.locations);
    const map = {};
    data.locations.map((item) => (map[item.id] = item));
    setLocationsHashMap(map);
  };

  const locationFetchError = (err) => {
    console.log(err);
  };

  const managerFetchSuccess = (res) => {
    const data = res.data.data;
    setManagers(data.managers);
  };
  const managerFetchError = (err) => {
    console.log(err);
  };
  const onClose = () => {
    setEdit(null);
    setShowModal(false);
  };
  return (
    <Fragment>
      <FormDialog
        onClose={onClose}
        show={showModal}
        title={`Add/Edit Dwelling`}
        content={
          <AddBuilding
            edit={edit}
            managers={managers}
            startLoader={startLoader}
            stopLoader={stopLoader}
            onSuccess={onBuildingAddSuccess}
            onClose={onClose}
            userRole={userRole}
            user={user}
            locations={locations}
          />
        }
      />
      <Grid container direction="column" spacing={2}>
        <div className="info-container">
          <div>
            <div className="info-heading">
              {BRIEFS?.manageBuildings?.heading}
            </div>
            <ul className="info-list">
              {Object.values(BRIEFS?.manageBuildings?.description).map(
                (desc) => (
                  <li>{desc}</li>
                )
              )}
            </ul>
          </div>
          <div>
            {["admin", "manager"].includes(userRole) && (
              <Grid item>
                <Button
                  onClick={() => setShowModal(true)}
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  mb={2}
                >
                  Add Dwelling
                </Button>
              </Grid>
            )}
          </div>
        </div>
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Buildings</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={tableHead}
                tableData={devices}
              />
            </CardBody>
          </Card>
        </Grid>
        {pagination.totalPages > 1 ? (
          <Grid item xs={12} className={classes.pagination}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.curPage}
              variant="outlined"
              color="primary"
              onChange={(e, value) =>
                setPagination((prev) => ({
                  ...prev,
                  curPage: value,
                }))
              }
            />
          </Grid>
        ) : null}
      </Grid>
    </Fragment>
  );
};

const useBuildings = (
  startLoader,
  stopLoader,
  refresh,
  setRefresh,
  editBuilding,
  curPage,
  setPagination,
  userRole,
  locationsHashMap
) => {
  const [devices, setDevices] = useState([]);
  const history = useHistory();
  useEffect(() => {
    BuildingService.Read(
      LIMIT,
      (curPage - 1) * LIMIT,
      () => startLoader("buildingFetch"),
      handleBuildingFetchSuccess,
      handleBuildingFetchError,
      () => stopLoader("buildingFetch")
    );
  }, [curPage, refresh, locationsHashMap]);
  const handleBuildingFetchSuccess = (res) => {
    const data = res.data.data;
    const pagination = data.pagination;
    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(pagination.totalCount / LIMIT),
    }));
    const processedData = ["admin"].includes(userRole)
      ? data.building.map((item) => [
          <p className="device-link">{item.buildingName}</p>,

          item.managerName,
          locationsHashMap[item.locationId]?.locationName,
          new Date(item.createdAt).toLocaleString(),
          getOptions(item.buildingId),
        ])
      : ["manager"].includes(userRole)
      ? data.building.map((item) => [
          <p className="device-link">{item.buildingName}</p>,
          locationsHashMap[item.locationId]?.locationName,
          new Date(item.createdAt).toLocaleString(),
          getOptions(item.buildingId),
        ])
      : data.building.map((item) => [
          <p className="device-link">{item.buildingName}</p>,
          locationsHashMap[item.locationId]?.locationName,
          new Date(item.createdAt).toLocaleString(),
        ]);
    setDevices(processedData);
  };
  const handleBuildingFetchError = (err) => {
    console.log(err);
  };
  const handleDeviceDelete = (id) => {
    BuildingService.Delete(
      id,
      () => startLoader("deleteBuilding"),
      () => setRefresh((prev) => prev + 1),
      () => setRefresh((prev) => prev + 1),
      () => stopLoader("deleteBuilding")
    );
  };
  const getOptions = (id) => {
    return (
      <>
        <CustomToolTip
          title={"Edit Dwelling"}
          onClick={() => editBuilding(id)}
          Icon={EditIcon}
        />
        <CustomToolTip
          title={"Delete Dwelling"}
          onClick={() => handleDeviceDelete(id)}
          Icon={DeleteIcon}
        />
      </>
    );
  };
  return devices.map((x, i) => [(curPage - 1) * LIMIT + i + 1, ...x]);
};
