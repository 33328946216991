import React from 'react';
import Chart from 'react-apexcharts';

const colors = ['#FF5733', '#FFC300', '#7AFF33'];

export const RadialChart = ({ batteryLevel }) => {
  const color = batteryLevel <= 30 ? colors[0] : batteryLevel <= 60 ? colors[1] : colors[2];
  var options1 = {
    optionsRadial: {
      plotOptions: {
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          hollow: {
            margin: 0,
            size: '60%',
            background: '#fff',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: '#fff',
            strokeWidth: '100%',
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            showOn: 'always',
            name: {
              offsetY: -20,
              show: true,
              color: '#888',
              fontSize: '13px',
            },
            value: {
              formatter: function (val) {
                return val;
              },
              color: '#111',
              fontSize: '30px',
              show: true,
            },
          },
        },
      },
      fill: {
        colors: [color],
      },
      stroke: {
        lineCap: 'round',
      },
      labels: [''],
    },
    seriesRadial: [batteryLevel],
  };

  return (
    <div>
      <Chart options={options1.optionsRadial} series={options1.seriesRadial} type='radialBar' width='200' height='242' />
    </div>
  );
};
