import React, { useState, useEffect, Fragment } from "react";
import { Formik, Form, Field } from "formik";
import { Input } from "../../components/Input/Input";
import { SwitchComponent } from "../../components/SwitchComponent/SwitchComponent";
import Button from "../../components/CustomButtons/Button";
import { Grid, makeStyles } from "@material-ui/core";
import { DropDown } from "../../components/DropDown/DropDown";
import { FormDialog } from "../../components/FormDialog/FormDialog";
import {
  DeviceAddValidation,
  DeviceAddValidationWithoutManager,
} from "../../validators/DeviceValidator";
import { DeviceService } from "../../services/DeviceService";
import { emptyToNull } from "../../utils/ObjectResolver";
import MapComponent from "../../components/Map/MapComponent";
import { SensorService } from "../../services/SensorService";
import { BuildingService } from "../../services/BuildingService";
import { CanvasComponent } from "../../components/CanvasComponent/CanvasComponent";
import { FloorMapService } from "../../services/FloorMapService";
const useStyles = makeStyles({
  buttonContainer: {
    textAlign: "center",
  },
});
export const AddDevice = ({
  managers,
  startLoader,
  stopLoader,
  onSuccess,
  onClose,
  edit = null,
  userRole,
  user,
}) => {
  const hasAccess = userRole === "admin" || userRole === "manager";
  const classes = useStyles();
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [error, setError] = useState(null);
  const [sensors, setSensors] = useState(null);
  const [CoordinateOption, setCoordinateOption] = useState("");
  const isManagerAndNotManagerAdmin = Boolean(
    userRole === "manager" && !user.managerAdmin
  );
  const [devManager, setDevManager] = useState("");
  const [floorManager, setfloorManager] = useState("");
  const [buildingManager, setBuildingManager] = useState("");
  const [FloorModal, setFloorModal] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [building, setBuilding] = useState([]);
  const [floor, setFloor] = useState([]);
  const [imageUrl, setimageUrl] = useState(null);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      if (location.lat === 0 && location.lng === 0) {
        setLocation({ lat: coords.latitude, lng: coords.longitude });
      }
    });
  }, []);

  useEffect(() => {
    if (userRole === "manager") getSensors(user.id);
  }, []);

  useEffect(() => {
    console.log({ CoordinateOption });
  }, [CoordinateOption]);

  useEffect(() => {
    BuildingService.GetAll(
      startLoader,
      handleBuildingSucess,
      handleBuildingerror,
      stopLoader
    );
  }, []);

  const handleBuildingSucess = (res) => {
    const buildings = res.data.data.building;
    setBuilding(buildings);
  };
  const handleBuildingerror = (err) => {
    console.log(err);
  };
  const getSensors = (id) => {
    if (id) {
      SensorService.ReadAllByManager(
        id,
        () => startLoader("getSensors"),
        getSensorsSuccess,
        (err) => console.log(err),
        () => stopLoader("getSensors")
      );
    }
  };

  const getSensorsSuccess = (res) => {
    const data = res.data.data;
    setSensors(data.sensors);
  };

  const initialValues = useInitialValues(
    startLoader,
    stopLoader,
    edit,
    setLocation,
    userRole,
    user,
    getSensors,
    setDevManager,
    setCoordinateOption,
    setMarkers,
    setBuildingManager,
    setfloorManager
  );

  const [showModal, setShowModal] = useState(false);
  const handleSubmit = (values) => {
    setError(null);
    const payload = emptyToNull({ ...values, ...location });
    delete payload.Gps;
    if (
      payload.template !== "Ultrasonic" &&
      payload.deviceMetaData &&
      payload.deviceMetaData.maximumDepth != undefined
    ) {
      payload.deviceMetaData.maximumDepth = undefined;
      payload.deviceMetaData.applicationType = undefined;
    } else if (payload.template === "Ultrasonic") {
      payload.deviceMetaData.maximumDepth = parseInt(
        payload.deviceMetaData.maximumDepth
      );
    }
    if (CoordinateOption === "Map") {
      payload.lng = null;
      payload.lat = null;
      payload.locationMode = CoordinateOption;
      payload.floorData = {
        ...values.floorData,
        xValue: markers[0].left,
        yValue: markers[0].top,
      };
    } else if (CoordinateOption === "Gps") {
      delete payload.floorData;

      payload.locationMode = CoordinateOption;
    }
    delete payload.edit;
    payload.managerId = devManager;
    payload.weather = payload.weather === true ? "enabled" : "disabled";
    payload.sensorAdjustment = {
      ...payload.sensorAdjustment,
      sensorId1Meta: reverseFormatSensorMetaData(
        payload.sensorAdjustment?.sensorId1Meta
      ),
      sensorId2Meta: reverseFormatSensorMetaData(
        payload.sensorAdjustment?.sensorId2Meta
      ),
      sensorId3Meta: reverseFormatSensorMetaData(
        payload.sensorAdjustment?.sensorId3Meta
      ),
      sensorId4Meta: reverseFormatSensorMetaData(
        payload.sensorAdjustment?.sensorId4Meta
      ),
    };

    if (edit) {
      delete payload.deviceName;
      if (
        initialValues.managerId !== payload.managerId ||
        initialValues.subManagerId !== payload.subManagerId
      ) {
        payload.managerChanged = true;
      }
      DeviceService.Update(
        edit,
        payload,
        () => startLoader("deviceUpdate"),
        userCreateSuccess,
        userCreateError,
        () => stopLoader("deviceUpdate")
      );
    } else {
      DeviceService.Create(
        payload,
        () => startLoader("deviceCreate"),
        userCreateSuccess,
        userCreateError,
        () => stopLoader("deviceCreate")
      );
    }
  };
  const userCreateSuccess = (res) => {
    const data = res.data.data;
    if (res.data.error) {
      setError(res.data.message);
      return;
    }
    onSuccess(data);
    onClose();
  };
  const userCreateError = (err) => {
    setError(null);
    console.error(err);
    onClose();
  };
  const handleManagerChange = (e) => {
    let manager = e.target.value;
    setDevManager(manager);
    getSensors(manager);
  };
  useEffect(() => {
    if (floorManager) {
      fetchOneFloor();
    }
  }, [floorManager]);
  const fetchOneFloor = () => {
    FloorMapService.ReadById(
      floorManager,
      startLoader,
      handlefloorDataSuccess,
      handleFloorError,
      stopLoader
    );
  };
  const handleFloorChange = (e) => {
    setfloorManager(e.target.value);
  };
  const handlefloorDataSuccess = (res) => {
    const floor = res.data.data.floor;
    setimageUrl(floor.imageUrl);
  };
  useEffect(() => {
    if (buildingManager) {
      fetchFloor();
      fetchBuildingDetails();
    }
  }, [buildingManager]);
  const fetchFloor = () => {
    FloorMapService.getFloorByBuilding(
      buildingManager,
      startLoader,
      handlefloorSuccess,
      handleFloorError,
      stopLoader
    );
  };
  const fetchBuildingDetails = () => {
    BuildingService.ReadById(
      buildingManager,
      () => startLoader("getBuilding"),
      buildingFetchSuccess,
      handleError,
      () => stopLoader("getBuilding")
    );
  };
  const buildingFetchSuccess = (res) => {
    const data = res.data.data.buildingData;
    setDevManager(data.managerId);
    getSensors(data.managerId);
  };
  const handleError = (err) => console.log(err);
  const handleBuildingChange = (e) => {
    const building = e.target.value;
    setBuildingManager(building);
  };
  const handlefloorSuccess = (res) => {
    const floor = res.data.data.floor;
    setFloor(floor);
  };
  const handleFloorError = (err) => {
    console.log(err);
  };

  return (
    <Fragment>
      <FormDialog
        onClose={() => setShowModal(false)}
        show={showModal}
        title={`Select Location`}
        content={
          <MapComponent
            defaultLocation={location}
            setLocation={setLocation}
            setShowModal={setShowModal}
          />
        }
      />
      <FormDialog
        onClose={() => setFloorModal(false)}
        show={FloorModal}
        title={`Select Floor cordinate`}
        content={
          <CanvasComponent
            markers={markers}
            setMarkers={setMarkers}
            image={imageUrl}
          />
        }
      />
      <Formik
        onSubmit={handleSubmit}
        validationSchema={
          CoordinateOption === "Gps"
            ? DeviceAddValidation
            : DeviceAddValidationWithoutManager
        }
        initialValues={initialValues}
        enableReinitialize
      >
        {({ handleChange, handleSubmit, isSubmitting, values }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <Field
                  component={Input}
                  name="deviceName"
                  type="text"
                  fullWidth
                  label="Name"
                  disabled={edit}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Field
                  component={DropDown}
                  name="Gps"
                  type="select"
                  values={[
                    { id: "Gps", name: "Gps" },
                    { id: "Map", name: "Room Map" },
                  ]}
                  value={CoordinateOption}
                  onChange={(e) => {
                    setCoordinateOption(e.target.value);
                  }}
                  fullWidth
                  label="Location Mode"
                  // disabled={edit}
                />
              </Grid>
              {CoordinateOption === "Gps" ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Field
                    onClick={() => setShowModal(true)}
                    component={Input}
                    value={Object.values(location).join(",")}
                    name="locate"
                    type="text"
                    fullWidth
                    label="Locate"
                  />
                </Grid>
              ) : CoordinateOption === "Map" ? (
                <>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Field
                      component={DropDown}
                      name="floorData.buildingId"
                      type="select"
                      values={
                        building &&
                        building.map((x) => ({
                          id: x.buildingId,
                          name: x.buildingName,
                        }))
                      }
                      value={buildingManager}
                      onChange={(selectedOption) => {
                        handleBuildingChange(selectedOption);
                        handleChange("floorData.buildingId")(selectedOption);
                      }}
                      fullWidth
                      disabled={!hasAccess}
                      label="Dwelling"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Field
                      component={DropDown}
                      name="floorData.floorMapId"
                      type="select"
                      values={
                        floor &&
                        floor.map((x) => ({
                          id: x.floorId,
                          name: x.floorName,
                        }))
                      }
                      value={floorManager}
                      onChange={(selectedOption) => {
                        handleFloorChange(selectedOption);
                        handleChange("floorData.floorMapId")(selectedOption);
                      }}
                      fullWidth
                      disabled={!hasAccess}
                      label="Room"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Field
                      onClick={() => {
                        setFloorModal(true);
                      }}
                      component={Input}
                      value={
                        markers.length > 0 &&
                        Object.values(markers[0]).join(",")
                      }
                      name="floor"
                      type="text"
                      fullWidth
                      label="Room Map Cordinate"
                    />
                  </Grid>
                </>
              ) : null}

              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <Field
                  component={Input}
                  name="deviceAlias"
                  type="text"
                  fullWidth
                  label="Alias"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <Field
                  component={DropDown}
                  name="template"
                  type="select"
                  values={[
                    { id: "Multiple", name: "Multiple" },
                    { id: "Single", name: "Single" },
                    { id: "Ultrasonic", name: "Ultrasonic" },
                  ]}
                  fullWidth
                  label="Template"
                  disabled={edit}
                />
              </Grid>
              {!isManagerAndNotManagerAdmin && (
                <>
                  {userRole === "admin" && (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Field
                        component={DropDown}
                        name="managerId"
                        type="select"
                        values={
                          managers &&
                          managers.map((x) => ({
                            id: x.id,
                            name: x.name,
                          }))
                        }
                        value={devManager}
                        onChange={(selectedOption) => {
                          handleManagerChange(selectedOption);
                          handleChange("managerId")(selectedOption);
                          handleChange("sensorId1")("");
                          handleChange("sensorId2")("");
                          handleChange("sensorId3")("");
                          handleChange("sensorId4")("");
                        }}
                        fullWidth
                        disabled={!hasAccess}
                        label="Manager"
                      />
                    </Grid>
                  )}

                  {hasAccess && (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Field
                        component={DropDown}
                        name="subManagerId"
                        type="select"
                        values={
                          managers &&
                          managers.map((x) => ({
                            id: x.id,
                            name: x.name,
                          }))
                        }
                        fullWidth
                        disabled={!hasAccess}
                        label="Sub Manager"
                      />
                    </Grid>
                  )}
                </>
              )}

              {values.template === "Ultrasonic" ? (
                <>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Field
                      component={Input}
                      name="deviceMetaData.maximumDepth"
                      type="text"
                      fullWidth
                      label="Maximum Depth"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Field
                      component={DropDown}
                      name="deviceMetaData.valueType"
                      type="select"
                      values={[
                        { id: "First", name: "First" },
                        { id: "Average", name: "Average" },
                      ]}
                      fullWidth
                      label="Value Type"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Field
                      component={DropDown}
                      name="deviceMetaData.applicationType"
                      type="select"
                      values={[
                        { id: "Fill", name: "Fill" },
                        { id: "Overflow", name: "Overflow" },
                      ]}
                      fullWidth
                      label="Application Type"
                    />
                  </Grid>
                </>
              ) : null}
              {userRole === "admin" && (
                <>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Field
                      component={Input}
                      name="smsLimit"
                      type="text"
                      fullWidth
                      label="SMS Limit"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Field
                      component={Input}
                      name="emailLimit"
                      type="text"
                      fullWidth
                      label="Email Limit"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Field
                      component={DropDown}
                      name="limitRefresh"
                      type="select"
                      values={[
                        { id: "Daily", name: "Daily" },
                        { id: "Weekly", name: "Weekly" },
                        { id: "Monthly", name: "Monthly" },
                      ]}
                      fullWidth
                      label="Limit Refresh"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Field
                      component={SwitchComponent}
                      name="weather"
                      type="checkbox"
                      fullWidth
                      label="Weather"
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <Field
                  component={SwitchComponent}
                  name="sensorAdjustment.enabled"
                  type="checkbox"
                  fullWidth
                  label="Sensor Adjustment"
                />
              </Grid>

              {/* ============================================== */}
              {values.sensorAdjustment.enabled && (
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={2}></Grid>
                  <Grid
                    item
                    xs={12}
                    md={10}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {Array(4)
                      .fill(0)
                      .map((_, index) => (
                        <Grid
                          container
                          xs={3}
                          spacing={1}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {index + 1}
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              )}

              <Grid item container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <Field
                    component={DropDown}
                    name="sensorId1"
                    type="select"
                    values={
                      sensors &&
                      sensors.map((x) => ({
                        id: x.id,
                        name: x.sensorName,
                      }))
                    }
                    fullWidth
                    label="Sensor 1"
                  />
                </Grid>
                {values.sensorAdjustment.enabled &&
                  getDynamicForm("sensorId1Meta")}
              </Grid>

              {values.template !== "Ultrasonic" ? (
                <>
                  <Grid item container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <Field
                        component={DropDown}
                        name="sensorId2"
                        type="select"
                        values={
                          sensors &&
                          sensors.map((x) => ({
                            id: x.id,
                            name: x.sensorName,
                          }))
                        }
                        fullWidth
                        label="Sensor 2"
                      />
                    </Grid>
                    {values.sensorAdjustment.enabled &&
                      getDynamicForm("sensorId2Meta")}
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <Field
                        component={DropDown}
                        name="sensorId3"
                        type="select"
                        values={
                          sensors &&
                          sensors.map((x) => ({
                            id: x.id,
                            name: x.sensorName,
                          }))
                        }
                        fullWidth
                        label="Sensor 3"
                      />
                    </Grid>
                    {values.sensorAdjustment.enabled &&
                      getDynamicForm("sensorId3Meta")}
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <Field
                        component={DropDown}
                        name="sensorId4"
                        type="select"
                        values={
                          sensors &&
                          sensors.map((x) => ({
                            id: x.id,
                            name: x.sensorName,
                          }))
                        }
                        fullWidth
                        label="Sensor 4"
                      />
                    </Grid>
                    {values.sensorAdjustment.enabled &&
                      getDynamicForm("sensorId4Meta")}
                  </Grid>
                </>
              ) : null}

              {/* ============================================== */}

              <Grid item xs={12} sm={6} md={4}>
                <Field
                  component={SwitchComponent}
                  name="sensorMapping.enabled"
                  type="checkbox"
                  fullWidth
                  label="Sensor Mapping"
                />
              </Grid>

              {values.sensorMapping.enabled && (
                <>
                  <Grid item xs={12} sm={6} md={2}>
                    <Field
                      component={Input}
                      name="sensorMapping.TC1"
                      type="text"
                      fullWidth
                      label="TC1"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Field
                      component={Input}
                      name="sensorMapping.TC2"
                      type="text"
                      fullWidth
                      label="TC2"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Field
                      component={Input}
                      name="sensorMapping.TC3"
                      type="text"
                      fullWidth
                      label="TC3"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Field
                      component={Input}
                      name="sensorMapping.TC4"
                      type="text"
                      fullWidth
                      label="TC4"
                    />
                  </Grid>
                </>
              )}

              {error ? (
                <Grid item lg={12}>
                  <p className="error-msg" style={{ marginLeft: "13px" }}>
                    {error}
                  </p>
                </Grid>
              ) : null}
              <Grid item xs={12} className={classes.buttonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mb={2}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

const getDynamicForm = (sensorType) => {
  return (
    <Grid
      item
      xs={12}
      md={10}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      {Array(4)
        .fill(0)
        .map((_, index) => (
          <Grid container xs={3} spacing={2}>
            <Grid item xs={4}>
              <Field
                component={Input}
                name={`sensorAdjustment.${sensorType}.${index + 1}a.from`}
                type="text"
                fullWidth
                label="From"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component={Input}
                name={`sensorAdjustment.${sensorType}.${index + 1}a.to`}
                type="text"
                fullWidth
                label="To"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component={Input}
                name={`sensorAdjustment.${sensorType}.${index + 1}a.value`}
                type="text"
                fullWidth
                label="+/-"
              />
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

const useInitialValues = (
  startLoader,
  stopLoader,
  edit,
  setLocation,
  userRole,
  user,
  getSensors,
  setDevManager,
  setCoordinateOption,
  setMarkers,
  setBuildingManager,
  setfloorManager
) => {
  const [initialValues, setInitialValues] = useState({
    edit: false,
    deviceName: "",
    deviceAlias: "",
    managerId: userRole === "manager" ? user.id : "",
    subManagerId: "",
    sensorId1: "",
    sensorId2: "",
    sensorId3: "",
    sensorId4: "",
    template: "",
    limitRefresh: "Monthly",
    smsLimit: 10,
    emailLimit: 10,
    weather: false,
    deviceMetaData: {
      maximumDepth: 600,
      applicationType: "Fill",
    },
    sensorAdjustment: {
      enabled: false,
      sensorId1Meta: {
        "1a": { from: "", to: "", value: "" },
        "2a": { from: "", to: "", value: "" },
        "3a": { from: "", to: "", value: "" },
        "4a": { from: "", to: "", value: "" },
      },
      sensorId2Meta: {
        "1a": { from: "", to: "", value: "" },
        "2a": { from: "", to: "", value: "" },
        "3a": { from: "", to: "", value: "" },
        "4a": { from: "", to: "", value: "" },
      },
      sensorId3Meta: {
        "1a": { from: "", to: "", value: "" },
        "2a": { from: "", to: "", value: "" },
        "3a": { from: "", to: "", value: "" },
        "4a": { from: "", to: "", value: "" },
      },
      sensorId4Meta: {
        "1a": { from: "", to: "", value: "" },
        "2a": { from: "", to: "", value: "" },
        "3a": { from: "", to: "", value: "" },
        "4a": { from: "", to: "", value: "" },
      },
    },
    sensorMapping: {
      enabled: false,
      TC1: "",
      TC2: "",
      TC3: "",
      TC4: "",
    },
  });
  useEffect(() => {
    if (edit) {
      DeviceService.ReadById(
        edit,
        () => startLoader("getDevice"),
        userFetchSuccess,
        userFetchError,
        () => stopLoader("getDevice")
      );
    } else if (userRole === "manager") {
      setInitialValues({
        ...initialValues,
        managerId: user.id,
      });
      setDevManager(user.id);
    }
  }, [edit]);
  const userFetchSuccess = (res) => {
    const data = res.data.data.deviceData;

    data.edit = true;
    getSensors(data.managerId);
    const sensorAdjustment = {
      ...data.sensorAdjustment,
      sensorId1Meta: formatSensorMetaData(data.sensorAdjustment?.sensorId1Meta),
      sensorId2Meta: formatSensorMetaData(data.sensorAdjustment?.sensorId2Meta),
      sensorId3Meta: formatSensorMetaData(data.sensorAdjustment?.sensorId3Meta),
      sensorId4Meta: formatSensorMetaData(data.sensorAdjustment?.sensorId4Meta),
    };
    if (data.locationMode === "Map") {
      setInitialValues({
        ...data,
        weather: data.weather === "enabled" ? true : false,
        sensorAdjustment,
      });
      setMarkers([{ top: data.floorData.yValue, left: data.floorData.xValue }]);
      setBuildingManager(data.floorData.buildingId);
      setfloorManager(data.floorData.floorMapId);
    } else {
      setInitialValues({
        ...data,
        weather: data.weather === "enabled" ? true : false,
        sensorAdjustment,
      });
      setLocation({ lat: data.lat, lng: data.lng });
    }

    setCoordinateOption(String(data.locationMode));
    setDevManager(data.managerId);
  };
  const userFetchError = (err) => {
    console.log(err);
  };
  return initialValues;
};

const formatSensorMetaData = (data = {}) => {
  const newObject = {};
  Object.keys(data).forEach((key) => (newObject[`${key}a`] = data[key]));
  return newObject;
};

const reverseFormatSensorMetaData = (data = {}) => {
  const newObject = {};
  Object.keys(data).forEach(
    (key) => (newObject[extractNumberFromString(key)] = data[key])
  );
  return newObject;
};

const extractNumberFromString = (str) => {
  const match = str.match(/^\d+/);
  if (match) {
    return parseInt(match[0], 10);
  } else {
    return null;
  }
};
