import React, { Fragment, useState, useContext, useEffect } from 'react';
import { FormDialog } from '../../components/FormDialog/FormDialog';
import { LoaderContext } from '../../context/LoaderContext';
import { START_LOADER, STOP_LOADER, LOCAL_PATH, BRIEFS } from '../../constants';
import { Grid, makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import Table from '../../components/Table/Table';
import { CustomToolTip } from '../../components/CustomToolTip/CustomToolTip';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { EventService } from '../../services/EventService';
import { AddEvent } from './AddEvent';
import { AuthService } from '../../services/AuthService';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  button: {
    float: 'right',
  },
  pagination: {
    marginLeft: 'auto',
  },
});

const LIMIT = 10;

export const EventPage = () => {
  const userRole = AuthService.getUserRole();

  const history = useHistory();
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const { dispatch } = useContext(LoaderContext);
  const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });
  const events = useEvents(startLoader, stopLoader, refresh, pagination.curPage, setPagination, setRefresh, history);

  const afterInsertSuccess = () => {
    setShowModal(false);
    setRefresh((prev) => prev + 1);
  };
  const onClose = () => {
    setShowModal(false);
  };
  return (
    <Fragment>
      <FormDialog
        show={showModal}
        onClose={onClose}
        title={`Event`}
        content={<AddEvent startLoader={startLoader} stopLoader={stopLoader} onSuccess={afterInsertSuccess} onClose={onClose} />}
      />
      <Grid container direction='column' spacing={2}>
        <div className='info-container'>
          <div>
            <div className='info-heading'>{BRIEFS?.manageEvents?.heading}</div>
            <ul className='info-list'>
              {Object.values(BRIEFS?.manageEvents?.description).map((desc) => (
                <li>{desc}</li>
              ))}
            </ul>
          </div>
          <div>
            <Grid item>
              <Button onClick={() => setShowModal(true)} className={classes.button} variant='contained' color='primary' mb={2}>
                Add Event
              </Button>
            </Grid>
          </div>
        </div>
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color='primary'>
              <h4 className={classes.cardTitleWhite}>Events</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor='primary'
                tableHead={['SNo', 'Device', 'Sensor', 'Value Type', 'Set Point', 'Event Type', 'Created At', 'Options']}
                tableData={events}
              />
            </CardBody>
          </Card>
        </Grid>
        {pagination.totalPages > 1 ? (
          <Grid item xs={12} className={classes.pagination}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.curPage}
              variant='outlined'
              color='primary'
              onChange={(e, value) =>
                setPagination((prev) => ({
                  ...prev,
                  curPage: value,
                }))
              }
            />
          </Grid>
        ) : null}
      </Grid>
    </Fragment>
  );
};

const useEvents = (startLoader, stopLoader, refresh, curPage, setPagination, setRefresh, history) => {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    EventService.Read(
      LIMIT,
      (curPage - 1) * LIMIT,
      () => startLoader('fetchEvents'),
      handleEventFetchSuccess,
      handleEventFetchError,
      () => stopLoader('fetchEvents')
    );
  }, [curPage, refresh]);
  const handleEventFetchSuccess = (res) => {
    const data = res.data.data;
    const pagination = data.pagination;
    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(pagination.totalCount / LIMIT),
    }));
    const processedData = data.events.map((item) => [
      item.id,
      <p className='device-link' onClick={() => history.push(LOCAL_PATH.DEVICE, { id: item.deviceName })}>
        {item.deviceName}
      </p>,
      item.sensorName,
      item.valueType,
      item.setPoint,
      item.eventType,
      new Date(item.createdAt).toLocaleString(),
      getOptions(item.id),
    ]);
    setEvents(processedData);
  };
  const handleEventFetchError = (err) => {
    console.log(err);
  };

  const handleEventDelete = (id) => {
    EventService.Delete(
      id,
      () => startLoader('eventDelete'),
      () => setRefresh((prev) => prev + 1),
      () => setRefresh((prev) => prev + 1),
      () => stopLoader('eventDelete')
    );
  };

  const getOptions = (id) => {
    return (
      <>
        <CustomToolTip title={'Delete Event'} onClick={() => handleEventDelete(id)} Icon={DeleteIcon} />
      </>
    );
  };
  return events.map((x, i) => [(curPage - 1) * LIMIT + i + 1, ...x.slice(1)]);
};
