import React, { useState, useEffect } from 'react';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import CardBody from '../../../components/Card/CardBody';
import Table from '../../../components/Table/Table';
import Icon from '@material-ui/core/Icon';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import { RadialChart } from '../../../components/Chart/RadialChart';
import Bat1 from '../../../assets/bat1.png';
import Bat2 from '../../../assets/bat2.png';
import Bat3 from '../../../assets/bat3.png';
import Bat4 from '../../../assets/bat4.png';
import Bat5 from '../../../assets/bat5.png';
import classes from "../dashboard.module.css"
export const DeviceDashboardCards = ({ sensors, sensorValues, windowDimensions, template }) => {
  const cards = sensors.map((sensor, index) => {
    let tableData = [];
    if (sensorValues && sensorValues.data && template === 'Multiple') {
      if (sensorValues.data['TC' + (index + 1)] || sensorValues.data['TC' + (index + 1)] === 0) {
        tableData.push(['Current', sensorValues.data['TC' + (index + 1)]]);
      } else {
        if (sensorValues.data['TN' + (index + 1)] || sensorValues.data['TN' + (index + 1)] === 0)
          tableData.push(['Minimum', sensorValues.data['TN' + (index + 1)]]);
        if (sensorValues.data['TM' + (index + 1)] || sensorValues.data['TM' + (index + 1)] === 0)
          tableData.push(['Maximum', sensorValues.data['TM' + (index + 1)]]);
        if (sensorValues.data['TA' + (index + 1)] || sensorValues.data['TA' + (index + 1)] === 0)
          tableData.push(['Average', sensorValues.data['TA' + (index + 1)]]);
      }
    } else if (sensorValues && (template === 'Single' || template === 'Ultrasonic')) {
      const { lastThree } = sensorValues;
     
      lastThree.forEach((ele, i) => {
        if (sensor !== '-' && lastThree[i] && lastThree[i].timestamp && lastThree[i].data['TC' + (index + 1)] !== null){
         
          tableData.push([new Date(lastThree[i].timestamp).toLocaleString(), lastThree[i].data && lastThree[i].data['TC' + (index + 1)]]);
        }
          
      });
    }

    return (
      <GridItem key={index} xs={12} sm={12} md={windowDimensions.width <= 1140 ? 12 : 3}>
        <Card className={' dev-card-body' + index}>
          <CardHeader color='primary' stats icon>
            <CardIcon color='primary' className={' dev-card' + index}>
              <Icon style={{ fontSize: '16px' }}>{index + 1}</Icon>
            </CardIcon>
          </CardHeader>
          <h4 className='device-card-header'>{sensor}</h4>
          <CardBody className='p-t-0'>{sensorValues ? <Table tableData={tableData} /> : <Table tableData={[]} />}</CardBody>
        </Card>
      </GridItem>
    );
  });
  return <GridContainer>{cards}</GridContainer>;
};

export const UltraSonicsCard = ({ sensors, sensorValues, windowDimensions, initialData }) => {
  const { deviceInfo, latestData } = initialData;

  console.log(deviceInfo);
  let batteryLevel = 0;
  let batteryPercent = 0;
  if (latestData.lastThree.length) {
    const maxThree = Math.max.apply(
      Math,
      latestData.lastThree.map(function (o) {
        return o.data.TC1;
      })
    );
    batteryLevel = (maxThree / deviceInfo.deviceMetaData.maximumDepth) * 100;
    batteryPercent = ((parseFloat(deviceInfo.metaData.BATTERY) - 3.4) * 100) / (3.75 - 3.4);
  }

  const batteryImage =
    batteryPercent <= 20 ? Bat1 : batteryPercent <= 40 ? Bat2 : batteryPercent <= 60 ? Bat3 : batteryPercent <= 80 ? Bat4 : Bat5;
  const imageData = []
  let tableData = [];
  sensors.forEach((sensor, index) => {
    const { lastThree, lastThreeImages } = sensorValues;
    lastThree.forEach((ele, i) => {
      if (sensor !== '-' && lastThree[index] && lastThree[i].timestamp && lastThree[i].data['TC' + (index + 1)])
        tableData.push([new Date(lastThree[i].timestamp).toLocaleString(), lastThree[i].data && lastThree[i].data['TC' + (index + 1)]]);
    });
    lastThreeImages && lastThreeImages.forEach((ele, i) => {

      let obj = {
        image: (<img src={ele.url} alt={`${i}image`} />),
        name: ele.fileName.split('_')[2].split('.')[0]
      }
      if (sensor !== '-' && lastThree[index] && lastThree[i].timestamp && lastThree[i].data['TC' + (index + 1)]) {
        imageData.push(obj)
      }

    })
  });

  const formatSimId = (id) => {
    const length = id.length;
    return id.substring(0, length / 2) + ' ' + id.substring(length / 2, length);
  };

  return (
    <GridContainer>
      <GridItem key={0} xs={12} sm={12} md={windowDimensions.width <= 1140 ? 12 : 3}>
        <Card className={' dev-card-body' + 0}>
          <CardHeader color='primary' stats icon>
            <CardIcon color='primary' className={' dev-card' + 0}>
              <Icon style={{ fontSize: '16px' }}>{1}</Icon>
            </CardIcon>
          </CardHeader>
          <h4 className='device-card-header'>Latest Readings</h4>
          <CardBody className='p-t-0'>
            {sensorValues && sensorValues.lastThree.length ? (
              <Table tableData={tableData} />
            ) : (
              <Table tableData={[['-'], ['-'], ['-'], ['-']]} />
            )}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem key={0} xs={12} sm={12} md={windowDimensions.width <= 1140 ? 12 : 3}>
        <Card className={' dev-card-body' + 1}>
          <CardHeader color='primary' stats icon>
            <CardIcon color='primary' className={' dev-card' + 1}>
              <Icon style={{ fontSize: '16px' }}>{2}</Icon>
            </CardIcon>
          </CardHeader>
          <h4 className='device-card-header'>Current Level</h4>
          <CardBody className='p-t-0 battery-level'>
            <RadialChart batteryLevel={batteryLevel} />
            <div className='battery-percent1'>{batteryLevel.toFixed(2)}%</div>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem key={0} xs={12} sm={12} md={windowDimensions.width <= 1140 ? 12 : 3}>
        <Card className={' dev-card-body' + 2}>
          <CardHeader color='primary' stats icon>
            <CardIcon color='primary' className={' dev-card' + 2}>
              <Icon style={{ fontSize: '16px' }}>{3}</Icon>
            </CardIcon>
          </CardHeader>
          <h4 className='device-card-header'>Images</h4>
          <CardBody className='p-t-0 battery-img-cont'>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {imageData.map((item) => (

                <div style={{ display: "flex" }}>
                  {(<div>{item.name} </div>)}{(<div className={classes.imageSize}>{item.image} </div>)}
                </div>


              ))}
            </div>
            {/* <div className='battery-container'>
              <img src={batteryImage} alt='' />
            </div>
            <div className='battery-percent'>{batteryPercent.toFixed(2)}%</div> */}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem key={0} xs={12} sm={12} md={windowDimensions.width <= 1140 ? 12 : 3}>
        <Card className={' dev-card-body' + 3}>
          <CardHeader color='primary' stats icon>
            <CardIcon color='primary' className={' dev-card' + 3}>
              <Icon style={{ fontSize: '16px' }}>{4}</Icon>
            </CardIcon>
          </CardHeader>
          <h4 style={{ marginBottom: "1.1vw" }} className='device-card-header '>Device Info</h4>
          <CardBody className='p-t-0' style={{ marginTop: "-1.5vw" }}>
            <Table
              tableData={[
                ['Application', deviceInfo.deviceMetaData.applicationType],
                ['Max Depth', deviceInfo.deviceMetaData.maximumDepth],
                ['SIM', formatSimId(deviceInfo.metaData.SIMID) || '-'],
                ['RSSI', parseInt(deviceInfo.metaData.RSSI) || '-'],
                ['Battery', `${parseInt(batteryPercent.toFixed(2))}%` || '-'],
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export const IntelligenceCard = ({ intelligence,template,rainSummary }) => {
console.log(rainSummary)
  return (
    <Card className='intelligence-card'>
      <CardHeader color='primary' className='align-self-center' stats icon>
        <CardIcon color='primary' className='intelligence-card-header'>
          <p className='m-0'>INTELLIGENCE</p>
        </CardIcon>
      </CardHeader>
      {intelligence && (
        <CardBody className='text-align-right' style={{ overflowWrap: 'anywhere' }}>
          <div className='intelligence-fields'>Events</div>
          <Table
            tableData={[
              ['Events For Period', intelligence.event.events],
              ['Highest', intelligence.event.highest],
              ['Lowest', intelligence.event.lowest],
            ]}
          />
          <div className='intelligence-fields'>Summary</div>
          <Table
            tableData={[
              ['Lowest', intelligence.summary.minimum],
              ['Highest', intelligence.summary.maximum],
              ['Average', intelligence.summary.average],
            ]}
          />
          {/* {
            template === 'Ultrasonic' && <div className={classes.RainDataSummery}>
              <div className='intelligence-fields'>Rain Summary</div>
              <Table
                tableData={[
                  ['Lowest', rainSummary?.minimum],
                  ['Highest', rainSummary?.maximum],
                  ['Average', rainSummary?.average],
                ]}
              />
            </div>
          } */}
        </CardBody>
      )}
    </Card>
  );
};
