import React, { useContext, Fragment, useEffect, useState } from "react";
import {
  Drawer,
  Grid,
  InputAdornment,
  makeStyles,
  Button as MuiButton,
  TextField,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { DropDown } from "../../components/DropDown/DropDown";
import Button from "../../components/CustomButtons/Button";
import { DashboardServices } from "../../services/DashBoardServices";
import DashCard from "../../components/Card/DashCard";
import MapComponent from "../../components/Map/MapComponent";
import BuildingMapComponent from "../../components/Map/BuildingMapComponent";
import { AuthService } from "../../services/AuthService";
import { START_LOADER, STOP_LOADER } from "../../constants";
import { LoaderContext } from "../../context/LoaderContext";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchIcon from "@material-ui/icons/Search";
import { FloorMapService } from "../../services/FloorMapService";
import { CompanyService } from "../../services/CompanyService";
import { BuildingService } from "../../services/BuildingService";

const LIMIT = 9;

const colorFilter = [
  { id: 2, name: "All" },
  { id: 0, name: "Green" },
  { id: 1, name: "Red" },
];

const useStyles = makeStyles({
  buttonContainer: {
    maxWidth: "17.333333% !important",
  },
  MuiButton: {
    color: "purple",
    border: "1px solid purple",
    fontSize: "0.7rem",
    padding: "0.3rem",
  },
  MuiButton2: {
    color: "#0064C1",
    border: "1px solid #0064C1",
    fontSize: "0.7rem",
    padding: "0.3rem",
    width: "100%",
  },
  MuiButtonSelected: {
    color: "white",
    border: "1px solid white",
    fontSize: "0.7rem",
    padding: "0.3rem",
    width: "100%",
    backgroundColor: "#0064C1",
  },
  MuiButtonContainer: {
    display: "flex",
    gap: "1vw",
    alignItems: "center",
    justifyContent: "space-between",
  },
  drawer: {
    padding: "1vw",
  },
  infiniteScroll: {
    display: "flex",
    gap: "1%",
    flexWrap: "wrap",
    justifyContent: "start",
  },
  infiniteScrollCard: {
    width: "32%",
    marginBottom: "1.5vh",
  },
  searchBtn: {
    margin: 0,
    padding: "11px 12px 11px 15px",
  },
  searchWrapper: {
    paddingRight: "0",
    marginRight: "1.3px",
  },
  searchInput: {
    padding: "11px 14px",
  },
});

export const DashboardPage = () => {
  const classes = useStyles();
  const user = AuthService.getCurrentUser();
  const userRole = AuthService.getUserRole();
  const { dispatch } = useContext(LoaderContext);
  const hasAccess = user.managerAdmin ? true : ["admin"].includes(userRole);
  const [deviceData, setDeviceData] = useState([]);
  const [deviceCardData, setDeviceCardData] = useState([]);
  const [deviceCardData1, setDeviceCardData1] = useState([]);
  const initialValues = {
    locationName: "",
    companyId: "",
    filter: "",
  };

  const [locationData, setLocationData] = useState([]);
  const [floorOptions, setFloorOptions] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [companyHashMap, setCompanyHashMap] = useState({});
  const [selectedFloor, setSelectedFloor] = useState(0);
  const [selectedColor, setSelectedColor] = useState(2);
  const [allData, setAllData] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState(0);
  const [map, setMap] = useState(false);
  const [buildingMap, setbuildingMap] = useState(false);
  const [deviceMap, setdeviceMap] = useState(false);
  const [location, setLocation] = useState({});
  const [pagination, setPagination] = useState({
    totalPages: 0,
    curPage: 1,
    totalCount: 1,
  });
  const [pagination1, setPagination1] = useState({
    totalPages: 0,
    curPage: 1,
    totalCount: 1,
  });
  const [apiCallState, setApiCallState] = useState(0);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState({
    status: Date.now(),
    replaceState: false,
  });
  const [refresh1, setRefresh1] = useState({
    status: Date.now(),
    replaceState: false,
  });
  const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });

  useEffect(() => {
    DashboardServices.GetDashLocationData(
      () => startLoader("dataLocationLoading"),
      handleFetchLocationSuccess,
      () => console.log("error"),
      () => stopLoader("dataLocationLoading")
    );
    CompanyService.ReadAll(
      () => startLoader("companyLoading"),
      handleFetchCompanySuccess,
      () => console.log("error"),
      () => stopLoader("companyLoading")
    );
    FloorMapService.Read(
      10000,
      0,
      () => startLoader("dataFloorLoading"),
      handlefetchFloorSuccess,
      () => console.log("error"),
      () => stopLoader("dataFloorLoading")
    );
    BuildingService.Read(
      10000,
      0,
      () => startLoader("dataBuildingLoading"),
      handlefetchBuildingSuccess,
      () => console.log("error"),
      () => stopLoader("dataBuildingLoading")
    );
  }, []);

  useEffect(() => {
    fetchInitalDeviceData(refresh.replaceState);
  }, [refresh.status]);

  useEffect(() => {
    if (apiCallState) handleSubmit(refresh1.replaceState);
  }, [refresh1.status]);

  const fetchInitalDeviceData = (replaceState) => {
    DashboardServices.GetDashData(
      { limit: LIMIT, offset: (pagination.curPage - 1) * LIMIT, search },
      () => {},
      (res) => handleFetchSuccess(res, replaceState),
      (err) => console.log(err),
      () => {}
    );
  };

  const handleFetchSuccess = async (res, replaceState) => {
    const data = await res.data.data;
    if (data.mapData.length) {
      setLocation({ lat: data.mapData[0].lat, lng: data.mapData[0].lng });
    } else {
      setLocation({ lat: 51.5074, lng: 0.1278 });
    }
    const respagination = data.pagination;
    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(respagination.totalCount / LIMIT),
      curPage: pagination.curPage + 1,
      totalCount: respagination.totalCount,
    }));
    setDeviceData(data.mapData);
    setBuildings(data.buildingData);
    setAllData(data.deviceData);
    if (replaceState) setDeviceCardData(data.deviceData);
    else setDeviceCardData(deviceCardData.concat(data.deviceData));
  };
  const handleFetchLocationSuccess = async (res) => {
    const data = await res.data.data.locations;
    const tempData = {
      id: 0,
      locationName: "All",
    };
    data.unshift(tempData);
    setLocationData(data);
  };
  const handlefetchFloorSuccess = async (res) => {
    const data = await res.data.data.floor;
    if (Array.isArray(data) && data.length) {
      const options = [{ id: 0, name: "All" }];
      data.forEach((x) => {
        options.push({
          id: x.floorId,
          name: x.floorName,
        });
      });
      setFloorOptions(options);
    }
  };

  const handlefetchBuildingSuccess = async (res) => {
    const data = await res.data.data.building;
    if (Array.isArray(data) && data.length) {
      const options = [{ id: 0, name: "All" }];
      data.forEach((x) => {
        options.push({
          id: x.buildingId,
          name: x.buildingName,
        });
      });
      setBuildingOptions(options);
    }
  };

  const handleFetchCompanySuccess = async (res) => {
    const data = await res.data.data.company;
    const tempData = {
      id: 0,
      companyName: "All",
    };
    data.unshift(tempData);
    setCompanyData(data);
    const map = {};
    data.forEach((element) => {
      map[element.id] = element;
    });
    setCompanyHashMap(map);
  };

  const handleSubmit = (replaceState) => {
    const params = {
      limit: LIMIT,
      offset: (pagination1.curPage - 1) * LIMIT,
      search,
      status: selectedColor,
      locationId: selectedLocation,
      companyId: selectedCompany,
      floor: selectedFloor,
      buildingId: selectedBuilding,
    };

    DashboardServices.GetFilteredData(
      params,
      () => {},
      (res) => handleFetchSuccess1(res, replaceState),
      (err) => console.log(err),
      () => {}
    );
  };
  const handleFetchSuccess1 = async (res, replaceState) => {
    const data = await res.data.data;
    // if (data.deviceData.length) setLocation({ lat: data.deviceData[0].lat, lng: data.deviceData[0].lng });
    const respagination = data.pagination;
    setPagination1((prev) => ({
      ...prev,
      totalPages: Math.ceil(respagination.totalCount / LIMIT),
      curPage: pagination1.curPage + 1,
      totalCount: respagination.totalCount,
    }));
    if (replaceState) setDeviceCardData1(data.deviceData);
    else setDeviceCardData1(deviceCardData1.concat(data.deviceData));
    setBuildings(data.buildingData);
    setDeviceData(data.mapData);
    setAllData(data.deviceData);
  };
  // const changeColor = (value) => {
  //   if (value === 1 || value === 0) {
  //     const filterData = allData.filter((item) => item.status === value);
  //     const filterMapData = allData.filter(
  //       (item) => item.status === value && item.locationMode === "Gps"
  //     );
  //     setDeviceCardData(filterData);
  //     setDeviceData(filterMapData);
  //   } else {
  //     setDeviceCardData(allData);
  //   }
  //   setSelectedColor(value);
  // };

  const fetchData = () => {
    if (apiCallState) {
      setRefresh1({ status: Date.now(), replaceState: false });
    } else {
      setRefresh({ status: Date.now(), replaceState: false });
    }
  };

  const initiateSearch = () => {
    if (apiCallState) {
      setPagination1({ totalPages: 0, curPage: 1, totalCount: 1 });
      setRefresh1({ status: Date.now(), replaceState: true });
    } else {
      setPagination({ totalPages: 0, curPage: 1, totalCount: 1 });
      setRefresh({ status: Date.now(), replaceState: true });
    }
  };

  return (
    <Fragment>
      <Grid container direction="column" spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TopBar
            setMap={setMap}
            setdeviceMap={setdeviceMap}
            deviceMap={deviceMap}
            map={map}
            buildingMap={buildingMap}
            setbuildingMap={setbuildingMap}
            initialValues={initialValues}
            locationData={locationData}
            companyData={companyData}
            selectedLocation={selectedLocation}
            selectedCompany={selectedCompany}
            setSelectedLocation={setSelectedLocation}
            setSelectedCompany={setSelectedCompany}
            handleSubmit={() => {
              setPagination1({ totalPages: 0, curPage: 1, totalCount: 1 });
              setApiCallState(1);
              setRefresh1({ status: Date.now(), replaceState: true });
            }}
            selectedColor={selectedColor}
            // changeColor={changeColor}
            userRole={userRole}
            initiateSearch={initiateSearch}
            setSearch={setSearch}
            setSelectedColor={setSelectedColor}
            hasAccess={hasAccess}
            floorOptions={floorOptions}
            setSelectedFloor={setSelectedFloor}
            selectedFloor={selectedFloor}
            user={user}
            setSelectedBuilding={setSelectedBuilding}
            selectedBuilding={selectedBuilding}
            buildingOptions={buildingOptions}
          />
        </Grid>
        <Grid item style={{ marginTop: "2vh" }}>
          {map && deviceMap ? (
            <div style={{ height: "80vh", width: "100%" }}>
              <MapComponent
                isMarkerShown
                locations={deviceData}
                defaultLocation={location}
              />
            </div>
          ) : map && buildingMap ? (
            <div style={{ height: "80vh", width: "100%" }}>
              <BuildingMapComponent
                startLoader={startLoader}
                stopLoader={stopLoader}
                isMarkerShown
                locations={buildings}
                defaultLocation={location}
              />
            </div>
          ) : (
            <Grid>
              {!apiCallState && deviceCardData !== undefined ? (
                <InfiniteScroll
                  dataLength={deviceCardData.length}
                  next={fetchData}
                  hasMore={pagination.totalCount !== deviceCardData.length}
                  loader={
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h4>Loading...</h4>
                    </div>
                  }
                  className={classes.infiniteScroll}
                  endMessage={
                    <p style={{ textAlign: "center", width: "100%" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  scrollableTarget="scroll-target"
                  // scrollThreshold={0.6}
                >
                  {deviceCardData.map((item) => (
                    <Grid className={classes.infiniteScrollCard}>
                      <DashCard item={item} companyHashMap={companyHashMap} />
                    </Grid>
                  ))}
                </InfiniteScroll>
              ) : (
                ""
              )}
              {apiCallState && deviceCardData1 !== undefined ? (
                <InfiniteScroll
                  dataLength={deviceCardData1.length}
                  next={fetchData}
                  hasMore={pagination1.totalCount !== deviceCardData1.length}
                  loader={
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h4>Loading...</h4>
                    </div>
                  }
                  className={classes.infiniteScroll}
                  endMessage={
                    <p style={{ textAlign: "center", width: "100%" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  scrollableTarget="scroll-target"
                  // scrollThreshold={0.6}
                >
                  {deviceCardData1.map((item) => (
                    <Grid className={classes.infiniteScrollCard}>
                      <DashCard item={item} companyHashMap={companyHashMap} />
                    </Grid>
                  ))}
                </InfiniteScroll>
              ) : (
                ""
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

const TopBar = ({
  initialValues,
  locationData,
  companyData,
  selectedLocation,
  selectedCompany,
  setSelectedLocation,
  setSelectedCompany,
  handleSubmit,
  selectedColor,
  setSelectedColor,
  // changeColor,
  setMap,
  map,
  userRole,
  user,
  buildingMap,
  setbuildingMap,
  setdeviceMap,
  deviceMap,
  initiateSearch,
  setSearch,
  hasAccess,
  setSelectedFloor,
  floorOptions,
  selectedFloor,
  setSelectedBuilding,
  selectedBuilding,
  buildingOptions,
}) => {
  const classes = useStyles();
  const filterRoles = ["admin"];
  if (user.managerAdmin) {
    filterRoles.push("manager");
  }

  const [drawerState, setDrawerState] = useState(false);
  return (
    <>
      <Drawer
        variant="temporary"
        anchor={"right"}
        open={drawerState}
        onClose={() => setDrawerState(false)}
      >
        <div className={classes.drawer}>
          <Formik onSubmit={handleSubmit} initialValues={initialValues}>
            {({ handleSubmit, isSubmitting, values }) => (
              <Form>
                <Grid container direction="column" spacing={2}>
                  <Grid
                    container
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    spacing={2}
                  >
                    {filterRoles.includes(userRole) && (
                      <>
                        <Grid item xs={12}>
                          <Field
                            component={DropDown}
                            value={selectedCompany}
                            onChange={(data) =>
                              setSelectedCompany(data.target.value)
                            }
                            name="companyId"
                            type="select"
                            values={
                              companyData &&
                              companyData.map((x) => ({
                                id: x.id,
                                name: x.companyName,
                              }))
                            }
                            fullWidth
                            label="Company"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            component={DropDown}
                            name="locationName"
                            value={selectedLocation}
                            onChange={(data) =>
                              setSelectedLocation(data.target.value)
                            }
                            type="select"
                            values={locationData.map((x) => ({
                              id: x.id,
                              name: x.locationName,
                            }))}
                            fullWidth
                            label="Location Name"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Field
                            component={DropDown}
                            value={selectedFloor}
                            onChange={(data) =>
                              setSelectedFloor(data.target.value)
                            }
                            name="room"
                            type="select"
                            values={floorOptions}
                            fullWidth
                            label="Select Room"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            component={DropDown}
                            value={selectedBuilding}
                            onChange={(data) =>
                              setSelectedBuilding(data.target.value)
                            }
                            name="buildingId"
                            type="select"
                            values={buildingOptions}
                            fullWidth
                            label="Select Dwelling"
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <Field
                        component={DropDown}
                        name="filter"
                        type="select"
                        value={selectedColor}
                        onChange={(data) => setSelectedColor(data.target.value)}
                        values={colorFilter.map((x) => ({
                          id: x.id,
                          name: x.name,
                        }))}
                        fullWidth
                        label="Filter"
                        disabled={buildingMap}
                      />
                    </Grid>
                    <Grid item xs={1} className={classes.buttonContainer}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        mb={2}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Drawer>
      <Grid className={classes.MuiButtonContainer}>
        <MuiButton
          className={classes.MuiButton}
          type="submit"
          variant="outlined"
          color="primary"
          mb={2}
          onClick={() => {
            setMap(!map);
            setdeviceMap(!deviceMap);
          }}
        >
          {map ? "Switch to card view" : "Switch to map view"}
        </MuiButton>
        <Grid style={{ display: "flex", alignItems: "center" }}>
          <TextField
            variant="outlined"
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              className: classes.searchWrapper,
              classes: {
                input: classes.searchInput,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    color="primary"
                    className={classes.searchBtn}
                    onClick={initiateSearch}
                  >
                    <SearchIcon />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          {hasAccess && (
            <Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                mb={2}
                onClick={() => setDrawerState(true)}
              >
                Filter
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        xl={12}
        container
        direction="row"
        spacing={2}
      >
        {map ? (
          <>
            {" "}
            <Grid item xs={2}>
              <MuiButton
                className={
                  deviceMap ? classes.MuiButtonSelected : classes.MuiButton2
                }
                type="submit"
                variant="outlined"
                mb={2}
                onClick={() => {
                  setbuildingMap(false);
                  setdeviceMap(true);
                }}
              >
                View Device
              </MuiButton>
            </Grid>
          </>
        ) : null}
        {map ? (
          <>
            {" "}
            <Grid item xs={2}>
              <MuiButton
                className={
                  buildingMap ? classes.MuiButtonSelected : classes.MuiButton2
                }
                type="submit"
                variant="outlined"
                mb={2}
                onClick={() => {
                  setbuildingMap(true);
                  setdeviceMap(false);
                }}
              >
                View Dwelling
              </MuiButton>
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
};
