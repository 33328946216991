import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/tableStyle";

const useStyles = makeStyles({
    ...styles,
    selectedRow: {
        background: "#ececec",
    },
    cursor: {
        cursor: "pointer",
    },
});

export default function CustomTable(props) {
    const classes = useStyles();
    const {
        tableHead,
        tableData,
        tableHeaderColor,
        selected = null,
        onClick = false,
    } = props;
    // console.log(props)
    return (
        <div className={classes.tableResponsive}>
            <Table className={classes.table}>
                {tableHead !== undefined ? (
                    <TableHead
                        className={classes[tableHeaderColor + "TableHeader"] + 'ssa'}
                    >
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((prop, key) => {
                                return (
                                    <TableCell
                                        className={
                                            classes.tableCell +
                                            " " +
                                            classes.tableHeadCell
                                        }
                                        key={key}
                                    >
                                        {prop}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {tableData.map((prop, key) => {
                        return (
                            <TableRow
                                key={key}
                                className={`
                                    ${classes.tableBodyRow} ${
                                    prop[0] === selected && classes.selectedRow
                                } ${onClick && classes.cursor}`}
                                onClick={onClick ? () => onClick(prop) : undefined}
                            >
                                {prop.map((prop, key) => {
                                    return (
                                        <TableCell
                                            className={classes.tableCell}
                                            key={key}
                                        >
                                            {prop}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
}

CustomTable.defaultProps = {
    tableHeaderColor: "gray",
};

CustomTable.propTypes = {
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray",
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};