import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { MAP_API_KEY } from "../../config";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { LOCAL_PATH } from "../../constants";
import locationimage from "../../assets/locationimage.svg";
import  {FloorMapService} from "../../services/FloorMapService"
import { ErrorOutlined } from "@material-ui/icons";
import {FloorMapModal}from "../../view/FloorMap/FloorMapModal"
import { FormDialog } from '../../components/FormDialog/FormDialog';
const BuildingMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key="+MAP_API_KEY+"&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `700px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(({locations,defaultLocation,setLocation = () => {},setShowModal=() => {},defaultDraggable,stopLoader,startLoader}) => {
  console.log(locations)
    const [infoWindow,setInfoWindow]=useState('');
    const [showModalFloor,setShowModalFloor]= useState(false)
    const [markerPosition,setMarkerPosition]=useState(defaultLocation);
    const [floorData,setFloorData]=useState([]);
    const history = useHistory();
    const onMapClick = ({latLng}) =>{
      setMarkerPosition(latLng)
      setLocation({lat:latLng.lat(),lng:latLng.lng()});
      setShowModal(false)
    }
    const handleFloorModalShow =(buildingId)=>{
      FloorMapService.getFloorByBuilding(
        buildingId,
        ()=>{startLoader('dataLoading')},
        handleDataSuccess,
        handleDataError,
        ()=>{stopLoader('dataLoading')}

        )
    }
    const handleDataSuccess =(res)=>{
      console.log(res.data.data)
      const floors= res.data.data.floor
      setFloorData(floors)
      setShowModalFloor(true)
    }
    const handleDataError=(err)=>{
      console.log(ErrorOutlined)
    }
    const markers = locations ? locations.map(device=>{
          let markerIcon=  locationimage
          return  (
          <Marker
            position={{lat:device.lat,lng:device.lng}}
            options={{icon:markerIcon}}
            onMouseOver={()=>setInfoWindow(device.buildingName)}
            onMouseOut={()=>setInfoWindow('')}
            onClick={()=>{handleFloorModalShow(device.buildingId)}}
            >
                {(infoWindow===device.buildingName) && 
                    <InfoWindow>
                        <p> <strong>
                                {device.buildingName} <br />
                                {/* {device.deviceAlias} <br /> */}
                              
                            </strong>
                        </p>
                    </InfoWindow>}
          </Marker>
        )
    }): null
    return (<>
     <FormDialog
        onClose={() => setShowModalFloor(false)}
        show={showModalFloor}
        title={`Rooms`}
        content={
          <FloorMapModal onClose={() => setShowModalFloor(false)} startLoader={startLoader} stopLoader={stopLoader} floorData={floorData} />
        }
      />
    
    {
        markers ?
        <GoogleMap defaultZoom={3} defaultCenter={defaultLocation}>
            {markers}
        </GoogleMap> : 
        <GoogleMap defaultZoom={12} defaultCenter={defaultLocation} onClick={!defaultDraggable ? onMapClick : undefined} >
            <Marker position={markerPosition} defaultDraggable={!defaultDraggable} onDragEnd={onMapClick}/>
        </GoogleMap> }
        </>
    )
});

export default BuildingMapComponent;