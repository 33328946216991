import ImageMarker, { Marker, MarkerComponentProps } from 'react-image-marker';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { LOCAL_PATH } from '../../constants';
import ReactTooltip from 'react-tooltip';
import classes from './Canvas.module.css';
export const CanvasComponent = ({ markers = [], setMarkers = () => {}, image, status = false }) => {
  const history = useHistory();

  const styleUl = { listStyleType: 'none', fontWeight: 700, textAlign: 'left' };
  const style = { type: 'dark', effect: 'float', place: 'top', offset: 'left' };
  const CustomMarker = (props) => {
    let markerIcon =
      status === 1 ? 'http://maps.google.com/mapfiles/ms/icons/red-dot.png' : 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
    return (
      <>
        <img
          className={classes.canvasImage}
          src={markerIcon}
          onClick={() => props.status != undefined && history.push(LOCAL_PATH.DEVICE, { id: props.deviceName })}
          data-tip
          data-for={props.deviceName}
          alt=''
        />
        {props.status != undefined && (
          <ReactTooltip
            id={props.deviceName}
            aria-haspopup='true'
            className={classes.extraClass}
            role='example'
            type={style.type}
            effect={style.effect}
            place={style.place}
          >
            <div style={styleUl}>
              <p>{props.deviceName}</p>
              <p>{props.deviceAlias}</p>
              <p>{props.managerName}</p>
            </div>
          </ReactTooltip>
        )}
      </>
    );
  };
  return (
    <div>
      <ImageMarker
        src={image}
        markers={markers}
        onAddMarker={(marker) => {
          setMarkers([{ ...marker, name: `device` }]);
        }}
        markerComponent={CustomMarker}
      />
    </div>
  );
};
