import React, { useContext, useState, useEffect, Fragment } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Table from "../../components/Table/Table";
import { LoaderContext } from "../../context/LoaderContext";
import { BRIEFS, START_LOADER, STOP_LOADER } from "../../constants";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { CompanyService } from "../../services/CompanyService";
import { FormDialog } from "../../components/FormDialog/FormDialog";
import { AddCompany } from "./AddCompany";
import { CustomToolTip } from "../../components/CustomToolTip/CustomToolTip";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  button: {
    float: "right",
  },
  pagination: {
    marginLeft: "auto",
  },
});

const LIMIT = 10;

export const CompanyPage = () => {
  const classes = useStyles();
  const { dispatch } = useContext(LoaderContext);
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });
  const companies = useCompanies(
    startLoader,
    stopLoader,
    refresh,
    pagination.curPage,
    setPagination
  );

  const afterCompanyCreate = () => {
    setShowModal(false);
    setRefresh((prev) => prev + 1);
  };
  return (
    <Fragment>
      <FormDialog
        show={showModal}
        onClose={() => setShowModal(false)}
        title={`Add Company`}
        content={
          <AddCompany
            startLoader={startLoader}
            stopLoader={stopLoader}
            onClose={afterCompanyCreate}
          />
        }
      />
      <Grid container direction="column" spacing={2}>
        <div className="info-container">
          <div>
            <div className="info-heading">
              {BRIEFS?.manageCompany?.heading}
            </div>
            <ul className="info-list">
              {Object.values(BRIEFS?.manageCompany?.description).map(
                (desc) => (
                  <li>{desc}</li>
                )
              )}
            </ul>
          </div>
          <div>
            <Grid item>
              <Button
                onClick={() => setShowModal(true)}
                className={classes.button}
                variant="contained"
                color="primary"
                mb={2}
              >
                Add Company
              </Button>
            </Grid>
          </div>
        </div>
        <Grid item xs={12} sm={12} md={12} className="w-65 margin-auto">
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Companies</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["SNo", "Name", "Options"]}
                tableData={companies}
              />
            </CardBody>
          </Card>
        </Grid>
        {pagination.totalPages > 1 ? (
          <Grid item xs={12} className={classes.pagination}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.curPage}
              variant="outlined"
              color="primary"
              onChange={(e, value) =>
                setPagination((prev) => ({
                  ...prev,
                  curPage: value,
                }))
              }
            />
          </Grid>
        ) : null}
      </Grid>
    </Fragment>
  );
};

const useCompanies = (
  startLoader,
  stopLoader,
  refresh,
  curPage,
  setPagination
) => {
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    CompanyService.Read(
      LIMIT,
      (curPage - 1) * LIMIT,
      () => startLoader("companyFetch"),
      handleCompanyReadSuccess,
      handleCompanyReadError,
      () => stopLoader("companyFetch")
    );
  }, [refresh, curPage]);

  const handleCompanyReadSuccess = (res) => {
    const data = res.data.data;
    const pagination = data.pagination;
    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(pagination.totalCount / LIMIT),
    }));
    const finalData = data.company.map((item, index) => [
      item.id,
      index + 1,
      item.companyName,
      getOptions(item.id),
    ]);
    setCompanies(finalData);
  };

  const handleCompanyReadError = (err) => {
    console.log(err);
  };

  const deleteCompany = (id) => {
    CompanyService.Delete(
      id,
      () => startLoader("deleteCompany"),
      () => deleteCompanySuccess(id),
      deleteCompanyError,
      () => stopLoader("deleteCompany")
    );
  };
  const deleteCompanySuccess = (id) => {
    setCompanies((prev) => prev.filter((x) => x[0] !== id));
  };
  const deleteCompanyError = (err) => {
    console.log(err);
  };

  const getOptions = (id) => {
    return (
      <>
        <CustomToolTip
          title={"Delete Company"}
          onClick={() => deleteCompany(id)}
          Icon={DeleteIcon}
        />
      </>
    );
  };
  return companies.map((x, i) => [
    (curPage - 1) * LIMIT + i + 1,
    ...x.slice(2),
  ]);
};
