import axios from "axios";
import { STORED_CREDENTIALS, LOCAL_PATH } from "../constants";
import { BASE_URL, VERSION_PREFIX } from "../config";

const HEADERS = {
    "Content-Type": "application/json",
};

export const HttpClient = axios.create({
    baseURL: `${BASE_URL}/${VERSION_PREFIX}`,
    headers: {
        ...HEADERS,
    },
});

HttpClient.interceptors.request.use((config) => {
    let credentials = localStorage.getItem(STORED_CREDENTIALS) || "{}";
    credentials = JSON.parse(credentials);
    config.headers.Authorization = `Bearer ${credentials.token}`;
    return config;
});

HttpClient.interceptors.response.use(
    (res) => {
        return Promise.resolve(res);
    },
    (error) => {
        if (error != null && error.response.status === 401) {
            localStorage.removeItem(STORED_CREDENTIALS);
            window.location.href = LOCAL_PATH.LOGIN;
        }
        return Promise.reject(error);
    }
);
