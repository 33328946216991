import React, { useContext, useState, useEffect, Fragment } from "react";
import { Grid, InputAdornment, makeStyles, TextField } from "@material-ui/core";
import { UdpLogService } from "../../services/UdpLogService";
import { LoaderContext } from "../../context/LoaderContext";
import { BRIEFS, START_LOADER, STOP_LOADER } from "../../constants";
import { DeleteDialog } from "../../components/FormDialog/DeleteDialog";
import { Pagination } from "@material-ui/lab";
import Button from "../../components/CustomButtons/Button";
import SearchIcon from "@material-ui/icons/Search";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Table from "../../components/Table/Table";
import moment from "moment";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  button: {
    float: "right",
  },
  pagination: {
    marginLeft: "auto",
  },
  searchBtn: {
    margin: 0,
    padding: "11px 12px 11px 15px",
  },
  searchWrapper: {
    paddingRight: "0",
    marginRight: "1.3px",
  },
  searchInput: {
    padding: "11px 14px",
  },
});

const LIMIT = 10;

const UdpLog = () => {
  const { dispatch } = useContext(LoaderContext);
  const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });
  const classes = useStyles();
  const [timeoutState, setTimeoutState] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState("");
  const logs = useLocations(refresh, pagination.curPage, setPagination, search);

  useEffect(() => {
    if (!timeoutState) {
      const timeoutId = setInterval(() => {
        setRefresh((prev) => prev + 1);
      }, 5000);
      setTimeoutState(timeoutId);
    }

    return () => {
      clearInterval(timeoutState);
      setTimeoutState(null);
    };
  }, []);

  const handleDelete = (id) => {
    UdpLogService.DeleteAll(
      () => startLoader("deleteAll"),
      handleDeleteSuccess,
      handleError,
      () => stopLoader("deleteAll")
    );
  };

  const handleDeleteSuccess = () => {
    toast.success("Logs deleted!");
  };

  const handleError = (err) => {
    toast.error(err?.response?.data?.message);
  };

  return (
    <Fragment>
      <DeleteDialog
        show={showModal}
        onClose={setShowModal}
        deletefunction={handleDelete}
      />
      <Grid container direction="column" spacing={2}>
        <div className="info-container">
          <div>
            <div className="info-heading">{BRIEFS?.udpLogs?.heading}</div>
            <ul className="info-list">
              {Object.values(BRIEFS?.udpLogs?.description).map((desc) => (
                <li>{desc}</li>
              ))}
            </ul>
          </div>
          <div>
            <Grid item className="info-container">
              <div>
                <TextField
                  variant="outlined"
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    className: classes.searchWrapper,
                    classes: {
                      input: classes.searchInput,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          color="primary"
                          className={classes.searchBtn}
                          onClick={() => setRefresh((prev) => prev + 1)}
                        >
                          <SearchIcon />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Button
                onClick={() => setShowModal(true)}
                className={classes.button}
                variant="contained"
                color="primary"
                mb={2}
              >
                Delete All
              </Button>
            </Grid>
          </div>
        </div>
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Logs</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Device ID", "Host", "Port", "Topic", "Message", "Timestap"]}
                tableData={logs}
              />
            </CardBody>
          </Card>
        </Grid>
        {pagination.totalPages > 1 ? (
          <Grid item xs={12} className={classes.pagination}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.curPage}
              variant="outlined"
              color="primary"
              onChange={(e, value) =>
                setPagination((prev) => ({
                  ...prev,
                  curPage: value,
                }))
              }
            />
          </Grid>
        ) : null}
      </Grid>
    </Fragment>
  );
};

const useLocations = (refresh, curPage, setPagination, search) => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    getData();
  }, [refresh, curPage]);

  const getData = () => {
    const params = {
      limit: LIMIT,
      offset: (curPage - 1) * LIMIT,
    };
    if (search) params.search = search;

    UdpLogService.Read(
      params,
      () => {},
      handleLogsReadSuccess,
      handleLogsReadError,
      () => {}
    );
  };

  const handleLogsReadSuccess = (res) => {
    const data = res.data.data;
    const pagination = data.pagination;
    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(pagination.totalCount / LIMIT),
    }));
    const finalData = data.udpLogs.map((item) => [
      item.deviceId,
      item.host,
      item.port,
      item.topic,
      item.message,
      moment(item.timestamp).toString(),
    ]);
    setLogs(finalData);
  };

  const handleLogsReadError = (err) => {
    console.log(err);
  };

  return logs;
};

export default UdpLog;
