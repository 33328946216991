import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';

// core components
import Admin from './layouts/Admin.js';
import './index.css';
import './assets/css/material-dashboard-react.css?v=1.9.0';
import { Auth } from './layouts/Auth.js';
import Loader from './components/Loader/Loader.js';
import { LoaderProvider } from './context/LoaderContext.js';
import { AuthService } from './services/AuthService.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <LoaderProvider>
    <ToastContainer autoClose={2000} position='bottom-left' />
    <Loader />
    <BrowserRouter>
      <Switch>
        <Route path='/auth' component={Auth} />
        <Route path='/dashboard' component={Admin} />
        {AuthService.getCurrentUser() ? <Redirect from='/' to='/dashboard/dashboard' /> : <Redirect from='/' to='/auth/login' />}
      </Switch>
    </BrowserRouter>
  </LoaderProvider>,
  document.getElementById('root')
);
