import React, { useEffect, useContext, useState } from "react";
import { LoaderContext } from "../../context/LoaderContext";
import {
    START_LOADER,
    STOP_LOADER,
    LOCAL_PATH,
} from "../../constants";
import { makeStyles, Grid, Button } from "@material-ui/core";
import { Lock as LockIcon } from "@material-ui/icons";
import { Formik, Form, Field } from "formik";
import { Input } from "../../components/Input/Input";
import { EmailValidation, ResetPasswordValidation } from "../../validators/userValidator";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import {  useHistory } from "react-router-dom";
import { AuthService } from "../../services/AuthService";
import { UserService } from "../../services/UserService";

const useStyles = makeStyles({
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    container: {
        width: "30vw",
        margin: "0 auto",
    },
    fields: {
        marginBottom: "1rem",
    },
});
export const ResetPassword = () => {
    const user = AuthService.getCurrentUser();
    const history = useHistory();
    const [confirmReset,setConfirmReset]=useState(false)
    const [message,setMessage]=useState(null)
    if (user) {
        history.push(LOCAL_PATH.HOME);
    }

    const { dispatch } = useContext(LoaderContext);
    const startLoader = () => dispatch({ type: START_LOADER, payload: "forgetPassword" });
    const stopLoader = () => dispatch({ type: STOP_LOADER, payload: "forgetPassword" });
    const classes = useStyles();
    const initialValues = { email: "" };

    const handleSubmit = (values) => {
        setMessage(null)
        if(confirmReset){
            delete values['confirmPassword'];
            UserService.ResetPassword(
                values,
                ()=>startLoader('passwordReset'),
                passwordChangeSuccess,
                (err)=>console.log(err),
                ()=>stopLoader('passwordReset')
            )
            return
        }
        UserService.ResetRequest(
            values,
            ()=>startLoader('ResetRequest'),
            handleChange,
            (err)=>console.log(err),
            ()=>stopLoader('ResetRequest')
        )
    };

    const handleChange = ({data}) => {
        if(!data.error){
            setConfirmReset(true)
            return
        }
        setMessage(data.message)
    };

    const passwordChangeSuccess = ({data}) =>{
        if(!data.error  || data.message=="Exceeded Maximum Monthly Resets"){
            setConfirmReset(false)
            setMessage(data.message)
            setTimeout(()=>{
                history.push(LOCAL_PATH.LOGIN)
            },1800)
            return
        }
        setMessage(data.message)
    }

    return (
        <div className="login-page">
            <div className={classes.container + " login-page-card"}>
                <Card>
                    <CardHeader color="primary" className="reset-password-header">
                        <h4 className={classes.cardTitleWhite}>
                            <LockIcon />
                            Reset Password
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <Formik
                            onSubmit={handleSubmit}
                            validationSchema={confirmReset ? ResetPasswordValidation : EmailValidation}
                            initialValues={initialValues}
                        >
                            {({ handleSubmit, isSubmitting, values }) => (
                                <Form>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Field
                                                component={Input}
                                                name="email"
                                                type="email"
                                                disabled={confirmReset}
                                                fullWidth
                                                label="Email Address"
                                            />
                                        </Grid>
                                        {(message && message!=="OTP Does not match") ? 
                                         <Grid item xs={12} sm={12} md={12} className="forget-password-link">
                                            <div 
                                                className={message=="Password Changed Successfully!" ? "success-msg" : "error-msg"}
                                                >{message}</div>
                                         </Grid> : null}
                                        {confirmReset ?
                                        <>
                                        <Grid item xs={12} sm={12} md={12} className="forget-password-link">
                                            <div className="success-msg">OTP has been sent to your mobile</div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Field
                                                component={Input}
                                                name="userOtp"
                                                type="text"
                                                fullWidth
                                                label="OTP"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Field
                                                component={Input}
                                                name="password"
                                                type="password"
                                                fullWidth
                                                label="Password"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Field
                                                component={Input}
                                                name="confirmpassword"
                                                type="password"
                                                fullWidth
                                                label="Confirm Password"
                                            />
                                        </Grid>
                                        </> : null}
                                        <Grid item xs={12} sm={12} md={12} className="forget-password-link">
                                            <div className="forget-password" onClick={()=>history.push(LOCAL_PATH.LOGIN)}>Login With Credentials</div>
                                        </Grid>
                                        {(message && message==="OTP Does not match") ? 
                                         <Grid item xs={12} sm={12} md={12} className="forget-password-link">
                                            <div className="error-msg">{message}</div>
                                         </Grid> : null}
                                        <Grid item xs={12} sm={12} md={12} style={{textAlign:'center'}}>
                                            <Button
                                                type="submit"
                                                variant="outlined"
                                                color="primary"
                                                mb={2}
                                                disabled={message=="Password Changed Successfully!" || message=="Exceeded Maximum Monthly Resets"}
                                            >
                                                Reset Password
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};
