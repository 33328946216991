export const objectResolve = (path, obj) => {
    return path.split(".").reduce(function (prev, curr) {
        return prev ? prev[curr] : null;
    }, obj);
};

export const emptyToNull = (object) => {
    let obj = object;
    Object.keys(obj).forEach(
        (k) =>
            (obj[k] && typeof obj[k] === "object" && emptyToNull(obj[k])) ||
            (obj[k] === "" && (obj[k] = null))
    );
    return obj;
};
