import React from "react";
import { pure } from "recompose";
import Button from "@material-ui/core/Button";
import MuiButton from "../../../components/CustomButtons/Button";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
const useStyles = makeStyles({
  buttonContainer: {},
  MuiButton: {
    color: "purple",
    border: "1px solid purple",
    fontSize: "0.7rem",
    psubtracting: "0.3rem",
  },
  MuiButtonContainer: {
    margin: "auto",
  },
  button: {
    backgroundColor: "#0064C1",
  },
});
export const DeviceDashboardFilters = pure(
  ({
    set5SecTime,
    last5SecTime,
    sensorOptions,
    handleSubmit,
    deviceId,
    windowDimensions,
    graphData,
    handleRefreshGraph,
    deviceExportDataReactor,
    getDeviceExportData,
  }) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [filterValues, setFilterValues] = useState({
      sensor: sensorOptions && String(sensorOptions[0]?.option),
      fromDate: moment().startOf("day").format(),
      toDate: moment().endOf("day").format(),
    });

    const [intervalState, setIntervalState] = useState(null);
    // useEffect(() => {
    //   setFilterValues({
    //     sensor: 1,
    //     fromDate: moment().startOf('day').format(),
    //     toDate: moment().add(1, 'd').endOf('day').format(),
    //   });
    // }, [deviceId]);

    useEffect(() => {
      getRealTimeData();
    }, []);

    useEffect(() => {
      getDeviceExportData &&
        getDeviceExportData(filterValues.fromDate, filterValues.toDate);
    }, [deviceExportDataReactor]);

    const submitForm = (e) => {
      const processedFilters = {
        ...filterValues,
        fromDate: moment(filterValues.fromDate).startOf("day").format(),
        toDate: moment(filterValues.toDate).endOf("day").format(),
      };
      handleSubmit(e, processedFilters);
      getRealTimeData();
    };

    const getRealTimeData = () => {
      const isSameDay =
        moment().endOf("day").isSame(filterValues.toDate, "date") &&
        moment().startOf("day").isSame(filterValues.fromDate);
      if (intervalState) {
        clearInterval(intervalState);
      }
      if (isSameDay) {
        const newInterval = setInterval(() => {
          refreshGraph(isSameDay ? [] : graphData);
        }, 10000);
        setIntervalState(newInterval);
      }
    };

    const refreshGraph = (graphData) => {
      // console.log(filterValues)
      if (graphData?.timeStamp?.length > 0) {
        const processedFilters = {
          sensor: filterValues?.sensor,
          fromDate: last5SecTime
            ? last5SecTime
            : graphData?.timeStamp[graphData?.timeStamp.length - 1],
          toDate: moment().utc().format(),
        };
        handleRefreshGraph(processedFilters, graphData);
        set5SecTime(moment().utc().format());
      } else {
        const processedFilters = {
          sensor: filterValues?.sensor,
          fromDate: last5SecTime,
          toDate: moment().utc().format(),
        };
        handleRefreshGraph(processedFilters);
        set5SecTime(moment().utc().format());
      }
    };
    return (
      <form onSubmit={(e) => submitForm(e)}>
        <Grid container spacing={2} className="device-dashboard-filters">
          <Grid item xs={windowDimensions.width <= 1140 ? 3 : 4}>
            {sensorOptions && (
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "100%" }}
              >
                <InputLabel htmlFor="sensor">Filter By Sensor</InputLabel>
                <Select
                  native
                  label="Filter By Sensor"
                  onChange={(e) =>
                    setFilterValues((prev) => ({
                      ...prev,
                      sensor: e?.target?.value,
                    }))
                  }
                  inputProps={{
                    name: "sensor",
                    id: "sensor",
                  }}
                  value={filterValues.sensor}
                >
                  {sensorOptions.map((sensor) => (
                    <option key={sensor.option} value={sensor.option}>
                      {sensor.sensorName || "-"}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={3} className="align-self-center m-0">
              <KeyboardDatePicker
                disableToolbar
                autoOk
                className="m-0"
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="fromDate"
                label="Start Date"
                onChange={(date) =>
                  setFilterValues((prev) => ({
                    ...prev,
                    fromDate: moment(date).format(),
                  }))
                }
                value={filterValues.fromDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                fullWidth
                disableFuture={true}
              />
            </Grid>
            <Grid item xs={3} className="align-self-center">
              <KeyboardDatePicker
                disableToolbar
                autoOk
                className="m-0"
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="toDate"
                label="End Date"
                value={filterValues.toDate}
                minDate={moment(filterValues.fromDate).add(0, "d").format()}
                maxDate={moment(filterValues.fromDate).add(30, "d").format()}
                onChange={(date) =>
                  setFilterValues((prev) => ({
                    ...prev,
                    toDate: moment(date).format(),
                  }))
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                fullWidth
                disableFuture={true}
              />
            </Grid>
          </MuiPickersUtilsProvider>
          <Grid
            item
            xs={1}
            className={classes.buttonContainer + " align-self-center"}
          >
            <MuiButton
              type="submit"
              variant="contained"
              // className="primary"
              className={classes.button}
              size="large"
              mb={2}
              disabled={
                moment(filterValues.toDate).diff(
                  filterValues.fromDate,
                  "days"
                ) > 30
              }
            >
              Submit
            </MuiButton>
          </Grid>
        </Grid>
      </form>
    );
  }
);
