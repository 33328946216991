import { HttpClient } from "../utils/httpClient";

const PATH = {
    create: "/company",
    read: "/company",
    readAll: "/getAllCompany",
    delete: "/company",
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload)
        .then(callback)
        .catch(error)
        .finally(next);
};

const Read = (limit, offset, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.read}?limit=${limit}&offset=${offset}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

const ReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAll)
        .then(callback)
        .catch(error)
        .finally(next);
};

const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

export const CompanyService = {
    Create,
    Read,
    ReadAll,
    Delete,
};
