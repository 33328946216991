import React, { useState } from "react";
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { objectResolve } from "../../utils/ObjectResolver";
import { useEffect } from "react";

export const DropDown = ({ label, values = [], field, ...rest }) => {
  if (!values) values = [];
  const error =
    field &&
    objectResolve(field.name, rest.form.touched) &&
    objectResolve(field.name, rest.form.errors);
  return (
    <TextField
      id="select"
      label={label}
      {...field}
      {...rest}
      error={Boolean(error)}
      helperText={error}
      select
    >
      {values.map((value) => (
        <MenuItem value={value.id}>{value.name}</MenuItem>
      ))}
    </TextField>
  );
};

export const MultiSelectDropDown = ({
  label,
  values = [],
  selectedValues = [],
  field,
  ...rest
}) => {
  const filteredSelectedValues = values
    .map((item) => item.id)
    .filter((option) => selectedValues.includes(option));

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected(filteredSelectedValues);
  }, [JSON.stringify(filteredSelectedValues)]);

  const error =
    field &&
    objectResolve(field.name, rest.form.touched) &&
    objectResolve(field.name, rest.form.errors);

  return (
    <Select
      {...field}
      {...rest}
      input={<TextField label={label} />}
      multiple
      error={Boolean(error)}
      helperText={error}
      onChange={(e) => {
        setSelected(e.target.value);
        rest.form.setFieldValue(field.name, e.target.value);
      }}
      select
      value={selected}
      renderValue={(selected) => selected.join(', ')}
    >
      {values.map((item) => (
        <MenuItem value={item.id} key={item.id}>
          <>
            <Checkbox
              checked={selected.findIndex((val) => item.id === val) > -1}
              color="primary"
            />
            <ListItemText primary={item.name} />
          </>
        </MenuItem>
      ))}
    </Select>
  );
};
