import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Input } from "../../components/Input/Input";
import Button from "../../components/CustomButtons/Button";
import { capitalize, Grid, makeStyles } from "@material-ui/core";
import { DropDown } from "../../components/DropDown/DropDown";
import { ALERTS } from "../../constants";
import { AssignDeviceService } from "../../services/AssignDeviceService";
import { AlertAddValidation } from "../../validators/AlertValidator";
import { AlertService } from "../../services/AlertService";

const useStyles = makeStyles({
    buttonContainer: {
        textAlign: "center",
    },
});
export const AddAlert = ({ startLoader, stopLoader, onSuccess, onClose }) => {
    const classes = useStyles();
    const [device, setDevice] = useState(null);
    const [sensors, setSensors] = useState([]);
    const initialValues = useInitialValues();
    const devices = useAllDevices(startLoader, stopLoader);

    useEffect(() => {
        if (device) {
            AlertService.GetDeviceSensor(
                device,
                () => startLoader("getSensor"),
                (res) => setSensors(res.data.data),
                handleFetchError,
                () => stopLoader("getSensor")
            );
        }
    }, [device]);

    const handleSubmit = (values) => {
        const payload = values;
        payload.criteria.values.push(payload.a);
        delete payload.a;
        if (ALERTS.ALERT_TYPE.slice(0, 2).find((x) => payload.alertType)) {
            payload.criteria.values.push(payload.b);
        }
        delete payload.b;
        if (ALERTS.ALERT_TYPE[2] === payload.alertType) {
            payload.criteria.timePeriod = payload.timePeriod;
        }
        delete payload.timePeriod;

        if (payload.mqttEnabled === "disabled") {
            payload.host = null;
            payload.port = null;
            payload.topic = null;
        }

        AlertService.Create(
            payload,
            () => startLoader("createAlert"),
            userCreateSuccess,
            userCreateError,
            () => stopLoader("createAlert")
        );
    };
    const userCreateSuccess = (res) => {
        const data = res.data.data;
        onSuccess(data);
        onClose();
    };
    const userCreateError = (err) => {
        console.log(err);
        onClose();
    };

    const handleFetchError = (err) => {
        console.log(err);
    };

    return (
        <Formik
            onSubmit={handleSubmit}
            validationSchema={AlertAddValidation}
            initialValues={initialValues}
            enableReinitialize
        >
            {({ handleSubmit, isSubmitting, values }) => (
                <Form>
                    {setDevice(values.deviceName)}
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={DropDown}
                                name="deviceName"
                                type="select"
                                values={
                                    devices &&
                                    devices.deviceTable &&
                                    devices.deviceTable.map((x) => ({
                                        id: x,
                                        name: x,
                                    }))
                                }
                                fullWidth
                                label="Device"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={DropDown}
                                name="sensorId"
                                type="select"
                                values={
                                    sensors &&
                                    sensors.sensorList &&
                                    sensors.sensorList.map((x) => ({
                                        id: x.seqId,
                                        name: x.sensorData,
                                    }))
                                }
                                fullWidth
                                label="Sensor"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={DropDown}
                                name="valueType"
                                type="select"
                                values={ALERTS.VALUE_TYPES.map((x) => ({
                                    id: x,
                                    name: x,
                                }))}
                                fullWidth
                                label="Value Type"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={DropDown}
                                name="alertType"
                                type="select"
                                values={ALERTS.ALERT_TYPE.map((x) => ({
                                    id: x,
                                    name: x,
                                }))}
                                fullWidth
                                label="Alert Type"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={Input}
                                name="a"
                                type="text"
                                fullWidth
                                label={getFields(values).aLabel}
                            />
                        </Grid>
                        {getFields(values).bDisabled ||
                            (getFields(values).bLabel === "Time Period" ? (
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <Field
                                        component={DropDown}
                                        name="timePeriod"
                                        type="select"
                                        values={ALERTS.TIME_PERIOD.map((x) => ({
                                            id: x[0],
                                            name: x[1],
                                        }))}
                                        fullWidth
                                        label={getFields(values).bLabel}
                                    />
                                </Grid>
                            ) : (
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <Field
                                        component={Input}
                                        name="b"
                                        type="text"
                                        fullWidth
                                        label={getFields(values).bLabel}
                                    />
                                </Grid>
                            ))}
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={Input}
                                name="contact"
                                type="text"
                                fullWidth
                                label="Contact"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={Input}
                                name="email"
                                type="text"
                                fullWidth
                                label="Email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={DropDown}
                                name="mqttEnabled"
                                type="select"
                                values={ALERTS.MQTT_ENABLED.map((x) => ({
                                    id: x,
                                    name: capitalize(x),
                                }))}
                                fullWidth
                                label="MQTT Enabled"
                            />
                        </Grid>
                        {values.mqttEnabled === "enabled" && (
                            <>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <Field
                                        component={Input}
                                        name="host"
                                        type="text"
                                        fullWidth
                                        label="Host"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <Field
                                        component={Input}
                                        name="port"
                                        type="number"
                                        fullWidth
                                        label="Port"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <Field
                                        component={Input}
                                        name="topic"
                                        type="text"
                                        fullWidth
                                        label="Topic"
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <Field
                                component={Input}
                                name="message"
                                type="text"
                                fullWidth
                                label="Message (Variables allowed: $data,$timestamp,$devid,$name,$setpoint,$alerttype,$delta )"
                                multiline
                                rows={3}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.buttonContainer}>
                            <Button type="submit" variant="contained" color="primary" mb={2}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export const getFields = (values) => {
    const alerts = ALERTS.ALERT_TYPE;
    let [aLabel, bLabel, bDisabled] = alerts
        .slice(0, 2)
        .filter((x) => x === values.alertType).length
        ? ["Upper Threshold", "Lower Threshold", false]
        : ["Set Point", "", true];
    values.alertType === "Periodic"
        ? ([aLabel, bLabel, bDisabled] = ["Set Point", "Time Period", false])
        : ([aLabel, bLabel, bDisabled] = [aLabel, bLabel, bDisabled]);

    return {
        aLabel,
        bLabel,
        bDisabled,
    };
};

const useInitialValues = () => {
    const initialValues = {
        deviceName: "",
        timePeriod: "",
        sensorId: "",
        valueType: "",
        alertType: ALERTS.ALERT_TYPE[0],
        criteria: { values: [], timePeriod: null },
        contact: "",
        email: "",
        mqttEnabled: "enabled",
        host: "",
        port: "",
        topic: "",
        message: "",
    };

    return initialValues;
};

export const useAllDevices = (startLoader, stopLoader) => {
    const [devices, setDevices] = useState([]);
    useEffect(() => {
        AssignDeviceService.GetAllDevices(
            () => startLoader("allDevice"),
            deviceFetchSuccess,
            deviceFetchError,
            () => stopLoader("allDevice")
        );
    }, []);
    const deviceFetchSuccess = (res) => {
        const data = res.data.data;
        setDevices(data);
    };
    const deviceFetchError = (err) => {
        console.log(err);
    };
    return devices;
};

export const useDeviceSensors = (startLoader, stopLoader, device) => {
    const [sensors, setSensors] = useState([]);
    useEffect(() => {
        if (device) {
            AlertService.GetDeviceSensor(
                device,
                () => startLoader("getSensor"),
                handleFetchSuccess,
                handleFetchError,
                () => stopLoader("getSensor")
            );
        }
    }, [device]);
    const handleFetchSuccess = (res) => {
        const data = res.data.data;
        setSensors(
            data.sensorList
                .map((x) =>
                    x.sensorData ? { id: x.seqId, name: x.sensorData.sensorName } : null
                )
                .filter(Boolean)
        );
    };
    const handleFetchError = (err) => {
        console.log(err);
    };
    return sensors;
};
