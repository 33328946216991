import React, { useState, useEffect, Fragment } from "react";
import { Formik, Form, Field } from "formik";
import { Input } from "../../components/Input/Input";

import Button from "../../components/CustomButtons/Button";
import { Grid, makeStyles } from "@material-ui/core";
import { DropDown } from "../../components/DropDown/DropDown";
import { FormDialog } from "../../components/FormDialog/FormDialog";
import { BuildingAddValidation } from "../../validators/BuildingValidation";
import { BuildingService } from "../../services/BuildingService";
import { emptyToNull } from "../../utils/ObjectResolver";
import MapComponent from "../../components/Map/MapComponent";

const useStyles = makeStyles({
  buttonContainer: {
    textAlign: "center",
  },
});
export const AddBuilding = ({
  managers,
  startLoader,
  stopLoader,
  onSuccess,
  onClose,
  edit = null,
  userRole,
  user,
  locations,
}) => {
  const hasAccess = userRole === "admin";
  const classes = useStyles();
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [error, setError] = useState(null);
  const [devManager, setDevManager] = useState("");
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      if (location.lat === 0 && location.lng === 0) {
        setLocation({ lat: coords.latitude, lng: coords.longitude });
      }
    });
  }, []);

  const initialValues = useInitialValues(
    startLoader,
    stopLoader,
    edit,
    setLocation,
    userRole,
    user,
    setDevManager
  );

  const [showModal, setShowModal] = useState(false);
  const handleSubmit = (values) => {
    setError(null);
    const payload = emptyToNull({ ...values, ...location });

    delete payload.edit;
    payload.managerId = devManager;

    if (edit) {
      delete payload.buildingId;
      if (initialValues.managerId !== payload.managerId) {
        payload.managerChanged = true;
      }
      BuildingService.Update(
        edit,
        payload,
        () => startLoader("updateBuilding"),
        userCreateSuccess,
        userCreateError,
        () => stopLoader("updateBuilding")
      );
    } else {
      BuildingService.Create(
        payload,
        () => startLoader("createBuilding"),
        userCreateSuccess,
        userCreateError,
        () => stopLoader("createBuilding")
      );
    }
  };
  const userCreateSuccess = (res) => {
    const data = res.data.data;
    if (res.data.error) {
      setError(res.data.message);
      return;
    }
    onSuccess(data);
    onClose();
  };
  const userCreateError = (err) => {
    setError(null);
    console.error(err);
    onClose();
  };
  const handleManagerChange = (e) => {
    let manager = e.target.value;
    setDevManager(manager);
  };
  return (
    <Fragment>
      <FormDialog
        onClose={() => setShowModal(false)}
        show={showModal}
        title={`Select Location`}
        content={
          <MapComponent
            defaultLocation={location}
            setLocation={setLocation}
            setShowModal={setShowModal}
          />
        }
      />
      <Formik
        onSubmit={handleSubmit}
        validationSchema={BuildingAddValidation}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ handleChange, handleSubmit, isSubmitting, values }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <Field
                  component={Input}
                  name="buildingName"
                  type="text"
                  fullWidth
                  label="Dwelling Name"
                  disabled={edit}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                <Field
                  onClick={() => setShowModal(true)}
                  component={Input}
                  value={Object.values(location).join(",")}
                  name="locate"
                  type="text"
                  fullWidth
                  label="Locate"
                />
              </Grid>

              {Boolean(hasAccess) && (
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <Field
                    component={DropDown}
                    name="managerId"
                    type="select"
                    values={
                      managers &&
                      managers.map((x) => ({
                        id: x.id,
                        name: x.name,
                      }))
                    }
                    value={devManager}
                    onChange={(selectedOption) => {
                      handleManagerChange(selectedOption);
                      handleChange("managerId")(selectedOption);
                    }}
                    fullWidth
                    disabled={!hasAccess}
                    label="Manager"
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                <Field
                  component={DropDown}
                  name="locationId"
                  type="select"
                  values={
                    locations &&
                    locations.map((x) => ({
                      id: x.id,
                      name: x.locationName,
                    }))
                  }
                  fullWidth
                  label="Location"
                  disabled={
                    user.managerAdmin ? false : !["admin"].includes(userRole)
                  }
                />
              </Grid>

              {error ? (
                <Grid item lg={12}>
                  <p className="error-msg" style={{ marginLeft: "13px" }}>
                    {error}
                  </p>
                </Grid>
              ) : null}
              <Grid item xs={12} className={classes.buttonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mb={2}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

const useInitialValues = (
  startLoader,
  stopLoader,
  edit,
  setLocation,
  userRole,
  user,
  setDevManager
) => {
  const [initialValues, setInitialValues] = useState({
    edit: false,
    buildingName: "",
    managerId: userRole === "manager" ? user.id : "",
    locationId: "",
  });
  useEffect(() => {
    if (edit) {
      BuildingService.ReadById(
        edit,
        () => startLoader("getBuilding"),
        buildingFetchSuccess,
        buildingFetchError,
        () => stopLoader("getBuilding")
      );
    } else if (userRole === "manager") {
      setInitialValues({
        ...initialValues,
        managerId: user.id,
      });
      setDevManager(user.id);
    }
  }, [edit]);
  const buildingFetchSuccess = (res) => {
    const data = res.data.data.buildingData;
    data["edit"] = true;
    setInitialValues({ ...data, managerId: user.id });
    setDevManager(data.managerId);
    setLocation({ lat: data.lat, lng: data.lng });
  };
  const buildingFetchError = (err) => {
    console.log(err);
  };
  return initialValues;
};
