import React, { Fragment, useState, useContext, useEffect } from "react";
import { FormDialog } from "../../components/FormDialog/FormDialog";
import { LoaderContext } from "../../context/LoaderContext";
import {
  START_LOADER,
  STOP_LOADER,
  LOCAL_PATH,
  BRIEFS,
  ALERT_TYPES,
} from "../../constants";
import {
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Table from "../../components/Table/Table";
import { CustomToolTip } from "../../components/CustomToolTip/CustomToolTip";
import {
  Delete as DeleteIcon,
  Details as DetailsIcon,
} from "@material-ui/icons";
import { AddAlert } from "./AddAlert";
import { AlertService } from "../../services/AlertService";
import { getFields } from "./AddAlert";
import { AuthService } from "../../services/AuthService";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  button: {
    float: "right",
  },
  pagination: {
    marginLeft: "auto",
  },
  searchBtn: {
    margin: 0,
    padding: "11px 12px 11px 15px",
  },
  searchWrapper: {
    paddingRight: "0",
    marginRight: "1.3px",
  },
  searchInput: {
    padding: "11px 14px",
  },
});

const LIMIT = 10;

export const AlertPage = () => {
  const userRole = AuthService.getUserRole();
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState({ show: false, content: null });
  const [refresh, setRefresh] = useState(0);
  const [criteria, setCriteria] = useState({ show: false, content: null });
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const { dispatch } = useContext(LoaderContext);
  const [search, setSearch] = useState("");
  const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });

  const alerts = useAlerts(
    startLoader,
    stopLoader,
    refresh,
    pagination.curPage,
    setPagination,
    setRefresh,
    setCriteria,
    userRole,
    setMessage,
    search
  );

  const afterInsertSuccess = () => {
    setShowModal(false);
    setRefresh((prev) => prev + 1);
  };
  const onClose = () => {
    setShowModal(false);
  };

  const initiateSearch = () => {
    setRefresh((prev) => prev + 1);
  };

  return (
    <Fragment>
      <FormDialog
        show={showModal}
        onClose={onClose}
        title={`Alerts`}
        content={
          <AddAlert
            startLoader={startLoader}
            stopLoader={stopLoader}
            onClose={afterInsertSuccess}
          />
        }
      />
      <FormDialog
        show={criteria.show}
        onClose={() => setCriteria({ show: false, content: null })}
        title={`Criteria`}
        content={criteria.content}
      />
      <FormDialog
        show={message.show}
        onClose={() => setMessage({ show: false, content: null })}
        title={`Message`}
        content={message.content}
      />
      <Grid container direction="column" spacing={2}>
        <div className="info-container">
          <div></div>
          <div>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
              <TextField
                variant="outlined"
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  className: classes.searchWrapper,
                  classes: {
                    input: classes.searchInput,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        color="primary"
                        className={classes.searchBtn}
                        onClick={initiateSearch}
                      >
                        <SearchIcon />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                onClick={() => setShowModal(true)}
                className={classes.button}
                variant="contained"
                color="primary"
                mb={2}
              >
                Add Alert
              </Button>
            </Grid>
          </div>
        </div>
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Alerts</h4>
            </CardHeader>
            <CardBody className="table-scroll">
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  "SNo",
                  "Device Name",
                  "Contact",
                  "Email",
                  "Sensor Name",
                  "Value Type",
                  "Alert Type",
                  "MQTT Enabled",
                  "Host",
                  "Port",
                  "Topic",
                  "Created At",
                  "Message",
                  "Options",
                ]}
                tableData={alerts}
              />
            </CardBody>
          </Card>
        </Grid>
        {pagination.totalPages > 1 ? (
          <Grid item xs={12} className={classes.pagination}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.curPage}
              variant="outlined"
              color="primary"
              onChange={(e, value) =>
                setPagination((prev) => ({
                  ...prev,
                  curPage: value,
                }))
              }
            />
          </Grid>
        ) : null}
      </Grid>
    </Fragment>
  );
};

const useAlerts = (
  startLoader,
  stopLoader,
  refresh,
  curPage,
  setPagination,
  setRefresh,
  setCriteria,
  userRole,
  setMessage,
  search
) => {
  const [alerts, setAlerts] = useState([]);
  const history = useHistory();
  useEffect(() => {
    AlertService.Read(
      LIMIT,
      (curPage - 1) * LIMIT,
      search,
      () => startLoader("alertFetch"),
      handleAlertFetchSuccess,
      handleAlertFetchError,
      () => stopLoader("alertFetch")
    );
  }, [curPage, refresh]);

  const handleAlertFetchSuccess = (res) => {
    const data = res.data.data;
    const pagination = data.pagination;
    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(pagination.totalCount / LIMIT),
    }));
    const processedData = data.alerts.map((item) => [
      item.id,
      <p
        className="device-link"
        onClick={() => history.push(LOCAL_PATH.DEVICE, { id: item.deviceName })}
      >
        {item.deviceName}
      </p>,
      item.contact,
      item.email,
      item.sensorName,
      item.valueType,
      item.alertType,
      item.mqttEnabled,
      item.host || "--",
      item.port || "--",
      item.topic || "--",
      new Date(item.createdAt).toLocaleString(),
      getMessage(item),
      getOptions(item),
    ]);
    setAlerts(processedData);
  };
  const handleAlertFetchError = (err) => {
    console.log(err);
  };

  const handleAlertDelete = (id, role) => {
    AlertService.Delete(
      id,
      role,
      () => startLoader("alertDelete"),
      () => setRefresh((prev) => prev + 1),
      () => setRefresh((prev) => prev + 1),
      () => stopLoader("alertDelete")
    );
  };

  const showCriteria = (item) => {
    const { aLabel, bLabel, bDisabled } = getFields(item);
    const content = (
      <>
        <Typography>
          {aLabel} : {item.criteria.values[0]}
        </Typography>
        {bDisabled || (
          <Typography>
            {bLabel} : {item.criteria.values[1] || item.criteria.timePeriod}
          </Typography>
        )}
      </>
    );
    return setCriteria({ show: true, content });
  };

  const showMessage = (message) => {
    const content = (
      <>
        <Typography>{message}</Typography>
      </>
    );
    return setMessage({ show: true, content });
  };

  const getOptions = (item) => {
    return (
      <>
        <CustomToolTip
          title="View Criteria"
          onClick={() => showCriteria(item)}
          Icon={DetailsIcon}
        />
        <CustomToolTip
          title={"Delete Alert"}
          onClick={() => handleAlertDelete(item.id)}
          Icon={DeleteIcon}
        />
      </>
    );
  };
  const getMessage = (item) => {
    return (
      <>
        {item.message ? (
          <CustomToolTip
            title={item.message}
            onClick={() => showMessage(item.message)}
            Icon={DetailsIcon}
          />
        ) : (
          <div>--</div>
        )}
      </>
    );
  };
  return alerts.map((x, i) => [(curPage - 1) * LIMIT + i + 1, ...x.slice(1)]);
};
