import React from "react";
import {
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  makeStyles,
  DialogActions,
} from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";

const useStyles = makeStyles({
  container: {
    minHeight: "10rem",
  },
});

export const DeleteDialog = ({
  show = false,
  onClose = () => {},
  deletefunction,
  funcParam,
  text = null,
}) => {
  const classes = useStyles();
  const handleCancel = () => onClose(false);

  return (
    <Card mb={6}>
      <div>
        <Dialog
          fullWidth
          open={show}
          onClose={handleCancel}
          aria-labelledby="form-dialog-title"
          scroll="body"
        >
          <DialogContent
            className={classes.container}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DialogContentText>
              <div>{text || "Are You Sure ?"}</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions onClick={() => onClose(false)} style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Button>No</Button>
            <Button
              onClick={() => deletefunction(funcParam)}
              autoFocus
              color="primary"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Card>
  );
};
