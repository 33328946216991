import React, { Fragment, useState } from 'react';
import { Button, makeStyles, Tab, Tabs } from '@material-ui/core';
import { tabProps, TabPanel } from '../../components/TabPanel/TabPanel';
import { AlertPage } from './AlertPage';
import { AlertLog } from './AlertLog';
import { ALERT_TYPES, BRIEFS } from '../../constants';
import { FormDialog } from '../../components/FormDialog/FormDialog';

const useStyles = makeStyles({
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  button: {
    float: 'right',
  },
  pagination: {
    marginLeft: 'auto',
  },
});

const AlertTypes = (
  <div>
    {ALERT_TYPES.map((type) => (
      <div>
        <div style={{ marginBottom: '0.51vw' }}>
          <span style={{ fontWeight: 'bold' }}>{type.name}:</span> {type.description}
        </div>
      </div>
    ))}
  </div>
);

export const AlertHome = () => {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const [showAlertInfo, setShowAlertInfo] = useState(false);

  const handleChange = (event, value) => {
    setValue(value);
  };
  return (
    <Fragment>
      <FormDialog show={showAlertInfo} onClose={() => setShowAlertInfo(false)} title={`Alert Types`} content={AlertTypes} />
      <div className='info-container' style={{ marginBottom: '25px' }}>
        <div>
          <div className='info-heading' style={{ marginTop: '10px' }}>
            {BRIEFS?.manageAlerts?.heading}
          </div>
          <ul className='info-list'>
            {Object.values(BRIEFS?.manageAlerts?.description).map((desc) => (
              <li>{desc}</li>
            ))}
          </ul>
        </div>
        <div>
          <Button onClick={() => setShowAlertInfo(true)} className={classes.button} variant='contained' color='primary' mb={2}>
            View Alert Types
          </Button>
        </div>
      </div>
      <Tabs value={value} onChange={handleChange}>
        <Tab label='Alerts' {...tabProps(0)} />
        <Tab label='Alert Log' {...tabProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <AlertPage />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AlertLog />
      </TabPanel>
    </Fragment>
  );
};
