import React, { useState,useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Input } from "../../components/Input/Input";
import Button from "../../components/CustomButtons/Button";
import { Grid, makeStyles } from "@material-ui/core";
import { DropDown } from "../../components/DropDown/DropDown";
import { EVENTS } from "../../constants";
import { useAllDevices, useDeviceSensors } from "../alert/AddAlert";
import { EventAddValidation } from "../../validators/EventValidator";
import { EventService } from "../../services/EventService";
import { AlertService } from "../../services/AlertService";

const useStyles = makeStyles({
    buttonContainer: {
        textAlign: "center",
    },
});
export const AddEvent = ({ startLoader, stopLoader, onSuccess, onClose }) => {
    const classes = useStyles();
    const [device, setDevice] = useState(null);
    const [error, setError] = useState(null);
    const [sensors, setSensors] = useState(null);
    const initialValues = {
        deviceName: "",
        sensorId: "",
        valueType: "",
        eventType: "",
        setPoint: "",
    };
    const devices = useAllDevices(startLoader, stopLoader);

    useEffect(() => {
        if (device) {
            AlertService.GetDeviceSensor(
                device,
                () => startLoader("getSensor"),
                (res) => setSensors(res.data.data),
                (err)=>console.log(err),
                () => stopLoader("getSensor")
            );
        }
    }, [device]);

    const handleSubmit = (values) => {
        setError(null)
        const payload = values;
        console.log(payload);
        EventService.Create(
            payload,
            () => startLoader("createEvent"),
            eventCreateSuccess,
            eventCreateError,
            () => stopLoader("createEvent")
        );
    };
    const eventCreateSuccess = (res) => {
        const data = res.data.data;
        if(res.data.error){
            setError(res.data.message)
            return
        }
        onSuccess(data);
        onClose();
    };
    const eventCreateError = (err) => {
        setError(null)
        console.log(err);
        onClose();
    };
    return (
        <Formik
            onSubmit={handleSubmit}
            validationSchema={EventAddValidation}
            initialValues={initialValues}
        >
            {({ handleSubmit, isSubmitting, values }) => (
                <Form>
                    {setDevice(values.deviceName)}
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={DropDown}
                                name="deviceName"
                                type="select"
                                values={devices && devices.deviceTable && devices.deviceTable.map((x) => ({
                                    id: x,
                                    name: x,
                                }))}
                                fullWidth
                                label="Device"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={DropDown}
                                name="sensorId"
                                type="select"
                                values={sensors && sensors.sensorList && sensors.sensorList.map((x) => ({
                                    id: x.seqId,
                                    name: x.sensorData,
                                }))}
                                fullWidth
                                label="Sensor"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={DropDown}
                                name="valueType"
                                type="select"
                                values={EVENTS.VALUE_TYPES.map((x) => ({
                                    id: x,
                                    name: x,
                                }))}
                                fullWidth
                                label="Value Type"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={DropDown}
                                name="eventType"
                                type="select"
                                values={EVENTS.EVENT_TYPES.map((x) => ({
                                    id: x,
                                    name: x,
                                }))}
                                fullWidth
                                label="Event Type"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={Input}
                                name="setPoint"
                                type="text"
                                fullWidth
                                label={"Set Point"}
                            />
                        </Grid>
                        {error ? 
                        <Grid item lg={12}>
                            <p className="error-msg" style={{marginLeft:'13px'}}>{error}</p>
                        </Grid> : null}
                        <Grid item xs={12} className={classes.buttonContainer}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                mb={2}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
