import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Button as MuiButton } from '@material-ui/core';
import { CanvasComponent } from '../../components/CanvasComponent/CanvasComponent';
import { FormDialog } from '../../components/FormDialog/FormDialog';
import { DeviceService } from '../../services/DeviceService';
import { DevicesTwoTone } from '@material-ui/icons';
const useStyles = makeStyles({
  buttonContainer: {
    textAlign: 'center',
  },
  MuiButton: {
    color: 'purple',
    border: '1px solid purple',
    fontSize: '0.7rem',
    padding: '0.3rem',
    width: '300px',
  },
  MuiButtonContainer: {
    margin: 'auto',
  },
});
export const FloorMapModal = ({ startLoader, stopLoader, floorData, onClose }) => {
  // const userRole = AuthService.getUserRole();
  const [showModal, setShowModal] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [image, setImage] = useState('');
  const classes = useStyles();
  const handleDevice = (floorId) => {
    DeviceService.deviceByFloor(
      floorId,
      () => {
        startLoader('dataLoading');
      },
      handleDevicesSuccess,
      handleDeviceserror,
      () => {
        stopLoader('dataLoading');
      }
    );
  };
  const handleDevicesSuccess = (res) => {
    const devices = res.data.data.devices;
    setMarkers(devices);
  };
  const handleDeviceserror = (err) => {
    console.log(err);
  };
  return (
    <>
      <FormDialog
        onClose={() => setShowModal(false)}
        show={showModal}
        title={`Room Map`}
        content={
          <CanvasComponent status={markers.length > 0 && markers[0].status} markers={markers} setMarkers={setMarkers} image={image} />
        }
      />
      <Grid container direction='column' spacing={3}>
        {floorData.map((floor, index) => (
          <Grid key={index} item xs={12} className={classes.MuiButtonContainer}>
            {' '}
            <MuiButton
              className={classes.MuiButton}
              type='submit'
              variant='outlined'
              fullWidth={true}
              color='primary'
              onClick={() => {
                setShowModal(true);
                setImage(floor.imageUrl);
                handleDevice(floor.floorId);
              }}
            >
              {floor.floorName}
            </MuiButton>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
