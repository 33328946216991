import React, { Fragment, useContext } from "react";

import { CircularProgress, makeStyles } from "@material-ui/core";
import { LoaderContext } from "../../context/LoaderContext";

const useStyles = makeStyles({
    root: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        minHeight: "100%",
        background: "black",
        position: "fixed",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        zIndex: 10000,
        opacity: 0.4,
    },
});

function Loader() {
    const { state } = useContext(LoaderContext);
    const classes = useStyles();
    const loaderComponent = (
        <div className={classes.root}>
            <CircularProgress m={2} color="primary" />
        </div>
    );
    return (
        <Fragment>{Boolean(state.loading.length) && loaderComponent}</Fragment>
    );
}

export default Loader;
