import { HttpClient } from "../utils/httpClient";

const PATH = {
    login: "/user/login",
    create: "/user",
    read: "/user",
    getManagers: "/getAllManagers",
    update: "/user",
    delete: "/user",
    logout:'/logout',
    resetPassword:'/user/resetPassword',
    resetRequest:'/user/resetRequest',
    getAllLocation:"/location/all",
    signupGenerateOtp:"/user/signupGenerateOtp",
    signup:"/user/signup"
};

const Login = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.login, payload)
        .then(callback)
        .catch(error)
        .finally(next);
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.create}`, payload)
        .then(callback)
        .catch(error)
        .finally(next);
};

const Read = (limit, offset, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.read}?limit=${limit}&offset=${offset}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

const ReadById = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.read}/${id}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

const GetManagers = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getManagers)
        .then(callback)
        .catch(error)
        .finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload)
        .then(callback)
        .catch(error)
        .finally(next);
};

const Delete = (id, role, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`, {
        params: { userRole: role },
    })
        .then(callback)
        .catch(error)
        .finally(next);
};

const ResetRequest = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.resetRequest}`, payload)
        .then(callback)
        .catch(error)
        .finally(next);
};

const ResetPassword = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.resetPassword}`, payload)
        .then(callback)
        .catch(error)
        .finally(next);
};
const GetAllLocation=( start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getAllLocation}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

const SignupGenerateOtp = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.signupGenerateOtp}`, payload)
        .then(callback)
        .catch(error)
        .finally(next);
};
const Signup = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.signup}`, payload)
        .then(callback)
        .catch(error)
        .finally(next);
};
export const UserService = {
    Login,
    Create,
    Read,
    ReadById,
    GetManagers,
    Update,
    Delete,
    ResetPassword,
    ResetRequest,
    GetAllLocation,
    SignupGenerateOtp,
    Signup


};
