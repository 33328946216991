/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components

import AdminNavbarLinks from "../Navbars/AdminNavbarLinks";

import styles from "../../assets/jss/material-dashboard-react/components/sidebarStyle";

const useStyles = makeStyles(styles);

const colors = [
  "forestgreen",
  "darkred",
  "orange",
  "coral",
  "darkseagreen",
  "brown",
  "antiquewhite",
  "cadetblue",
  "blueviolet",
  "forestgreen",
  "darkred",
  "orange",
  "coral",
  "#FED517",
  "antiquewhite",
  "antiquewhite",
  "cadetblue",
  null,
  null,
  null,
  "cadetblue",
];

export default function Sidebar(props) {
  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const {
    color,
    logo,
    image,
    logoText,
    routes,
    userRole,
    mqttAdmin,
    logoImage,
  } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (!prop.name || prop.path == "/deviceDashboard") {
          return null;
        }

        if (
          userRole === "assistant" &&
          ![
            mqttAdmin ? "/parentDevice" : "",
            ,
            "/home",
            "/mqttLogs",
            "/alert",
            "/event",
            "/autoReport",
            "/parentAlert",
          ].includes(prop.path)
        ) {
          return null;
        }
        var activePro = " ";
        var listItemClasses;
        if (prop.path === "/upgrade-to-pro") {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true,
          });
        } else {
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path),
          });
        }
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
        });
        return (
          <NavLink
            to={
              prop.path != "/parentUser" &&
              prop.path != "/parentAlert" &&
              prop.path != "/parentDevice" &&
              prop.path != "/parentLoc"
                ? prop.layout + prop.path
                : "#"
            }
            className={activePro + classes.item}
            activeClassName="active"
            key={key}
            style={
              prop.path != "/data-routing" &&
              prop.path != "/udp-routing" &&
              prop.path != "/home" &&
              prop.path != "/parentUser" &&
              prop.path != "/parentAlert" &&
              prop.path != "/parentDevice" &&
              prop.path != "/parentLoc"
                ? { marginLeft: "1.5vw" }
                : { marginLeft: "0" }
            }
          >
            <ListItem
              button
              className={
                prop.path != "/data-routing" &&
                prop.path != "/udp-routing" &&
                prop.path != "/home" &&
                prop.path != "/parentUser" &&
                prop.path != "/parentAlert" &&
                prop.path != "/parentDevice" &&
                prop.path != "/parentLoc"
                  ? classes.itemSubLink + listItemClasses
                  : classes.itemLink + listItemClasses
              }
            >
              {typeof prop.icon === "string" ? (
                <Icon
                  style={{
                    color:
                      prop.path === "/parentUser" ? "#FED517" : colors[key],
                  }}
                  className={classNames(
                    prop.path != "/data-routing" &&
                      prop.path != "/udp-routing" &&
                      prop.path != "/home" &&
                      prop.path != "/parentUser" &&
                      prop.path != "/parentAlert" &&
                      prop.path != "/parentDevice" &&
                      prop.path != "/parentLoc"
                      ? classes.itemSubIcon
                      : classes.itemIcon,
                    whiteFontClasses,
                    {
                      [classes.itemIconRTL]: props.rtlActive,
                    }
                  )}
                >
                  {prop.icon}
                </Icon>
              ) : (
                <prop.icon
                  style={{ color: colors[key] }}
                  className={classNames(
                    prop.path != "/data-routing" &&
                      prop.path != "/udp-routing" &&
                      prop.path != "/home" &&
                      prop.path != "/parentUser" &&
                      prop.path != "/parentAlert" &&
                      prop.path != "/parentDevice" &&
                      prop.path != "/parentLoc"
                      ? classes.itemSubIcon
                      : classes.itemIcon,
                    whiteFontClasses,
                    {
                      [classes.itemIconRTL]: props.rtlActive,
                    }
                  )}
                />
              )}
              {/*prop.path ==="/home" ?classes.itemLink +listItemClasses:classes.itemSubLink + listItemClasses */}
              <ListItemText
                primary={props.rtlActive ? prop.rtlName : prop.name}
                className={classNames(
                  prop.path != "/data-routing" &&
                    prop.path != "/udp-routing" &&
                    prop.path != "/home" &&
                    prop.path != "/parentUser" &&
                    prop.path != "/parentAlert" &&
                    prop.path != "/parentDevice" &&
                    prop.path != "/parentLoc"
                    ? classes.itemSubText
                    : classes.itemText,
                  whiteFontClasses,
                  {
                    [classes.itemTextRTL]: props.rtlActive,
                  }
                )}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      {!logoImage && (
        <span
          className={classNames(classes.logoLink, {
            [classes.logoLinkRTL]: props.rtlActive,
          })}
          style={{ textAlign: "center" }}
          target="_blank"
        >
          {logoText}
        </span>
      )}
      {logoImage && (
        <img src={logoImage} alt={"logo"} className={classes.logoWithImage} />
      )}
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
